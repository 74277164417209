import {
  HelpCenterItemData,
  HelpCenterItemStatistics,
  HelpCenterLanguage,
  HelpCenterMode,
  HelpItemSubject,
} from '@shared/modules/help-center/help-center.models';
import { HelpGuide, HelpSubject } from '@shared/modules/help-center/help-subject.enum';

export class GetHelpItemStatus {
  static type = '[Help Center] Get item status';

  constructor(readonly subject: HelpItemSubject) {}
}

export class GetHelpItemTip {
  static type = '[Help Center] Get item tip';

  constructor(
    readonly subject: HelpItemSubject,
    readonly lang: HelpCenterLanguage,
  ) {}
}

export class GetHelpItemTitle {
  static type = '[Help Center] Get item title';

  constructor(
    readonly subject: HelpItemSubject,
    readonly lang: HelpCenterLanguage,
  ) {}
}

export class GetHelpItemData {
  static type = '[Help Center] Get item data';

  constructor(
    readonly subject: HelpItemSubject,
    readonly lang: HelpCenterLanguage,
  ) {}
}

export class RevertHelpItemData {
  static type = '[Help Center] Revert item data';

  constructor(readonly subject: HelpItemSubject) {}
}

export class GetHelpItemStatistics {
  static type = '[Help Center] Get item stats';

  constructor(readonly subject: HelpItemSubject) {}
}

export class UpdateHelpCenterMode {
  static type = '[Help Center] Update mode';

  constructor(readonly mode: { language?: HelpCenterLanguage; mode?: HelpCenterMode }) {}
}

export class UpdateHelpItemData {
  static type = '[Help Center] Update data item';

  constructor(
    readonly subject: HelpItemSubject,
    readonly data: Partial<Record<HelpCenterLanguage, Partial<HelpCenterItemData>>>,
  ) {}
}

export class IncrementHelpItemStats {
  static type = '[Help Center] Increment stats';

  constructor(
    readonly subject: HelpItemSubject,
    readonly property: keyof HelpCenterItemStatistics,
    readonly lang: HelpCenterLanguage,
  ) {}
}

export class SelectHelpCenterTeam {
  static type = '[Help Center] Select team';

  constructor(readonly team: string) {}
}

export class ShowArticle {
  static type = '[Help Center] Show Article';

  constructor(readonly subject: HelpSubject) {}
}

export class HideArticle {
  static type = '[Help Center] Hide Article';

  constructor(readonly subject: HelpSubject) {}
}

export class GetGuide {
  static type = '[Help Center] Get Guide';

  constructor(readonly subject: HelpGuide) {}
}

export class ShowGuide {
  static type = '[Help Center] Show Guide';

  constructor(readonly subject: HelpGuide) {}
}

export class HideGuide {
  static type = '[Help Center] Hide Guide';

  constructor(readonly subject: HelpGuide) {}
}

export class StartGuide {
  static type = '[Help Center] Start Guide';

  constructor(
    readonly subject: HelpGuide,
    readonly steps: any[],
  ) {}
}
