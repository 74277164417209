import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';

import { OptionId, PlanId } from '@shared/models/plan.model';

import { PlansApi } from '@plans/shared/services/plans-api.service';
import { PlanCustomization, PlanInfo, PlanOption } from '@plans/shared/models/plans.model';

import { BillingPlanData, MonthlyYearly } from '@shared/models/billing.model';

import { CancelPlan } from '@shared/states/billing.actions';
import { AddPlan } from '@shared/states/cart.actions';
import {
  CheckUpgradeCheaper,
  ClearChosen,
  DowngradeDelete,
  GetOptions,
  GetPlanPrice,
  GetPlans,
  SetPlanState,
  UpdateChosenBillingPeriod,
  UpdateChosenOption,
  UpdateChosenPlan,
} from '@shared/states/plan.actions';

import { BillingState } from '@shared/states/billing.state';
import { SingleAction } from '@shared/decorators/single-action.decorator';

export interface PlanStateModel {
  plans: PlanInfo[];
  options: PlanOption[];
  chosenPlan?: PlanInfo;
  chosenOptions: PlanCustomization[];
  period: MonthlyYearly;
  price: number;
  cheaperPlan?: PlanId;
}

export const enum UpdateFrom {
  Plan,
  Options,
  Period,
}

@Injectable()
@State<PlanStateModel>({
  name: 'plan',
  defaults: {
    plans: [],
    options: [],
    chosenOptions: [],
    period: 'monthly' as MonthlyYearly,
    price: 0,
  },
})
export class PlanState {
  @Selector([BillingState.activePlan, BillingState.isNotPaid, BillingState.isParked])
  static isCurrent(plan: PlanStateModel, activePlan: BillingPlanData, isNotPaid: boolean, isParked: boolean) {
    if (!plan.chosenPlan) {
      return !isParked;
    }

    const isFree = PlanState.isFree({ chosenPlan: plan.chosenPlan } as PlanStateModel, isNotPaid);

    if (isFree && isNotPaid) {
      return !isParked;
    }

    if (!activePlan || activePlan.paymentPlan.period !== plan.period) {
      return false;
    }

    if (activePlan.plan.id !== plan.chosenPlan.id || activePlan.options.length !== plan.chosenOptions.length) {
      return false;
    }

    return activePlan.options.every((active) =>
      plan.chosenOptions.some((chosen) => chosen.id === active.id && chosen.value === active.value),
    );
  }

  @Selector([BillingState.isNotPaid])
  static isFree({ chosenPlan }: PlanStateModel, isNotPaid: boolean): boolean {
    return (!chosenPlan && isNotPaid) || chosenPlan?.id === 'free_plan';
  }

  @Selector([BillingState.activePlan])
  static isPlus({ chosenOptions, chosenPlan }: PlanStateModel, activePlan: BillingPlanData): boolean {
    return (chosenPlan && chosenOptions.length > 0) || (!chosenPlan && activePlan && activePlan.options.length > 0);
  }

  @Selector()
  static freePlan({ plans }: PlanStateModel): PlanInfo {
    return plans.find((plan) => plan.id === 'free_plan');
  }

  @Selector()
  static planOptions({ options }: PlanStateModel): PlanOption[] {
    return options;
  }

  @Selector()
  static planPrice({ price }: PlanStateModel): number {
    return price;
  }

  @Selector()
  static planPeriod({ period }: PlanStateModel): MonthlyYearly {
    return period;
  }

  @Selector()
  static chosenPlan({ chosenPlan }: PlanStateModel): PlanInfo {
    return chosenPlan;
  }

  @Selector()
  static chosenPlanId({ chosenPlan }: PlanStateModel): PlanId {
    return chosenPlan?.id;
  }

  @Selector()
  static plans({ plans }: PlanStateModel): PlanInfo[] {
    return plans;
  }

  static planOption(optionId: OptionId) {
    return createSelector([PlanState], ({ options }: PlanStateModel) =>
      options.find((option) => option.id === optionId),
    );
  }

  @Selector([BillingState.activePlan])
  static chosenPlanConstruct({ chosenPlan, chosenOptions }: PlanStateModel, activePlan: BillingPlanData) {
    const plan: PlanInfo = chosenPlan || activePlan.plan;
    const options: PlanCustomization[] = chosenPlan ? chosenOptions : activePlan.options;
    return { plan, options };
  }

  constructor(
    readonly pa: PlansApi,
    readonly store: Store,
  ) {}

  @Action(SetPlanState)
  setPlanState({ setState }: StateContext<PlanStateModel>, { state }: SetPlanState): void {
    setState(state);
  }

  @SingleAction(GetPlans)
  getPlans({ patchState }: StateContext<PlanStateModel>): Observable<PlanStateModel> {
    return this.pa.getPlans().pipe(map((plans) => patchState({ plans })));
  }

  @SingleAction(GetOptions)
  getOptions({ patchState }: StateContext<PlanStateModel>): Observable<void> {
    return this.pa.getOptions().pipe(
      map((options) => {
        options.sort((a, b) =>
          [a, b]
            .map(({ id }) => (id === 'extra_contacts' ? 1 : id === 'extra_answers' ? 2 : 3))
            .reduce((prev, cur) => (prev ? prev - cur : cur), 0),
        );

        patchState({ options });
      }),
    );
  }

  @Action(ClearChosen)
  clearChosen({ patchState }: StateContext<PlanStateModel>): void {
    patchState({
      chosenPlan: undefined,
      chosenOptions: [],
      period: 'monthly',
      price: 0,
    });
  }

  @Action(CheckUpgradeCheaper, { cancelUncompleted: true })
  checkUpgradeCheaper(ctx: StateContext<PlanStateModel>): Observable<PlanStateModel> | void {
    const matchingPlans = {
      plan_pro: 'plan_genius',
      plan_basic: 'plan_smart',
      plan_enterprise: 'plan_master',
    };

    const { plans, chosenPlan, period, price } = ctx.getState();

    const chosenPlanId = matchingPlans[chosenPlan.id] || chosenPlan.id;

    const idx = plans.findIndex((plan) => plan.id === chosenPlanId);
    const possiblePlans = plans.slice(idx + 1).reverse();
    const cheaper = possiblePlans.find(
      (plan) => (period === 'yearly' ? plan.priceAnnual / 12 : plan.priceMonthly) <= price,
    );

    if (cheaper) {
      const options = this.getNewOptions(ctx, cheaper);

      if (!options.length) {
        ctx.patchState({ cheaperPlan: cheaper.id });
      } else {
        return this.pa.getPaymentPlan(cheaper.id, period, options).pipe(
          map((plan) =>
            ctx.patchState({
              cheaperPlan: plan.price / (period === 'yearly' ? 12 : 1) <= price ? cheaper.id : void 0,
            }),
          ),
        );
      }
    }
  }

  @Action(GetPlanPrice, { cancelUncompleted: true })
  getPlanPrice(
    { getState, patchState, dispatch }: StateContext<PlanStateModel>,
    { updateFrom }: GetPlanPrice,
  ): Observable<any> | void {
    const ctx = { getState } as StateContext<PlanStateModel>;
    const chosenPlan = updateFrom === UpdateFrom.Plan ? getState().chosenPlan : this.getCurrentPlan(ctx);
    const chosenOptions = updateFrom === UpdateFrom.Options ? getState().chosenOptions : this.getCurrentOptions(ctx);
    const period = updateFrom === UpdateFrom.Period ? getState().period : this.getCurrentPeriod(ctx);

    const activePlan: BillingPlanData = this.store.selectSnapshot(BillingState.activePlan);
    const activeFree: boolean = this.store.selectSnapshot(BillingState.isNotPaid);
    const isParked: boolean = this.store.selectSnapshot(BillingState.isParked);

    patchState({ chosenPlan, chosenOptions, period, cheaperPlan: undefined });

    if (PlanState.isCurrent(getState(), activePlan, activeFree, isParked)) {
      patchState({ price: activePlan.paymentPlan.price / (activePlan.paymentPlan.period === 'yearly' ? 12 : 1) });
    } else if (!chosenOptions.length) {
      patchState({ price: period === 'yearly' ? chosenPlan.priceAnnual / 12 : chosenPlan.priceMonthly });
    } else {
      return this.pa.getPaymentPlan(chosenPlan.id, period, chosenOptions).pipe(
        tap(({ price }) => patchState({ price: price / (period === 'yearly' ? 12 : 1) })),
        tap(() => {
          if (
            chosenPlan.id === 'plan_basic' ||
            chosenPlan.id === 'plan_pro' ||
            chosenPlan.id === 'plan_smart' ||
            chosenPlan.id === 'plan_genius'
          ) {
            dispatch(new CheckUpgradeCheaper());
          }
        }),
      );
    }
  }

  @Action(UpdateChosenPlan, { cancelUncompleted: true })
  updateChosenPlan(ctx: StateContext<PlanStateModel>, { chosenPlan }: UpdateChosenPlan): void {
    const { patchState, dispatch } = ctx;
    const period = this.getCurrentPeriod(ctx);
    const chosenOptions = this.getNewOptions(ctx, chosenPlan);

    patchState({ chosenPlan, chosenOptions, period });
    dispatch(new GetPlanPrice(UpdateFrom.Plan));
  }

  @Action(UpdateChosenOption, { cancelUncompleted: true })
  updateChosenOption(
    { patchState, getState, dispatch }: StateContext<PlanStateModel>,
    { option }: UpdateChosenOption,
  ): void {
    const options = this.getCurrentOptions({ getState } as StateContext<PlanStateModel>);
    const oldOption = options.find((chosenOption) => chosenOption.id === option.id);

    if (oldOption) {
      oldOption.value = option.value;
    } else {
      options.push(option);
    }

    patchState({ chosenOptions: options.filter(({ value }) => !!value) });
    dispatch(new GetPlanPrice(UpdateFrom.Options));
  }

  @Action(UpdateChosenBillingPeriod, { cancelUncompleted: true })
  updateChosenBillingPeriod(
    { patchState, dispatch }: StateContext<PlanStateModel>,
    { period }: UpdateChosenBillingPeriod,
  ): void {
    patchState({ period });
    dispatch(new GetPlanPrice(UpdateFrom.Period));
  }

  @Action(DowngradeDelete)
  downgradeDelete(ctx: StateContext<PlanStateModel>, { response }: DowngradeDelete): void {
    const { getState, dispatch } = ctx;

    if (['free_plan', 'parked_plan'].includes(this.getCurrentPlan(ctx).id)) {
      dispatch(new CancelPlan(response));
    } else {
      dispatch([new AddPlan(getState(), response), new Navigate(['/plans/cart'])]);
    }
  }

  private getCurrentOptions({ getState }: StateContext<PlanStateModel>): PlanCustomization[] {
    const chosenPlan = getState().chosenPlan;
    let chosenOptions = getState().chosenOptions;

    if (!chosenPlan) {
      chosenOptions = this.store.selectSnapshot<PlanCustomization[]>(BillingState.activeOptions);
    }

    return [...chosenOptions].map((option) => ({ ...option }));
  }

  private getCurrentPlan({ getState }: StateContext<PlanStateModel>): PlanInfo {
    let plan = getState().chosenPlan;

    if (!plan) {
      const activePlan = this.store.selectSnapshot(BillingState.activePlan);
      plan = (activePlan && activePlan.plan) || (getState().plans.find(({ id }) => id === 'free_plan') as PlanInfo);
    }

    return plan;
  }

  private getCurrentPeriod({ getState }: StateContext<PlanStateModel>): MonthlyYearly {
    const plan = getState().chosenPlan;
    let period = getState().period;

    if (!plan) {
      const activePlan = this.store.selectSnapshot(BillingState.activePlan);
      period = (activePlan && activePlan.paymentPlan && activePlan.paymentPlan.period) || period;
    }

    return period;
  }

  private getNewOptions(ctx: StateContext<PlanStateModel>, next: PlanInfo): PlanCustomization[] {
    const plans = ctx.getState().plans;
    const current = this.getCurrentPlan(ctx);
    const options = this.getCurrentOptions(ctx);

    const oldIdx = plans.findIndex(({ id }) => id === current.id);
    const newIdx = plans.findIndex(({ id }) => id === next.id);

    if (oldIdx > newIdx) {
      return [];
    }

    if (oldIdx === newIdx) {
      return options;
    }

    return options
      .filter((option) => {
        const key = option.id.substr(6) as 'answers' | 'emails' | 'contacts';

        return option.value > next[key];
      })
      .map(({ id, value }) => ({
        id,
        value: value + current[id.substr(6)] - next[id.substr(6)],
      }))
      .filter(({ value }) => !!value);
  }
}
