import * as i0 from '@angular/core';
import { InjectionToken, Injectable, EventEmitter, Directive, Optional, Inject, Input, Output, Component, ViewEncapsulation, HostBinding, ViewChild, NgModule } from '@angular/core';
import Quill from 'quill';
import { CommonModule } from '@angular/common';
const _c0 = [[["", "quillToolbar", ""]], [["", "quillContent", ""]]];
const _c1 = ["[quillToolbar]", "[quillContent]"];
const QUILL_CONFIG = new InjectionToken('QUILL_CONFIG');
class QuillConfig {
  theme;
  debug;
  strict;
  formats;
  modules;
  readOnly;
  placeholder;
  bounds;
  scrollingContainer;
  constructor(config = {}) {
    this.assign(config);
  }
  assign(config = {}, target) {
    target = target || this;
    for (const key in config) {
      if (config[key] != null && !Array.isArray(config[key]) && typeof config[key] === 'object' && (typeof HTMLElement === 'undefined' || !(config[key] instanceof HTMLElement))) {
        target[key] = {};
        this.assign(config[key], target[key]);
      } else {
        target[key] = config[key];
      }
    }
  }
}
class QuillService {
  toolbar = null;
  constructor() {}
  getToolbar(toolbarConfig) {
    let toolbar = toolbarConfig;
    if (typeof toolbarConfig === 'string') {
      toolbar = document.querySelector(toolbarConfig);
    }
    if (typeof HTMLElement !== 'undefined' && toolbar instanceof HTMLElement) {
      if (!this.toolbar) {
        this.toolbar = toolbar;
      }
      const clone = this.toolbar.cloneNode(true);
      if (toolbar.parentNode) {
        toolbar.parentNode.replaceChild(clone, toolbar);
      }
      return clone;
    }
    return toolbarConfig;
  }
  static ɵfac = function QuillService_Factory(t) {
    return new (t || QuillService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: QuillService,
    factory: QuillService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuillService, [{
    type: Injectable
  }], () => [], null);
})();
class QuillDirective {
  zone;
  elementRef;
  differs;
  service;
  defaults;
  instance = null;
  selection = null;
  hasFocus = false;
  showToolbar = false;
  configDiff = null;
  defaultToolbarConfig = [['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'], [{
    'header': 1
  }, {
    'header': 2
  }], [{
    'list': 'ordered'
  }, {
    'list': 'bullet'
  }], [{
    'script': 'sub'
  }, {
    'script': 'super'
  }], [{
    'indent': '-1'
  }, {
    'indent': '+1'
  }], [{
    'direction': 'rtl'
  }], [{
    'size': ['small', false, 'large', 'huge']
  }], [{
    'header': [1, 2, 3, 4, 5, 6, false]
  }], [{
    'color': []
  }, {
    'background': []
  }], [{
    'font': []
  }], [{
    'align': []
  }], ['clean']];
  disabled = false;
  autoToolbar = false;
  realToolbar = false;
  config;
  modules;
  blur = new EventEmitter();
  focus = new EventEmitter();
  editorCreate = new EventEmitter();
  contentChange = new EventEmitter();
  selectionChange = new EventEmitter();
  constructor(zone, elementRef, differs, service, defaults) {
    this.zone = zone;
    this.elementRef = elementRef;
    this.differs = differs;
    this.service = service;
    this.defaults = defaults;
  }
  ngOnInit() {
    const params = new QuillConfig(this.defaults);
    params.assign(this.config); // Custom configuration
    if (this.disabled) {
      params.readOnly = true;
      params.modules = {
        toolbar: false
      }; // Disable toolbar
    } else {
      params.theme = params.theme || 'snow';
      params.modules = params.modules || {
        toolbar: true
      };
      params.modules.toolbar = params.modules.toolbar || false;
      if (params.modules.toolbar) {
        if (typeof params.modules.toolbar !== 'object' || params.modules.toolbar.container === undefined) {
          params.modules.toolbar = {
            container: params.modules.toolbar === true ? this.defaultToolbarConfig : params.modules.toolbar
          };
        }
        if (this.autoToolbar && !this.showToolbar) {
          params.modules.toolbar = false;
        } else if (this.autoToolbar || !this.realToolbar) {
          const toolbar = params.modules.toolbar.container;
          params.modules.toolbar.container = this.service.getToolbar(toolbar);
        }
      }
    }
    this.zone.runOutsideAngular(() => {
      if (this.modules) {
        Object.keys(this.modules).forEach(path => {
          if (this.modules && this.modules[path]) {
            Quill.register(path, this.modules[path]);
          }
        });
      }
      this.instance = new Quill(this.elementRef.nativeElement, params);
      if (!params.readOnly) {
        this.instance.enable();
      } else {
        this.instance.disable();
      }
      if (this.editorCreate.observers.length) {
        this.editorCreate.emit(this.instance);
      }
    });
    // Reset selection after onDestroy if available
    if (this.instance && this.selection && this.hasFocus === true) {
      this.instance.setSelection(this.selection, 'silent');
      this.instance.focus();
    }
    if (this.instance) {
      // Add handling of text / content change events
      this.instance.on('text-change', (delta, oldDelta, source) => {
        const html = this.elementRef.nativeElement.children[0].innerHTML;
        this.zone.runOutsideAngular(() => {
          if (this.instance && this.contentChange.observers.length) {
            this.contentChange.emit({
              editor: this.instance,
              html: html === '<p><br></p>' ? null : html,
              text: this.instance.getText(),
              delta: delta,
              oldDelta: oldDelta,
              source: source
            });
          }
        });
      });
      // Add handling of blur / focus and selection events
      this.instance.on('selection-change', (range, oldRange, source) => {
        let resetToolbar = false;
        if (!range && this.hasFocus) {
          this.hasFocus = false;
          this.blur.emit(this.instance);
          if (this.autoToolbar && this.showToolbar) {
            this.showToolbar = false;
          }
        } else if (range && !this.hasFocus) {
          this.hasFocus = true;
          this.focus.emit(this.instance);
          // Check if reset is needed to update toolbar
          if (this.autoToolbar && !this.showToolbar) {
            resetToolbar = true;
            this.showToolbar = true;
          }
        } else {
          this.zone.runOutsideAngular(() => {
            if (this.instance && this.selectionChange.observers.length) {
              this.selectionChange.emit({
                editor: this.instance,
                range: range,
                oldRange: oldRange,
                source: source
              });
            }
          });
        }
        if (resetToolbar) {
          setTimeout(() => {
            this.ngOnDestroy();
            this.ngOnInit();
          }, 0);
        }
      });
    }
    if (!this.configDiff) {
      this.configDiff = this.differs.find(this.config || {}).create();
      this.configDiff.diff(this.config || {});
    }
  }
  ngOnDestroy() {
    if (this.instance) {
      const toolbar = this.instance.getModule('toolbar');
      this.selection = this.instance.getSelection();
      if (typeof HTMLElement !== 'undefined' && toolbar && toolbar.options && toolbar.container && !(toolbar.options.container instanceof HTMLElement)) {
        toolbar.container.remove();
      }
      if (this.elementRef && this.elementRef.nativeElement) {
        const preview = this.elementRef.nativeElement.querySelector('.ql-preview');
        if (preview) {
          preview.innerHTML = '';
        }
      }
      delete this.instance;
      this.instance = null;
    }
  }
  ngDoCheck() {
    if (this.configDiff) {
      const changes = this.configDiff.diff(this.config || {});
      if (changes) {
        this.ngOnDestroy();
        this.ngOnInit();
      }
    }
  }
  ngOnChanges(changes) {
    if (this.instance && changes['disabled']) {
      if (changes['disabled'].currentValue !== changes['disabled'].previousValue) {
        this.zone.runOutsideAngular(() => {
          this.ngOnDestroy();
          this.ngOnInit();
        });
      }
    }
  }
  quill() {
    return this.instance;
  }
  clear(source) {
    if (this.instance) {
      this.instance.deleteText(0, this.instance.getLength(), source);
    }
  }
  getValue() {
    if (this.instance) {
      return this.instance.getText();
    }
  }
  setValue(value, source) {
    if (this.instance) {
      this.clear(source);
      this.instance.clipboard.dangerouslyPasteHTML(value, source);
      this.instance.setSelection(this.instance.getLength(), 1);
    }
  }
  static ɵfac = function QuillDirective_Factory(t) {
    return new (t || QuillDirective)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.KeyValueDiffers), i0.ɵɵdirectiveInject(QuillService), i0.ɵɵdirectiveInject(QUILL_CONFIG, 8));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: QuillDirective,
    selectors: [["", "quill", ""]],
    inputs: {
      disabled: "disabled",
      autoToolbar: "autoToolbar",
      realToolbar: "realToolbar",
      config: [i0.ɵɵInputFlags.None, "quill", "config"],
      modules: "modules"
    },
    outputs: {
      blur: "blur",
      focus: "focus",
      editorCreate: "editorCreate",
      contentChange: "contentChange",
      selectionChange: "selectionChange"
    },
    exportAs: ["ngxQuill"],
    features: [i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuillDirective, [{
    type: Directive,
    args: [{
      selector: '[quill]',
      exportAs: 'ngxQuill'
    }]
  }], () => [{
    type: i0.NgZone
  }, {
    type: i0.ElementRef
  }, {
    type: i0.KeyValueDiffers
  }, {
    type: QuillService
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [QUILL_CONFIG]
    }]
  }], {
    disabled: [{
      type: Input
    }],
    autoToolbar: [{
      type: Input
    }],
    realToolbar: [{
      type: Input
    }],
    config: [{
      type: Input,
      args: ['quill']
    }],
    modules: [{
      type: Input,
      args: ['modules']
    }],
    blur: [{
      type: Output
    }],
    focus: [{
      type: Output
    }],
    editorCreate: [{
      type: Output
    }],
    contentChange: [{
      type: Output
    }],
    selectionChange: [{
      type: Output
    }]
  });
})();
class QuillComponent {
  content = null;
  set value(value) {
    this.setContent(value);
  }
  disabled = false;
  autoToolbar = false;
  realToolbar = false;
  config;
  modules;
  useQuillClass = true;
  blur = new EventEmitter();
  focus = new EventEmitter();
  valueChange = new EventEmitter();
  editorCreate = new EventEmitter();
  contentChange = new EventEmitter();
  selectionChange = new EventEmitter();
  directiveRef;
  constructor() {}
  ngAfterViewInit() {
    if (this.content != null) {
      this.setContent(this.content, true);
    }
  }
  setContent(value, force) {
    if (force || value !== this.content) {
      if (this.directiveRef) {
        this.directiveRef.setValue(value, 'silent');
      }
      this.content = value;
    }
  }
  onContentChange(event) {
    this.content = event.html;
    this.contentChange.emit(event);
    this.valueChange.emit(event.html);
  }
  static ɵfac = function QuillComponent_Factory(t) {
    return new (t || QuillComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: QuillComponent,
    selectors: [["quill"]],
    viewQuery: function QuillComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(QuillDirective, 7);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.directiveRef = _t.first);
      }
    },
    hostVars: 2,
    hostBindings: function QuillComponent_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("quill", ctx.useQuillClass);
      }
    },
    inputs: {
      value: "value",
      disabled: "disabled",
      autoToolbar: "autoToolbar",
      realToolbar: "realToolbar",
      config: "config",
      modules: "modules",
      useQuillClass: "useQuillClass"
    },
    outputs: {
      blur: "blur",
      focus: "focus",
      valueChange: "valueChange",
      editorCreate: "editorCreate",
      contentChange: "contentChange",
      selectionChange: "selectionChange"
    },
    exportAs: ["ngxQuill"],
    ngContentSelectors: _c1,
    decls: 3,
    vars: 5,
    consts: [[3, "blur", "focus", "editorCreate", "contentChange", "selectionChange", "quill", "modules", "disabled", "autoToolbar", "realToolbar"]],
    template: function QuillComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef(_c0);
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("blur", function QuillComponent_Template_div_blur_0_listener($event) {
          return ctx.blur.emit($event);
        })("focus", function QuillComponent_Template_div_focus_0_listener($event) {
          return ctx.focus.emit($event);
        })("editorCreate", function QuillComponent_Template_div_editorCreate_0_listener($event) {
          return ctx.editorCreate.emit($event);
        })("contentChange", function QuillComponent_Template_div_contentChange_0_listener($event) {
          return ctx.onContentChange($event);
        })("selectionChange", function QuillComponent_Template_div_selectionChange_0_listener($event) {
          return ctx.selectionChange.emit($event);
        });
        i0.ɵɵprojection(1);
        i0.ɵɵprojection(2, 1);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵproperty("quill", ctx.config)("modules", ctx.modules)("disabled", ctx.disabled)("autoToolbar", ctx.autoToolbar)("realToolbar", ctx.realToolbar);
      }
    },
    dependencies: [QuillDirective],
    styles: ["@import\"https://cdn.quilljs.com/1.3.5/quill.snow.css\";@import\"https://cdn.quilljs.com/1.3.5/quill.bubble.css\";.quill{display:block;width:100%;height:100%}.quill>.ql-toolbar>.ql-formats{margin-right:12px;margin-left:0}.quill>.ql-toolbar+.ql-container{height:calc(100% - 42px)}.quill.flex{display:flex;flex-direction:inherit;align-items:inherit;min-width:0;min-height:0}.quill.flex>.ql-toolbar{flex:0 0 auto;-webkit-box-flex:0}.quill.flex>.ql-container{flex:1 1 auto;min-width:0;min-height:0;-webkit-box-flex:1}.quill .ql-snow .ql-tooltip:before{display:none!important}\n"],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuillComponent, [{
    type: Component,
    args: [{
      selector: 'quill',
      exportAs: 'ngxQuill',
      encapsulation: ViewEncapsulation.None,
      template: "<div [quill]=\"config\" [modules]=\"modules\" [disabled]=\"disabled\" [autoToolbar]=\"autoToolbar\" [realToolbar]=\"realToolbar\" (blur)=\"blur.emit($event)\" (focus)=\"focus.emit($event)\" (editorCreate)=\"editorCreate.emit($event)\" (contentChange)=\"onContentChange($event)\" (selectionChange)=\"selectionChange.emit($event)\">\n  <ng-content select=\"[quillToolbar]\"></ng-content>\n\n  <ng-content select=\"[quillContent]\"></ng-content>\n</div>\n",
      styles: ["@import\"https://cdn.quilljs.com/1.3.5/quill.snow.css\";@import\"https://cdn.quilljs.com/1.3.5/quill.bubble.css\";.quill{display:block;width:100%;height:100%}.quill>.ql-toolbar>.ql-formats{margin-right:12px;margin-left:0}.quill>.ql-toolbar+.ql-container{height:calc(100% - 42px)}.quill.flex{display:flex;flex-direction:inherit;align-items:inherit;min-width:0;min-height:0}.quill.flex>.ql-toolbar{flex:0 0 auto;-webkit-box-flex:0}.quill.flex>.ql-container{flex:1 1 auto;min-width:0;min-height:0;-webkit-box-flex:1}.quill .ql-snow .ql-tooltip:before{display:none!important}\n"]
    }]
  }], () => [], {
    value: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    autoToolbar: [{
      type: Input
    }],
    realToolbar: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    modules: [{
      type: Input
    }],
    useQuillClass: [{
      type: HostBinding,
      args: ['class.quill']
    }, {
      type: Input
    }],
    blur: [{
      type: Output
    }],
    focus: [{
      type: Output
    }],
    valueChange: [{
      type: Output
    }],
    editorCreate: [{
      type: Output
    }],
    contentChange: [{
      type: Output
    }],
    selectionChange: [{
      type: Output
    }],
    directiveRef: [{
      type: ViewChild,
      args: [QuillDirective, {
        static: true
      }]
    }]
  });
})();
class QuillModule {
  static ɵfac = function QuillModule_Factory(t) {
    return new (t || QuillModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: QuillModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [QuillService],
    imports: [CommonModule, CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuillModule, [{
    type: NgModule,
    args: [{
      declarations: [QuillComponent, QuillDirective],
      imports: [CommonModule],
      exports: [CommonModule, QuillComponent, QuillDirective],
      providers: [QuillService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { QUILL_CONFIG, QuillComponent, QuillConfig, QuillDirective, QuillModule, QuillService };
