<ng-template
  [zefSidenav]="true"
  [zefSidenavConfig]="config"
  [zefSidenavWidth]="config.width"
  [zefSidenavPosition]="config.position"
  [zefSidenavScrim]="config.scrim !== false"
  [zefSidenavBackdrop]="config.backdrop !== false"
  (zefSidenavAfterClosed)="afterClose()"
>
  <ng-container *ngComponentOutlet="config?.component"></ng-container>
</ng-template>
