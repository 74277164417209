<div class="consent-container z-fx-col z-fx-gap-16">
  <p i18n>
    This website stores cookies on your computer. These cookies are used to improve our website and provide more
    personalized services to you, both on this website and through other media. To find out more about the cookies we
    use, see our
    <a class="zef-hyper-link" href="https://zef.fi/privacy-policy/" target="_blank">Privacy Policy.</a>
  </p>

  <div class="buttons z-fx z-fx-end-center z-fx-gap-8">
    <button mat-raised-button i18n color="primary" class="action-button" id="consent-accept" (click)="onAccept()">
      Accept
    </button>

    <button mat-raised-button i18n class="action-button alert" id="consent-decline" (click)="onDecline()">
      Decline
    </button>
  </div>
</div>
