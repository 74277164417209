/**
 * Dahboard card types.
 *
 * @unstable
 */

import { CardData } from '@shared/models/prefs.model';

export class Cards {
  static readonly ANALYZE_CARD: string = 'analyze-card';
  static readonly CREATE_SURVEY: string = 'create-survey';
  static readonly RECENT_SURVEYS: string = 'recent-surveys';

  static getGridData(card: CardData, payload?: boolean): any {
    return Object.assign(card.gridSettings, payload ? { payload: card['$key'], dragHandle: '.title-text' } : {});
  }
}
