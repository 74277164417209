import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Component, Input, HostBinding, ViewChild } from '@angular/core';

import { ImageGalleryItem } from '@shared/modules/image-upload/models/image-upload.models';
import { ImageUploadService } from '@shared/modules/image-upload/services/image-upload.service';

@Component({
  selector: 'image-upload-item',
  templateUrl: './image-upload-item.component.html',
  styleUrls: ['./image-upload-item.component.scss'],
})
export class ImageUploadItemComponent {
  @Input()
  image?: ImageGalleryItem;

  @ViewChild(MatMenuTrigger)
  trigger?: MatMenuTrigger;

  @HostBinding('class.menu-open')
  get menuOpen(): boolean {
    return this.trigger?.menuOpen || this.isMenuOpen;
  }

  // Material bug. Trigger menu open class binding does not get updated
  isMenuOpen = false;

  constructor(readonly iu: ImageUploadService) {}
}
