import { Component } from '@angular/core';

import { HelpIcon } from '@shared/modules/help-center/components/help-icon/help-icon.component';
import { HelpCenterItemStatus } from '@shared/modules/help-center/help-center.models';

@Component({
  selector: 'zef-help-link',
  templateUrl: './help-link.component.html',
  styleUrls: ['./help-link.component.scss'],
})
export class HelpLink extends HelpIcon {
  readonly statusColorMap: Record<HelpCenterItemStatus, string> = {
    [HelpCenterItemStatus.Missing]: 'zef-color-alert',
    [HelpCenterItemStatus.Partial]: 'zef-color-warning',
    [HelpCenterItemStatus.Complete]: 'zef-color-ink-lightest',
  };
}
