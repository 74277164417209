import { DatatableSettings } from '@shared/models/datatable.model';
import { Integratable, IntegrationListLink, IntegrationPropertyLink } from '@shared/models/integrations.model';
import { SortDirection } from '@shared/components/table/models/table.models';

/**
 * Contacts related data models.
 *
 * @unstable
 */

export interface ContactData {
  entry: ContactEntryData;
  fields: ContactFieldsData;
}

export interface ContactEntryData {
  id: number;

  name?: string;
  lastName?: string;
  firstName?: string;
  email?: string;
  phone?: string;

  photo?: string;
  thumb?: string;

  source: string;
  creator: string;

  created: number;
  modified: number;

  collectionMethod: number;
}

export interface ContactFieldsData {
  [key: string]: any;
}

export class ContactItemData {
  id: number;

  name?: string;
  email?: string;
  phone?: string;

  photo?: string;
  thumb?: string;

  source: string;
  creator: string;

  created: number;
  modified: number;

  [key: string]: any;

  lastName?: string;
  firstName?: string;

  sourceName?: string;
  creatorName?: string;
}

export class ContactsDataResponse {
  startIndex: number;
  totalCount: number;

  result: ContactData[];

  constructor(data: any = {}) {
    this.result = data.result || null;

    this.startIndex = data.startIndex || 0;
    this.totalCount = data.totalCount || 0;
  }
}

export class ContactsItemResponse {
  startIndex: number;
  totalCount: number;

  result: ContactItemData[];

  constructor(data: any = {}) {
    this.result = data.result || [];

    this.startIndex = data.startIndex || 0;
    this.totalCount = data.totalCount || 0;
  }
}

export interface ContactsListData extends Integratable<IntegrationListLink> {
  id: number;
  name: string;

  // @deprecated: use creator
  author: string;
  owner: string;
  creator: string;
  created: string;
  updated: string;

  contactCount: number;
  lockedContactCount: number;
  includesMembers: number;
  hasPhones: boolean;
  hasEmails: boolean;

  members?: number[];
  source?: ContactSourceType;
}

export class ContactsListResponse {
  startIndex: number;
  totalCount: number;

  result: ContactsListData[];

  constructor(data: any = {}) {
    this.result = data.result || [];

    this.startIndex = data.startIndex || 0;
    this.totalCount = data.totalCount || 0;
  }
}

export enum ContactSourceType {
  Manual = 'Manual',
  Survey = 'Survey',
  Import = 'Import',
  CopyPaste = 'CopyPaste',
  Integration = 'Integration',
  API = 'API',
}

export type ColumnType = 'email' | 'text' | 'numeric' | 'auto' | 'date' | 'phone';

export type ContactImportStep = 'source' | 'config' | 'select' | 'review' | 'import';

export interface ContactColumn extends Integratable<IntegrationPropertyLink> {
  $key: string;
  name: string;
  type: ColumnType;
  visible?: boolean;
  required?: boolean;
  editable?: boolean;
  deleted?: boolean;
  added?: boolean;
  order?: number;
}

export interface ContactProperty {
  id: string;
  name: string;
}

export interface ImportColumn {
  key?: string;
  name: string;
  type: ColumnType;
  rows: string[];
}

export interface ContactImportSettings {
  textDelimiter: string | null;
  textQualifier: string | null;
  encoding: string;
  firstRowHasHeaders: boolean | null;
  repeatingDelimsAsOne: boolean;
  countryCode: string | null;
}

export interface ContactImportSource {
  gdprAccepted: boolean;
  type: 'text' | 'file';
  fileName?: string;
  pasteData?: string;
  progress?: number;
  error?: boolean;
  errorMessage?: string;
  list?: ContactsListData;
  invite?: string;
  inviteKind?: 'email' | 'sms';
}

export interface ContactImportReview {
  countryCode: string;
  list: {
    id?: string | number;
    name?: string;
    author?: string;
  };
  resolveWith: string;
}

export interface ContactsImportModelPreview {
  columns: ImportColumn[];
  sample: ImportColumn[];
  hasEmails: boolean;
  hasPhoneNumbers: boolean;
  maxRows: number;
  maxColumns: number;
}

export interface ContactsImportModel {
  step: ContactImportStep;
  settings: ContactImportSettings;
  source: ContactImportSource;
  preview?: ContactsImportModelPreview;
  columns?: SelectColumn[];
  review?: ContactImportReview;
}

export interface ImportColumnData {
  rows: ImportColumn[];
  countryCode: string;
  hasEmails: boolean;
  hasPhoneNumbers: boolean;
  delimiter?: string;
  qualifier?: string;
}

export enum ContactImportError {
  NOT_ACCEPTED,
  NO_PASTE_DATA,
  WRONG_FORMAT,

  NOT_SELECTED,
  NAME_EXISTS,
  NO_NAME,
  NO_TYPE,
  WRONG_TYPE,

  ZERO_MATCHED,
  NO_ID_COLUMNS,
  MULTIPLE_IDS,
  HAS_UNMATCHED,
}

export type DuplicatePolicy = 'merge' | 'keep_original' | 'replace';

export type SelectColumnState = 'selected' | 'unmatched' | 'skipped' | 'edit';

export interface SelectColumn extends ImportColumn {
  matchedColumn?: ContactColumn;
  state: SelectColumnState;
  editing?: boolean;
}

/**
 * Column configuration data saved in Firebase for a contact import
 */
export interface FbContactsImportColumns {
  key: string;
  name: string;
  type: ColumnType;

  visible?: boolean;
  skipped?: boolean;
}

export interface FbContactsColumn {
  name: string;
  type: ColumnType;
}

export interface FbContactsImportOptions {
  encoding: string;
  textDelimiter: string;
  textQualifier: string;
  duplicatePolicy?: DuplicatePolicy;
  firstRowHasHeaders: boolean;
  repeatingDelimsAsOne: boolean;
  repairCode?: string;
  updateKey?: ColumnType;
}

export interface FbContactsImport {
  list: { id: string | number; name: string };
  options: FbContactsImportOptions;
  columns: FbContactsImportColumns[];
  invite?: string;
  inviteKind?: 'email' | 'sms';
  user?: string;
}

export interface ContactsSearchParams {
  type: string | null;
  start: number | null;
  count: number | null;
  search: string | null;
  sort: string | null;
  order: string | null;
  listId: number | null;
  ids: number[] | null;
  refresh: boolean | null;
  creator: string[] | null;
  owner: string | null;
  onlyIds: boolean | null;
  includedMembers: number[] | null;
}

export interface ContactsViewSettings<T> extends DatatableSettings<T> {
  identities?: object | null;
  listId?: string | null;
  searchQuery: string | null;
}

export function ContactsSearchParams(settings): Partial<ContactsSearchParams> {
  return {
    count: settings.limit,
    start: settings.offset * settings.limit,
    search: settings.searchQuery,
    sort: settings.sortProp,
    order: settings.sortOrder,
    listId: settings.listId,
    creator: settings.creatorKeys,
  };
}

export interface ContactsPagePrefs {
  contactsSortDir: SortDirection;
  contactsSortCol: string;
  listsSortDir: SortDirection;
  listsSortProp: string;
  view: 'contacts' | 'lists';
}

export interface ImportPrefs {
  defaultRepairCountry: string;
}

export interface ChatPrefs {
  helpHidden: boolean;
  latestChatId?: number;
  latestChatDate?: number;
}

export interface UniqueContactsResponse {
  uniqueContacts: number;
  uniqueContactsInLists: number;
  uniqueLockedContactsInLists: number;
}

export interface UniqueContactsInfoResponse {
  uniqueEmails: number;
  uniquePhones: number;
  uniqueContacts: number;
  uniqueLockedContacts: number;
}

export interface UserContactsImport extends FbContactsImport {
  $key: string;
}
