<div *ngIf="sliderStepsTmpl?.length" class="z-fx z-fx-fill z-fx-around-start slider-steps">
  <ng-container *ngFor="let step of sliderStepsTmpl; let idx = index">
    <div
      class="z-fx-flex zef-text-center zef-text-p2 slider-step"
      [ngClass]="isStepActive(idx) ? 'zef-color-primary' : 'zef-text-p2-muted'"
      (click)="onStepClick(idx)"
    >
      <mat-icon *ngIf="idx && funnelMode" class="zef-color-space-darkest zef-icon-large slider-funnel">
        breadcrumb
      </mat-icon>

      <ng-container [ngTemplateOutlet]="step.tr"></ng-container>
    </div>
  </ng-container>
</div>

<div *ngIf="!disabled" #wrapper class="wrapper">
  <div class="track">
    <div *ngFor="let _ of steps_" class="step"></div>
    <div class="progress" [ngStyle]="progressStyle$ | async"></div>
  </div>

  <div *ngIf="thumb1 != null" class="thumb" [style.left.%]="thumb1Left$ | async"></div>
  <div class="thumb" [style.left.%]="thumb2Left$ | async"></div>
</div>

<div *ngIf="sliderLabelTmpl?.tr && !disabled" class="label-wrapper">
  <div class="label" [ngStyle]="labelStyle$ | async" #sliderLabel>
    <ng-container [ngTemplateOutlet]="sliderLabelTmpl.tr"></ng-container>
  </div>
</div>
