import { AsyncPipe } from '@angular/common';
import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { SourceType } from '@shared/models/utility.model';
import { ItemSearchHelper } from '@shared/services/item-search-helper.service';
import { ContactSourceType } from '@shared/models/contact.model';

@Pipe({
  name: 'keyToName',
  pure: false,
})
export class KeyToNamePipe implements PipeTransform, OnDestroy {
  private readonly keyContactMap: Record<ContactSourceType, SourceType> = {
    [ContactSourceType.Manual]: 'user',
    [ContactSourceType.Import]: 'user',
    [ContactSourceType.CopyPaste]: 'user',
    [ContactSourceType.Survey]: 'survey',
    [ContactSourceType.Integration]: 'integration',
    [ContactSourceType.API]: 'api',
  };

  private readonly ap = new AsyncPipe(this.cd);

  constructor(
    private cd: ChangeDetectorRef,
    private is: ItemSearchHelper,
  ) {}

  transform(key: string, type: SourceType | ContactSourceType): string {
    const sourceType: SourceType = this.keyContactMap[type] || type || 'user';

    return this.ap.transform(this.is.getItemName(sourceType, key));
  }

  ngOnDestroy(): void {
    this.ap.ngOnDestroy();
  }
}
