import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { NgxsModule } from '@ngxs/store';

import { SpinnerModule } from '@shared/components/spinner/spinner.module';

import { FileUploadModule } from '@shared/modules/file-upload/file-upload.module';
import { ImageUploadState } from '@shared/modules/image-upload/image-upload.state';
import { ImageUploadDefaultDirective } from '@shared/modules/image-upload/directive/image-upload-default.directive';
import { ImageUploadComponent } from '@shared/modules/image-upload/components/image-upload/image-upload.component';
import { ImageUploadAreaComponent } from '@shared/modules/image-upload/components/image-upload-area/image-upload-area.component';
import { ImageUploadItemComponent } from '@shared/modules/image-upload/components/image-upload-item/image-upload-item.component';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    SpinnerModule,
    NgxsModule.forFeature([ImageUploadState]),
    FileUploadModule,
  ],
  declarations: [ImageUploadComponent, ImageUploadAreaComponent, ImageUploadItemComponent, ImageUploadDefaultDirective],
  exports: [ImageUploadComponent, ImageUploadDefaultDirective],
})
export class ImageUploadModule {}
