import { ChangeDetectionStrategy, Component, Host, Input, Optional } from '@angular/core';

import { Status } from '@shared/models/status.model';
import { MatButtonToggle } from '@angular/material/button-toggle';

@Component({
  selector: 'zef-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIcon {
  @Input()
  status: Status;

  @Input()
  type: 'inline' | 'toggle' = 'inline';

  constructor(@Host() @Optional() readonly toggle: MatButtonToggle) {}
}
