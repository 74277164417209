import { ImageUploadDirectory, ImageGalleryItem } from '@shared/modules/image-upload/models/image-upload.models';

export class UploadImage {
  static type = '[ImageUpload] - Upload';

  constructor(
    readonly directory: ImageUploadDirectory,
    readonly image: File,
  ) {}

  toJSON = () => ({ ...this, type: UploadImage.type, image: this.image.name });
}

export class GetImages {
  static type = '[ImageUpload] - Get';

  constructor(readonly directory: ImageUploadDirectory) {}
}

export class RemoveImage {
  static type = '[ImageUpload] - Remove';

  constructor(
    readonly directory: ImageUploadDirectory,
    readonly image: string,
  ) {}
}

export class UploadImageProgress {
  static type = '[ImageUpload] - Progress';

  constructor(
    readonly image: File,
    readonly progress: number,
  ) {}

  toJSON = () => ({ ...this, type: UploadImageProgress.type, image: this.image.name });
}

export class UploadImageComplete {
  static type = '[ImageUpload] - Complete';

  constructor(
    readonly directory: ImageUploadDirectory,
    readonly image: ImageGalleryItem,
    readonly file: File,
  ) {}

  toJSON = () => ({ ...this, type: UploadImageComplete.type, file: this.file.name });
}
