<ng-template #content>
  <div class="zef-code-p1" [class.single-line]="!multiLine">
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-scrollbar [autoHeightDisabled]="false" *ngIf="!multiLine; else content" track="horizontal">
  <ng-template [ngTemplateOutlet]="content"></ng-template>
</ng-scrollbar>

<button
  *ngIf="copyContent"
  [zefCopyToClipboard]="copyContent"
  [copyToClipboardNotice]="copyToClipboardNotice"
  [copyToClipboardAction]="copyToClipboardAction"
  [copyToClipboardActionName]="copyToClipboardActionName"
  color="ink"
  class="zef-ghost-button"
  mat-icon-button
>
  <mat-icon>action_copy</mat-icon>
</button>
