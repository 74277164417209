import {
  ANIMATION_MODULE_TYPE,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  NgZone,
  Optional,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  LegacyTooltipComponent as TooltipComponent,
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
  MAT_LEGACY_TOOLTIP_SCROLL_STRATEGY as MAT_TOOLTIP_SCROLL_STRATEGY,
  MatLegacyTooltip as MatTooltip,
  MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions,
} from '@angular/material/legacy-tooltip';

import { Store } from '@ngxs/store';

import { PrefsState } from '@shared/states/prefs.state';
import { IncrementHelpItemStats } from '@shared/modules/help-center/state/help-center.actions';
import { HelpCenterLanguage, HelpItemSubject } from '@shared/modules/help-center/help-center.models';
import { Overlay, ScrollDispatcher } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT } from '@angular/common';

@Component({
  templateUrl: 'help-tooltip.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpTooltipComponent extends TooltipComponent {
  @HostBinding('style.zoom')
  get zoom(): number | null {
    return this.isVisible() ? 1 : null;
  }

  @HostBinding('attr.aria-hidden')
  readonly ariaHidden = true;

  subject?: HelpItemSubject;

  constructor(
    cd: ChangeDetectorRef,
    bo: BreakpointObserver,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) am?: string,
    private store?: Store,
  ) {
    super(cd, bo, am);
  }

  protected _onShow() {
    super._onShow();

    if (this.subject && this.store) {
      const lang = this.store.selectSnapshot(PrefsState.language) as HelpCenterLanguage;
      this.store.dispatch(new IncrementHelpItemStats(this.subject, 'hovers', lang));
    }
  }
}

@Directive({
  selector: '[helpTooltip]',
  exportAs: 'helpTooltip',
})
export class HelpTooltipDirective extends MatTooltip {
  @HostBinding('class.mat-tooltip-trigger')
  readonly trigger = true;

  @Input()
  set helpTooltip(message: string) {
    this.message = message;
  }

  @Input()
  subject?: HelpItemSubject;

  protected readonly _tooltipComponent = HelpTooltipComponent;

  constructor(
    ol: Overlay,
    el: ElementRef<HTMLElement>,
    sd: ScrollDispatcher,
    vc: ViewContainerRef,
    ng: NgZone,
    pl: Platform,
    ad: AriaDescriber,
    fm: FocusMonitor,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) ss: any,
    @Optional() dir: Directionality,
    @Optional() @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS) mdo: MatTooltipDefaultOptions,
    @Inject(DOCUMENT) doc: Document,
  ) {
    super(ol, el, sd, vc, ng, pl, ad, fm, ss, dir, mdo, doc);
  }

  show(delay?: number): void {
    super.show(delay);

    if (this._tooltipInstance) {
      (this._tooltipInstance as HelpTooltipComponent).subject = this.subject;
    }
  }
}
