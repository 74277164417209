import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { TeamFeature } from '@shared/models/features.model';
import { UpdateTeamFeature } from '@shared/states/account.actions';
import { AccountState } from '@shared/states/account.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'zef-beta-button',
  templateUrl: './beta-button.component.html',
  styleUrls: ['./beta-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BetaButton {
  @Select(AccountState.isTeamAdmin)
  readonly isTeamAdmin$!: Observable<boolean>;

  @Select(AccountState.isFeatureEnabled())
  readonly isEnabled$!: Observable<boolean>;

  constructor(private store: Store) {}

  enableExperimental() {
    this.store.dispatch([
      new UpdateTeamFeature(TeamFeature.ENABLE_EXPERIMENTAL, true),
      new UpdateTeamFeature(TeamFeature.ALL_FEATURES, true),
      new Navigate(['/settings/org']),
    ]);
  }
}
