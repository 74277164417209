<div class="z-fx z-fx-start-center z-fx-gap-8">
  <ng-container *ngIf="!isMultiSelect || (!selected && prefix); else multiSelectTmpl">
    <div *ngIf="prefix" class="select-item-prefix z-fx-none">
      <ng-container [ngTemplateOutlet]="prefix" [ngTemplateOutletContext]="{ $implicit: value }"></ng-container>
    </div>
  </ng-container>

  <ng-template #multiSelectTmpl>
    <mat-checkbox
      *ngIf="!radioType"
      [checked]="selected"
      color="primary"
      class="zef-pointer-events-none no-margin"
      tabindex="-1"
    ></mat-checkbox>

    <mat-radio-button
      *ngIf="radioType"
      [checked]="selected"
      color="primary"
      class="zef-pointer-events-none"
      tabindex="-1"
    ></mat-radio-button>
  </ng-template>

  <div
    *ngIf="label; else valueAsLabel"
    class="select-item-label zef-text-ellipsis z-fx-flex"
    [class.placeholder]="placeholder"
    textOverflow
  >
    <ng-container [ngTemplateOutlet]="label" [ngTemplateOutletContext]="{ $implicit: value }"></ng-container>

    <ng-container *ngIf="placeholder">...</ng-container>
  </div>
  <ng-template #valueAsLabel>
    <div
      *ngIf="!valuePrefixOnly"
      class="select-item-label zef-text-ellipsis z-fx-flex"
      [class.placeholder]="placeholder"
      textOverflow
    >
      {{ value }}
    </div>
  </ng-template>
</div>
