import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { patch, removeItem, insertItem } from '@ngxs/store/operators';
import { State, Action, StateContext, createSelector } from '@ngxs/store';

import { ImageUploadManager } from '@shared/modules/image-upload/image-upload-manager.service';
import { ImageUploadDirectory, ImageGalleryItem } from '@shared/modules/image-upload/models/image-upload.models';
import { UploadImage, GetImages, RemoveImage } from '@shared/modules/image-upload/image-upload.actions';

export type ImageUploadStateModel = Partial<Record<ImageUploadDirectory, ImageGalleryItem[]>>;

@State<ImageUploadStateModel>({
  name: 'imageUpload',
  defaults: {},
})
@Injectable()
export class ImageUploadState {
  static images(directory: ImageUploadDirectory) {
    return createSelector([ImageUploadState], (state): ImageGalleryItem[] | undefined => state[directory]);
  }

  constructor(private im: ImageUploadManager) {}

  @Action(UploadImage)
  uploadImage({ setState }: StateContext<ImageUploadStateModel>, { directory, image }: UploadImage): Observable<any> {
    return this.im
      .uploadImage(directory, image)
      .pipe(map((newImage) => setState(patch({ [directory]: insertItem(newImage) }))));
  }

  @Action(GetImages)
  getImages({ setState }: StateContext<ImageUploadStateModel>, { directory }: GetImages): Observable<any> {
    return this.im.getImages(directory).pipe(map(({ images }) => setState(patch({ [directory]: images }))));
  }

  @Action(RemoveImage)
  removeImage({ setState }: StateContext<ImageUploadStateModel>, { directory, image }: RemoveImage): Observable<void> {
    setState(
      patch({
        [directory]: removeItem<ImageGalleryItem>((item) => item.name === image),
      }),
    );

    return this.im.removeImage(image);
  }
}
