<div *cdkPortal class="wrapper" [ngStyle]="{ 'z-index': layer + 1 }" [ngClass]="align">
  <div
    class="panel"
    [style.width]="width"
    [@panelState]="state"
    (@panelState.start)="closing = $event.fromState?.slice(0, 6) === 'opened'"
    (@panelState.done)="closing = false"
  >
    <div class="body z-fx-col" [class.closed]="!isOpen()" [class.has-open-childs]="hasOpenChilds()">
      <ng-container *ngIf="isOpen() && template" [ngTemplateOutlet]="template"></ng-container>

      <ng-container *ngIf="!template">
        <ng-content></ng-content>
      </ng-container>
    </div>
  </div>
</div>
