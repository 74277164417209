import { timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'zef-spinner-beta',
  templateUrl: 'spinner-beta.component.html',
  styleUrls: ['./spinner-beta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerBetaComponent {
  readonly state$ = timer(0, 1000).pipe(map((time) => `state-${time % 4}`));
}
