<div class="nodes" [ngClass]="state$ | async">
  <div class="node node-1"></div>
  <div class="node node-2"></div>
  <div class="node node-3"></div>
  <div class="node node-4"></div>
  <div class="node node-5"></div>
  <div class="node node-6"></div>
  <div class="node node-7"></div>
  <div class="node node-8"></div>
</div>
