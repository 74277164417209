import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

export type ChipType = 'small' | 'medium' | 'large' | 'property' | 'hashtag';

@Component({
  selector: 'zef-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Chip implements OnChanges {
  @Output()
  readonly remove = new EventEmitter<void>();

  @Input()
  color?: string;

  @Input()
  transparency?: 10 | 20 | 30 | 50;

  @Input()
  type: ChipType = 'medium';

  @Input()
  bordered?: boolean;

  @Input()
  class?: string;

  @Input()
  newVersion?: boolean;

  @Input()
  @HostBinding('class.chip-disabled')
  disabled: boolean;

  @Input()
  @HostBinding('class.loading')
  loading: boolean;

  @Input()
  @HostBinding('class.chip-removable')
  removable: boolean;

  @HostBinding('class')
  classNames: string = '';

  @HostBinding('tabindex')
  tabindex: number = -1;

  private typeToTextClass: Record<ChipType, string> = {
    small: 'zef-text-p3',
    medium: 'zef-text-p3',
    large: 'zef-text-p1',
    property: 'zef-text-p1',
    hashtag: 'zef-text-p1-muted',
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (this.shouldUpdateWithChanges(changes)) {
      this.classNames = this.getClasses().join(' ');
    }
  }

  protected getClasses(): string[] {
    let color = this.color;

    if (this.type === 'property') {
      color = 'primary-light';
    }

    if (this.type === 'hashtag') {
      color = 'space-darkest';
    }

    if (color && this.transparency) {
      color += `-${this.transparency} zef-color-${color} zef-color-important`;
    }

    if (color === 'secondary') {
      color += ' zef-border-ink zef-color-ink';
    }

    if (this.bordered) {
      color = ` zef-border-${color} zef-color-${color}`;
    }

    const classes = [
      ...(this.type ? [`zef-chip-${this.type}`] : []),
      ...(this.type ? [this.typeToTextClass[this.type]] : []),
      ...(this.class?.split(' ') || []),
    ];

    if (this.newVersion) {
      return [...(color ? [`zef-color-${color}`] : []), ...(color ? [`zef-bgcolor-light-${color}`] : []), ...classes];
    } else {
      return [...(color ? [`zef-bgcolor-${color}`] : []), ...classes];
    }
  }

  protected shouldUpdateWithChanges(changes: SimpleChanges): boolean {
    return !!(changes.class || changes.type || changes.color || changes.transparency || changes.bordered);
  }
}
