<div [class.zef-card-z0-soft]="!noBackground" [ngClass]="variant">
  <figure [style.background-image]="teamLogoUrl() | encodeURI: 'url' | safeStyle"></figure>

  <span
    class="zef-text-h3"
    [ngClass]="{ 'zef-text-overflow-double': variant === 'big', 'zef-text-ellipsis': variant === 'small' }"
  >
    <ng-container *ngIf="teamName">{{ teamName }}</ng-container>
    <em *ngIf="!teamName" i18n>Unnamed organization</em>
  </span>

  <ng-content></ng-content>
</div>
