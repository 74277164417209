/**
 * Lang changed guard.
 *
 * @unstable
 */

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { PrefsState } from '@shared/states/prefs.state';

import { GetCountry, GetLanguage } from '@shared/states/prefs.actions';

@Injectable()
export class LangChanged {
  constructor(
    @Inject(LOCALE_ID) readonly locale: string,
    readonly store: Store,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const lang = this.locale.split('-')[0];

    return this.store.dispatch([new GetCountry(), new GetLanguage()]).pipe(
      map(() => {
        const newLang = this.store.selectSnapshot(PrefsState.language);

        if (newLang && lang !== newLang && window.location.href.indexOf('localhost') === -1 && !_route?.params?.poll) {
          console.log('Reloading due to language change:', lang, newLang);
          window.location.assign(state.url);

          return false;
        } else {
          return true;
        }
      }),
    );
  }
}
