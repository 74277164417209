import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { timer } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'zef-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss'],
})
export class Searchbox implements AfterViewInit {
  @Input()
  value?: string = '';

  @Input()
  autofocus?: boolean = false;

  @Input()
  space = true;

  @Input()
  debounceTime: number = 0;

  private readonly input$ = new EventEmitter<string>();

  @Output()
  readonly input = this.input$.pipe(debounce(() => timer(this.debounceTime)));

  @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>;

  emit(event: Event): void {
    event.stopPropagation();
    const value = this.inputField.nativeElement.value?.toLocaleLowerCase() || '';
    this.input$.next(value);
  }

  ngAfterViewInit(): void {
    if (this.autofocus && !!this.inputField) {
      const focused: HTMLElement = document.querySelector(':focus');
      focused?.blur();
      setTimeout(() => {
        this.inputField.nativeElement.focus();
      }, 300);
    }
  }
}
