<div class="z-fx" (click)="toggle()">
  <ng-container *ngIf="inputTemplate; else defaultInput" [ngTemplateOutlet]="inputTemplate"></ng-container>

  <ng-template #defaultInput>
    <div class="z-value">
      <span class="z-text-value" [class.zef-text-lighter]="!hasValue" [class.zef-color-muted]="disabled">
        <ng-container
          *ngIf="hasValue && inputTextTemplate; else defaultTextInput"
          [ngTemplateOutlet]="inputTextTemplate"
          [ngTemplateOutletContext]="{ $implicit: value }"
        ></ng-container>

        <ng-template #defaultTextInput>
          {{ hasValue ? displayWith(value) : placeholder || '' }}
        </ng-template>
      </span>

      <mat-icon class="z-arrow-icon" [class.open]="open" [class.zef-color-muted]="disabled">pointer_down</mat-icon>
    </div>
  </ng-template>
</div>

<div *cdkPortal class="z-dropdown-options-wrapper">
  <ng-scrollbar [autoHeightDisabled]="false" *ngIf="options?.length > 0" class="z-wrapper z-scroll-thin">
    <div class="z-dropdown-options">
      <div
        *ngFor="let option of options; trackBy: trackBy"
        class="z-option"
        [class.z-disabled]="!canSelect(option)"
        [class.z-header]="isHeader(option)"
        [class.z-selected]="isSelected(option)"
        (click)="selectOption(option)"
      >
        <ng-container *ngIf="optionTemplate; else optionText">
          <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
        </ng-container>

        <ng-template #optionText>
          <div class="z-text-option">
            <ng-container
              *ngIf="optionTextTemplate; else defaultTextOption"
              [ngTemplateOutlet]="optionTextTemplate"
              [ngTemplateOutletContext]="{ $implicit: option }"
            ></ng-container>

            <ng-template #defaultTextOption>
              {{ displayWith(option) }}
            </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-scrollbar>
</div>
