<!--
  Icon item with support for emblem icon.

  @unstable
-->

<mat-icon class="icon" role="img" [ngClass]="{ checked: !toggle || checked, rounded: rounded }">
  {{ icon }}

  <mat-icon *ngIf="checked" class="emblem">{{ toggle ? 'done' : emblem }}</mat-icon>
</mat-icon>
