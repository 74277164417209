import { Component, Input } from '@angular/core';

@Component({
  selector: 'zef-status-flag',
  templateUrl: './status-flag.component.html',
  styleUrls: ['./status-flag.component.scss'],
})
export class StatusFlagComponent {
  @Input()
  flag?: string;
}
