<span
  *ngIf="show$ | async"
  class="zef-text-link-underline"
  [ngClass]="color$ | async"
  [helpTooltip]="tip$ | async"
  matTooltipPosition="above"
  [matTooltipShowDelay]="300"
  [subject]="subject$ | async"
  (click)="openArticle($event)"
>
  <ng-content></ng-content>
</span>
