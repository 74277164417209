import { Injectable } from '@angular/core';

import { loadJavascript } from '@shared/utilities/dom.utilities';

const analytics = (window.analytics = window.analytics || ([] as any));

@Injectable({
  providedIn: 'root',
})
export class SegmentAnalyticsService {
  private initiated?: boolean;

  initSegment(): void {
    if (this.initiated) {
      return;
    }

    this.initiated = true;

    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
    ];

    analytics.factory = function (m) {
      return function (...a) {
        a.unshift(m);
        analytics.push(a);
        return analytics;
      };
    };

    for (const e of analytics.methods) {
      analytics[e] = analytics.factory(e);
    }

    analytics.load = function (t) {
      return loadJavascript(`https://cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`);
    };
    analytics.SNIPPET_VERSION = '4.0.0';
  }
}
