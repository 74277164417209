import { takeUntil, startWith, delay, switchMap, mapTo, filter, debounce } from 'rxjs/operators';
import { interval, fromEvent, merge, animationFrameScheduler, Subject, timer, race, Observable, defer } from 'rxjs';

import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Injector,
  ChangeDetectorRef,
  AfterViewInit,
  Inject,
  LOCALE_ID,
} from '@angular/core';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'zef-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [LifecycleHooks, { provide: NG_VALUE_ACCESSOR, useExisting: InputNumber, multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputNumber implements ControlValueAccessor, AfterViewInit {
  @ViewChild(MatInput, { static: true })
  input!: MatInput;

  @ViewChild(MatInput, { static: true, read: ElementRef })
  inputEl!: ElementRef<HTMLInputElement>;

  @Input()
  value?: number;

  @Input()
  min?: number;

  @Input()
  max?: number;

  @Input()
  noZero?: boolean;

  @Input()
  fallback: number = 0;

  @Input()
  append?: string;

  @Input()
  placeholder: string = '';

  @Input()
  space?: boolean;

  @Input()
  debounceTime: number = 0;

  @Input()
  disabled?: boolean;

  @Input()
  step: number = 1;

  private readonly valueChange$ = new EventEmitter<number>();

  onChange?: Function;

  onTouched?: Function;

  @Output()
  readonly valueChange = this.valueChange$.pipe(
    debounce(() => timer(this.debounceTime)),
    filter(() => !this.disabled),
  );

  readonly blur: Observable<UIEvent> = defer(() =>
    fromEvent(this.inputEl.nativeElement, 'blur').pipe(
      switchMap((event: UIEvent) =>
        race(
          fromEvent(this.inputEl.nativeElement, 'focus').pipe(mapTo(false)),
          timer(1).pipe(delay(1), mapTo(true)),
        ).pipe(
          filter((passed) => passed),
          mapTo(event),
        ),
      ),
    ),
  );

  readonly arrowKeyRelease$ = new Subject<void>();

  private arrowKeyDown?: 'increment' | 'decrement';

  constructor(
    private lh: LifecycleHooks,
    private inj: Injector,
    private cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  ngAfterViewInit(): void {
    this.cd.markForCheck();
  }

  validateNumericValue(value: string) {
    const regex = /[^0-9.,-]/g;
    if (regex.test(value)) {
      this.input.value = value.replace(regex, '');
    }
  }

  writeValue(value: string): void {
    this.updateInputValue(this.inputValueToNumber(value));
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  onArrowKeyDown(direction: 'increment' | 'decrement', event: KeyboardEvent): void {
    event.preventDefault();

    if (this.arrowKeyDown !== direction) {
      this.arrowKeyRelease$.next();
      this.arrowKeyDown = direction;

      if (direction === 'increment') {
        this.startIncrement();
      } else {
        this.startDecrement();
      }
    }
  }

  onArrowKeyUp(direction: 'increment' | 'decrement'): void {
    if (this.arrowKeyDown === direction) {
      this.arrowKeyRelease$.next();
      this.arrowKeyDown = undefined;
    }
  }

  onBlur(): void {
    if (this.input.value === '') {
      this.setValue(this.fallback || this.min || 0);
    } else {
      this.setValue(this.inputValueToNumber(this.input.value));
    }
  }

  startIncrement(): void {
    if (!this.disabled) {
      this.startChange(this.step || 1);
    }
  }

  startDecrement(): void {
    if (!this.disabled) {
      this.startChange(-(this.step || 1));
    }
  }

  parseValue(value: number | string): string {
    const nr = parseFloat(value?.toString() || '');

    if (value == null || isNaN(nr)) {
      return '';
    }

    const parsed = formatNumber(nr, this.locale);

    if (this.append && !value.toString().endsWith(this.append)) {
      return parsed + this.append;
    }

    return parsed;
  }

  focus(): void {
    this.input.focus();
  }

  private inputValueToNumber(value: string): number {
    value = value?.toString().trim() || '';

    const thousandSeparator = formatNumber(1111, this.locale).replace(/\p{Number}/gu, '');
    const decimalSeparator = formatNumber(1.1, this.locale).replace(/\p{Number}/gu, '');

    return parseFloat(
      value.replace(new RegExp('\\' + thousandSeparator, 'g'), '').replace(new RegExp('\\' + decimalSeparator), '.'),
    );
  }

  private startChange(change: number): void {
    interval(50, animationFrameScheduler)
      .pipe(
        delay(250),
        takeUntil(
          merge(
            fromEvent(document, 'pointercancel'),
            fromEvent(document, 'pointerup'),
            this.arrowKeyRelease$,
            this.lh.destroy,
          ),
        ),
        startWith(0),
      )
      .subscribe({
        next: () => this.updateInputValue(Math.round(((this.value || 0) + change) * 100) / 100),
        complete: () => {
          if (this.onChange) {
            this.onChange(this.value);
          }

          if (this.onTouched) {
            this.onTouched(this.value);
          }

          this.valueChange$.emit(this.value);
        },
      });
  }

  private updateInputValue(value?: number): void {
    if (value == null) {
      return;
    }

    value = parseFloat(value.toString());

    if (Number.isNaN(value)) {
      value = this.value || this.fallback;
    }

    if (this.noZero && value === 0) {
      value = this.fallback || 1;
    }

    if (this.min != null) {
      value = Math.max(this.min, value);
    }

    if (this.max != null) {
      value = Math.min(this.max, value);
    }

    if (value !== this.inputValueToNumber(this.input.value)) {
      this.input.value = formatNumber(value, this.locale) + (this.append || '');
    }

    this.value = value;
  }

  private setValue(value?: number): void {
    if (value !== this.value) {
      this.updateInputValue(value);

      if (this.onChange) {
        this.onChange(this.value);
      }

      if (this.onTouched) {
        this.onTouched(this.value);
      }

      this.valueChange$.emit(this.value);
    }
  }
}
