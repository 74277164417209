import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import {
  HELP_CENTER_STATE,
  HelpCenterGuide,
  HelpCenterItem,
  HelpCenterItemData,
  HelpCenterItems,
  HelpCenterItemStatistics,
  HelpCenterItemStatus,
  HelpCenterLanguage,
  HelpCenterMode,
  HelpCenterStateModel,
  HelpItemSubject,
} from '@shared/modules/help-center/help-center.models';
import { HelpCenterService } from '@shared/modules/help-center/help-center.service';
import {
  GetGuide,
  GetHelpItemData,
  GetHelpItemStatistics,
  GetHelpItemStatus,
  GetHelpItemTip,
  GetHelpItemTitle,
  HideArticle,
  HideGuide,
  IncrementHelpItemStats,
  RevertHelpItemData,
  SelectHelpCenterTeam,
  ShowArticle,
  ShowGuide,
  UpdateHelpCenterMode,
  UpdateHelpItemData,
} from '@shared/modules/help-center/state/help-center.actions';
import { shareRef } from '@shared/operators/share-ref.operator';
import { AuthState } from '@shared/states/auth.state';
import { PrefsState } from '@shared/states/prefs.state';
import { EMPTY, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

type StateCtx = StateContext<HelpCenterStateModel>;

@State({
  name: HELP_CENTER_STATE,
  defaults: {
    items: {},
    articles: [],
    guides: [],
  },
})
@Injectable()
export class HelpCenterState {
  @Selector([PrefsState.language])
  static language({ language }: HelpCenterStateModel, uiLanguage): string {
    return language || uiLanguage;
  }

  @Selector()
  static edit({ mode }: HelpCenterStateModel): boolean {
    return mode === HelpCenterMode.Edit;
  }

  @Selector()
  static preview({ mode }: HelpCenterStateModel): boolean {
    return mode === HelpCenterMode.Preview;
  }

  @Selector()
  static read({ mode }: HelpCenterStateModel): boolean {
    return mode === HelpCenterMode.Read;
  }

  @Selector()
  static items({ items }: HelpCenterStateModel): HelpCenterItems {
    return items;
  }

  @Selector([PrefsState.language])
  static articles({ articles, items }: HelpCenterStateModel, language: string): HelpCenterItem[] {
    const articlesData = articles
      .map((subject) => {
        const data = items[subject]?.data?.[language];
        const clicks = items[subject]?.stats?.[language]?.clicks || 0;

        return { ...data, subject, clicks };
      })
      .filter((item) => !!item?.title);

    return articlesData;
  }

  @Selector([PrefsState.language])
  static guides({ guides, items }: HelpCenterStateModel): HelpCenterItem[] {
    return guides.map((subject) => items?.[subject]);
  }

  @Selector()
  static selectedTeam({ selectedTeam }: HelpCenterStateModel): string | undefined {
    return selectedTeam;
  }

  static item(subject: HelpItemSubject) {
    return createSelector(
      [HelpCenterState.items],
      (items: HelpCenterItems): HelpCenterItem | undefined => items[subject],
    );
  }

  static status(subject: HelpItemSubject) {
    return createSelector(
      [HelpCenterState.item(subject)],
      (item?: HelpCenterItem): HelpCenterItemStatus | undefined => item?.status,
    );
  }

  static tip(subject: HelpItemSubject, lang: HelpCenterLanguage) {
    return createSelector(
      [HelpCenterState.item(subject)],
      (item?: HelpCenterItem): string | undefined => item?.data?.[lang]?.quickTip,
    );
  }

  static data(subject: HelpItemSubject, lang: HelpCenterLanguage) {
    return createSelector(
      [HelpCenterState.item(subject)],
      (item: HelpCenterItem | undefined): HelpCenterItemData | undefined => item?.data?.[lang],
    );
  }

  static stats(subject: HelpItemSubject) {
    return createSelector(
      [HelpCenterState.item(subject)],
      (item: HelpCenterItem | undefined): Record<string, HelpCenterItemStatistics> | undefined => item?.stats,
    );
  }

  constructor(
    private hcs: HelpCenterService,
    private store: Store,
  ) {}

  @StreamAction(GetHelpItemStatus)
  getStatus(ctx: StateCtx, { subject }: GetHelpItemStatus): Observable<unknown> {
    return this.hcs.getItemStatus(subject).pipe(
      tap((status) => this.updateItem(ctx, subject, { status })),
      shareRef(),
    );
  }

  @StreamAction(GetHelpItemTip)
  getTip(ctx: StateCtx, { subject, lang }: GetHelpItemTip): Observable<unknown> {
    return this.hcs.getItemTip(subject, lang).pipe(
      tap((quickTip) => this.updateItemData(ctx, subject, lang, { quickTip })),
      shareRef(),
    );
  }

  @StreamAction(GetHelpItemTitle)
  getTitle(ctx: StateCtx, { subject, lang }: GetHelpItemTitle): Observable<unknown> {
    return this.hcs.getItemTitle(subject, lang).pipe(
      tap((title) => this.updateItemData(ctx, subject, lang, { title })),
      shareRef(),
    );
  }

  @StreamAction(GetHelpItemData)
  getData(ctx: StateCtx, { subject, lang }: GetHelpItemData): Observable<unknown> {
    return this.hcs.getItemData(subject, lang).pipe(tap((data) => this.updateItemData(ctx, subject, lang, data)));
  }

  @StreamAction(GetHelpItemStatistics)
  getStatistics(ctx: StateCtx, { subject }: GetHelpItemStatistics): Observable<unknown> {
    return this.hcs.getItemStatistics(subject).pipe(
      map((stats) => {
        this.updateItem(ctx, subject, { stats });
      }),
    );
  }

  @StreamAction(GetGuide)
  getGuide({ patchState, getState }: StateCtx, { subject }: GetGuide): Observable<unknown> {
    return this.hcs.getGuide(subject).pipe(
      tap((data: HelpCenterGuide) => {
        const items = { ...getState().items, [subject]: { ...data, subject } };

        patchState({ items });
      }),
    );
  }

  @Action(UpdateHelpCenterMode)
  updateMode({ patchState }: StateCtx, { mode }: UpdateHelpCenterMode): void {
    patchState(mode);
  }

  @Action(UpdateHelpItemData)
  updateData(_: StateCtx, { subject, data }: UpdateHelpItemData): Observable<unknown> {
    return this.hcs.updateItemData(subject, data);
  }

  @Action(RevertHelpItemData)
  revertData(_, { subject }: RevertHelpItemData): Observable<unknown> {
    return this.hcs.removeParentData(subject);
  }

  @Action(IncrementHelpItemStats)
  updateStats(_: StateCtx, { subject, property, lang }: IncrementHelpItemStats): Observable<unknown> {
    if (environment.production && this.store.selectSnapshot(AuthState.isZefAdmin)) {
      return EMPTY;
    }

    return this.hcs.updateItemStats(subject, property, lang);
  }

  @Action(SelectHelpCenterTeam)
  selectTeam({ patchState }: StateCtx, { team }: SelectHelpCenterTeam): void {
    patchState({ selectedTeam: team });
  }

  @Action(ShowArticle)
  showArticle({ patchState, getState }: StateCtx, { subject }: ShowArticle): void {
    const articles = getState().articles.concat(subject);

    patchState({ articles });
  }

  @Action(HideArticle)
  hideArticle({ patchState, getState }: StateCtx, { subject }: HideArticle): void {
    const articles = [];
    let hidden = false;

    for (const article of getState().articles) {
      if (hidden || article !== subject) {
        articles.push(article);
      } else {
        hidden = true;
      }
    }

    patchState({ articles });
  }

  @Action(ShowGuide)
  ShowGuide({ patchState, getState }: StateCtx, { subject }: ShowGuide): void {
    const guides = getState()
      .guides.filter((guide) => guide !== subject)
      .concat(subject);

    patchState({ guides });
  }

  @Action(HideGuide)
  HideGuide({ patchState, getState }: StateCtx, { subject }: HideGuide): void {
    const guides = getState().guides.filter((guide) => guide !== subject);

    patchState({ guides });
  }

  private updateItemData(
    { setState, getState }: StateCtx,
    subject: HelpItemSubject,
    lang: HelpCenterLanguage,
    update: Partial<HelpCenterItemData>,
  ): void {
    const subjectState = getState().items[subject];
    const langUpdate = subjectState?.data?.[lang] ? patch(update) : update;
    const dataUpdate = subjectState?.data ? patch({ [lang]: langUpdate }) : { [lang]: langUpdate };
    const itemUpdate = subjectState ? patch({ data: dataUpdate }) : ({ data: dataUpdate } as HelpCenterItem);

    setState(patch({ items: patch({ [subject]: itemUpdate }) }));
  }

  private updateItem(
    { setState, getState }: StateCtx,
    subject: HelpItemSubject,
    update: Partial<HelpCenterItem>,
  ): void {
    const itemUpdate = getState().items[subject] ? patch(update) : (update as HelpCenterItem);

    setState(patch({ items: patch({ [subject]: itemUpdate }) }));
  }
}
