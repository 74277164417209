/**
 * Linda elements module.
 *
 * @unstable
 */

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { DropzoneModule } from 'ngx-dropzone-wrapper';

import { BasicModule } from '@shared/modules/basic.module';

import { Menu } from '@shared/components/menu/menu.component';
import { Panel } from '@shared/components/panel/panel.component';
import { Avatar } from '@shared/components/avatar/avatar.component';
import { Banner } from '@shared/components/banner/banner.component';
import { Dialog } from '@shared/components/dialog/dialog.component';
import { Emblem } from '@shared/components/emblem/emblem.component';
import { Infobox } from '@shared/components/infobox/infobox.component';
import { Snackbar } from '@shared/components/snackbar/snackbar.component';
import { DropdownDeprecated } from '@shared/components/dropdown/dropdown-deprecated.component';
import { Popup } from '@shared/components/popup/popup.component';
import { MessageMark } from '@shared/components/message-mark/message-mark.component';
import { StatusIcon } from '@shared/components/status-icon/status-icon.component';
import { SuccessBox } from '@shared/components/success-box/success-box.component';
import { SearchExpand } from '@shared/components/search-expand/search-expand.component';

import { PanelTrigger } from '@shared/components/panel/panel.directive';

import { SinceWhen } from '@shared/pipes/since-when.pipe';

import { AccordionModule } from '@shared/components/accordion/accordion.component';
import { RouterModule } from '@angular/router';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RandomBackground } from '@shared/components/random-bg/random-bg.component';
import { MessageStatus } from '@home/shared/pipes/message-status.pipe';
import { EditContact } from '@home/+contacts/edit-contact/edit-contact.component';
import { Searchbox } from '@shared/components/searchbox/searchbox.component';
import { ZeffiIcon } from '@shared/components/zeffi-icon/zeffi-icon.component';

import { SelectModule } from '@shared/components/select/select.module';
import { ToolbarModule } from '@shared/components/toolbar/toolbar.module';
import { InlineDialogModule } from '@shared/components/inline-dialog/inline-dialog.component';
import { HintDialogModule } from '@shared/components/hint-dialog/hint-dialog.component';
import { KeyToNamePipe } from '@shared/pipes/key-to-name.pipe';
import { KeyToSourcePipe } from '@shared/pipes/key-to-source.pipe';

import { GlobalSpinner } from '@shared/components/global-spinner/global-spinner.component';
import { SidenavModule } from '@shared/modules/sidenav/sidenav.module';
import { SourceLogo } from '@shared/components/source-logo/source-logo.component';
import { StatusFlagModule } from '@shared/modules/status-flag/status-flag.module';
import { CodeBlock } from '@shared/components/code-block/code-block.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ImageUploadModule } from '@shared/modules/image-upload/image-upload.module';
import { SpinnerModule } from '@shared/components/spinner/spinner.module';
import { InlineEdit } from '@shared/components/inline-edit/inline-edit.component';
import { StepSliderModule } from '@shared/components/step-slider/step-slider.component';
import { InputNumber } from '@shared/components/input-number/input-number.component';
import { ChipModule } from '@shared/components/chip/chip.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ScheduleDatePipe } from '@shared/pipes/schedule-date.pipe';
import { CopyToClipboardModule } from '@shared/modules/copy-to-clipboard/copy-to-clipboard.module';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';
import { InputSearch } from '@shared/components/input-search/input-search.component';
import { InputTooltip } from '@shared/directives/input-tooltip.directive';
import { InputTime } from '@shared/components/input-time/input-time.component';
import { PromoDialogModule } from '@shared/components/promo-dialog/promo-dialog.module';
import { UserRole } from '@shared/components/user-role/user-role.component';
import { TeamTile } from '@shared/components/team-tile/team-tile.component';
import { ZefTooltipComponent, ZefTooltipDirective } from '@shared/components/tooltip/tooltip.component';
import { BetaButton } from '@shared/components/beta-button/beta-button.component';

@NgModule({
  bootstrap: [],
  declarations: [
    Menu,
    Panel,
    Avatar,
    Banner,
    Dialog,
    Emblem,
    Infobox,
    Snackbar,
    SourceLogo,
    DropdownDeprecated,
    StatusIcon,
    SuccessBox,
    Popup,
    CodeBlock,
    Searchbox,
    SearchExpand,
    MessageMark,
    RandomBackground,
    InputTime,
    InputNumber,
    InputSearch,
    InlineEdit,
    PanelTrigger,
    SinceWhen,
    ScheduleDatePipe,
    KeyToNamePipe,
    KeyToSourcePipe,
    MessageStatus,
    InputTooltip,
    TeamTile,
    EditContact,
    ZeffiIcon,
    GlobalSpinner,
    UserRole,
    ZefTooltipComponent,
    ZefTooltipDirective,
    BetaButton,
  ],
  exports: [
    ChipModule,
    Menu,
    Panel,
    Avatar,
    Banner,
    Dialog,
    Emblem,
    Infobox,
    Snackbar,
    SourceLogo,
    DropdownDeprecated,
    StatusIcon,
    SuccessBox,
    Popup,
    CodeBlock,
    Searchbox,
    SearchExpand,
    MessageMark,
    RandomBackground,
    InputTime,
    InputNumber,
    InputSearch,
    InlineEdit,
    PanelTrigger,
    SinceWhen,
    ScheduleDatePipe,
    KeyToNamePipe,
    KeyToSourcePipe,
    MessageStatus,
    InputTooltip,
    TeamTile,
    AccordionModule,
    EditContact,
    ZeffiIcon,
    GlobalSpinner,
    UserRole,
    ZefTooltipDirective,
    BetaButton,
    // modules
    NgScrollbarModule,
    NgScrollbarReachedModule,
    SelectModule,
    ToolbarModule,
    SidenavModule,
    SpinnerModule,
    StatusFlagModule,
    StepSliderModule,
    ImageUploadModule,
    InlineDialogModule,
    HintDialogModule,
    PromoDialogModule,
    CopyToClipboardModule,
    NgScrollbarModule,
    ScrollingModule,
    ExperimentalScrollingModule,
  ],
  imports: [
    BasicModule,
    RouterModule,
    FormsModule,
    CommonModule,
    PortalModule,
    OverlayModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    MatDividerModule,
    MatToolbarModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    NgScrollbarModule,
    NgScrollbarReachedModule,
    DropzoneModule,
    SelectModule,
    SidenavModule,
    SpinnerModule,
    InlineDialogModule,
    HintDialogModule,
    PromoDialogModule,
    CopyToClipboardModule,
  ],
})
export class LindaModule {}
