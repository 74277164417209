import { CONSOLE_ZEFFI_TEAM } from '@admin/shared/states/console-state.models';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Commands } from '@shared/enums/commands.enum';
import {
  HELP_CENTER_STATE,
  HelpCenterItemData,
  HelpCenterItemStatistics,
  HelpCenterItemStatus,
  HelpCenterLanguage,
  HelpGuideStep,
  HelpItemSubject,
} from '@shared/modules/help-center/help-center.models';
import { shareRef } from '@shared/operators/share-ref.operator';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { AccountState } from '@shared/states/account.state';
import { CloseLastSidenav, OpenPublishSidenav } from '@shared/states/dialog.actions';
import { isObject } from '@shared/utilities/object.utilities';
import { ShepherdService } from 'angular-shepherd';
import { combineLatest, concat, EMPTY, lastValueFrom, Observable, of, switchMap } from 'rxjs';
import { delay, distinctUntilChanged, map, take } from 'rxjs/operators';
import { SidenavId } from '@shared/modules/sidenav/models/sidenav.models';
import { HelpGuide } from './help-subject.enum';

@Injectable({
  providedIn: 'root',
})
export class HelpCenterService {
  readonly pathPrefix$ = combineLatest([
    this.store.select(AccountState.isChild),
    this.store.select(AccountState.adminSettings),
    this.store.select(AccountState.isParent),
    this.store.select(AccountState.teamKey),
    this.store.select(HELP_CENTER_STATE).pipe(map(({ selectedTeam }) => selectedTeam)),
  ]).pipe(
    map(([isChild, adminSettings, isParent, teamKey, selectedTeam]) => {
      const teamKeyPrefix =
        selectedTeam === CONSOLE_ZEFFI_TEAM
          ? ''
          : selectedTeam || (isChild ? adminSettings.childOf : isParent ? teamKey : '');

      return teamKeyPrefix ? `/admin/parent/${teamKeyPrefix}` : '';
    }),
    distinctUntilChanged(),
  );

  constructor(
    private db: DatabaseWrapper,
    private cf: CloudFunctions,
    private store: Store,
    private sh: ShepherdService,
  ) {}

  getItemStatus(subject: HelpItemSubject): Observable<HelpCenterItemStatus> {
    return this.get<HelpCenterItemStatus>(`${subject}/status`, HelpCenterItemStatus.Missing, true);
  }

  getItemTip(subject: HelpItemSubject, lang: HelpCenterLanguage): Observable<string> {
    return this.get<string>(`${subject}/data/${lang}/quickTip`, '');
  }

  getItemTitle(subject: HelpItemSubject, lang: HelpCenterLanguage): Observable<string> {
    return this.get<string>(`${subject}/data/${lang}/title`, '');
  }

  getItemData(subject: HelpItemSubject, lang: HelpCenterLanguage): Observable<HelpCenterItemData> {
    return this.get<HelpCenterItemData>(`${subject}/data/${lang}`, {}).pipe(
      map((data) => ({
        title: data.title || '',
        article: data.article || '',
        quickTip: data.quickTip || '',
        externalLink: data.externalLink || '',
        hasParentData: data.hasParentData || false,
      })),
    );
  }

  getItemStatistics(subject: HelpItemSubject): Observable<Record<HelpCenterLanguage, HelpCenterItemStatistics>> {
    return this.get<Record<HelpCenterLanguage, HelpCenterItemStatistics>>(
      `${subject}/stats`,
      {} as Record<HelpCenterLanguage, HelpCenterItemStatistics>,
    );
  }

  updateItemData(
    subject: HelpItemSubject,
    data: Partial<Record<HelpCenterLanguage, Partial<HelpCenterItemData>>>,
  ): Observable<unknown> {
    const dataEntries = Object.entries(data || {});

    if (!dataEntries.length) {
      return EMPTY;
    }

    return concat(
      ...dataEntries.map(([lang, update]) =>
        this.pathPrefix$.pipe(
          take(1),
          switchMap((pathPrefix) =>
            this.db.object<HelpCenterItemData>(`${pathPrefix}/helpcenter/${subject}/data/${lang}`).update(update),
          ),
        ),
      ),
    );
  }

  getGuide(subject: HelpGuide) {
    return this.db.object(`/helpcenter/${subject}`).valueChanges().pipe(shareRef());
  }

  saveGuide(subject: HelpGuide, data: any) {
    return this.db.object(`/helpcenter/${subject}`).update(data);
  }

  updateItemStats(
    subject: HelpItemSubject,
    property: keyof HelpCenterItemStatistics,
    language: HelpCenterLanguage,
  ): Observable<void> {
    return this.cf.post(Commands.HelpCenterIncrement, undefined, { subject, property, language });
  }

  removeParentData(subject: HelpItemSubject): Observable<unknown> {
    return this.pathPrefix$.pipe(
      take(1),
      switchMap((pathPrefix) => this.db.object(`${pathPrefix}/helpcenter/${subject}`).remove()),
    );
  }

  private get<T>(path: string, fallback: T, getMax?: boolean): Observable<T> {
    return combineLatest([
      this.pathPrefix$.pipe<T>(
        switchMap((pathPrefix) =>
          pathPrefix ? this.db.object<T>(`${pathPrefix}/helpcenter/${path}`).valueChanges() : of(null),
        ),
      ),
      this.db.object<T>(`/helpcenter/${path}`).valueChanges(),
    ]).pipe(
      map(([prefixValue, value]) => {
        if (isObject(prefixValue)) {
          return {
            ...value,
            ...prefixValue,
            hasParentData: true,
          };
        }

        if (getMax) {
          return Math.max((prefixValue || fallback) as number, (value || fallback) as number) as T;
        } else {
          return prefixValue ?? value ?? fallback;
        }
      }),
      distinctUntilChanged(),
      shareRef(),
    );
  }

  stepWithLanguage(step, language) {
    return {
      ...step,
      title: step.title?.[language] || '&nbsp;',
      text: [step.text?.[language]],
      buttons: step.buttons?.map((button) => ({ ...button, text: button.text?.[language] })),
    };
  }

  stopGuide() {
    if (this.sh.tourObject?.isActive()) {
      this.sh.cancel();
    }
  }

  startGuide(subject: HelpGuide, steps: HelpGuideStep[], onClosed?: () => void) {
    this.stopGuide();

    this.sh.modal = true;
    this.sh.defaultStepOptions = {
      classes: 'tour-container zef-card-z4',
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      modalOverlayOpeningRadius: 8,
    };

    this.sh.requiredElements = [];

    if (subject === HelpGuide.SurveyPublish) {
      const closed = onClosed;

      onClosed = () => {
        closed?.();
        this.store.dispatch(new CloseLastSidenav(SidenavId.PublishSurvey));
      };

      steps = steps.map((s) => {
        const id = s?.id;

        if (id === 'sidenav') {
          return {
            ...s,
            when: {
              hide: () => this.store.dispatch(new CloseLastSidenav(SidenavId.PublishSurvey)),
            },
            beforeShowPromise: () => lastValueFrom(this.store.dispatch(new OpenPublishSidenav()).pipe(delay(10))),
          };
        } else {
          return s;
        }
      });
    }

    if (steps.some((s) => !s.attachTo?.element)) {
      steps = steps.map((s) => ({ ...s, attachTo: { ...s.attachTo, element: s.attachTo?.element || 'body' } }));
    }

    this.sh.addSteps(steps as any);
    this.sh.start();
    if (onClosed) {
      this.sh.tourObject.on('complete', onClosed);
      this.sh.tourObject.on('cancel', onClosed);
    }
  }
}
