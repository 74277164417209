import { Observable, of, forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { searchQueryMatch } from '@shared/utilities/string.utilities';
import { SourceType } from '@shared/models/utility.model';
import { SourceTypeService } from '@shared/services/source-type.service';
import { ColumnType } from '@shared/components/table/models/table.models';

export interface ItemProperty<T> {
  property: keyof T;
  type?: SourceType | ((source: T) => SourceType | null);
}

export type SearchProperty<T> = ItemProperty<T>;

export interface SortProperty<T> extends ItemProperty<T> {
  direction: 'asc' | 'desc';
  valueType: ColumnType;
}

@Injectable({
  providedIn: 'root',
})
export class ItemSearchHelper {
  readonly keyMap: Partial<Record<SourceType, string>> = {
    user: 'name',
    team: 'name',
    survey: 'name',
    api: 'name',
    integration: 'name',
  };

  constructor(private st: SourceTypeService) {}

  filterItems<T>(items: T[], search: string, properties: SearchProperty<T>[]): Observable<T[]> {
    search = search?.trim() || '';

    if (!search || !items.length) {
      return of(items);
    }

    return forkJoin(items.map((item) => forkJoin(properties.map((prop) => this.getItemByProperty(item, prop))))).pipe(
      map((targets: string[][]) =>
        items.filter((item, i) =>
          targets[i]?.filter((target) => !!target).some((target) => searchQueryMatch(target, search)),
        ),
      ),
    );
  }

  sortItems<T>(items: T[], sort: SortProperty<T>): Observable<T[]> {
    if (!items.length) {
      return of([]);
    }

    return forkJoin(items.map((item) => this.getItemByProperty(item, sort))).pipe(
      map((sortValues: string[]) =>
        sortValues
          .map((value, i) => ({ i, value }))
          .sort((a, b) => {
            const fromVal = sort.direction === 'asc' ? a.value : b.value;
            const withVal = sort.direction === 'asc' ? b.value : a.value;

            if (sort.valueType === 'number') {
              return (Number.parseFloat(fromVal) || 0) - (Number.parseFloat(withVal) || 0);
            } else {
              return fromVal.localeCompare(withVal, 'en', { sensitivity: 'base' });
            }
          })
          .map(({ i }) => items[i]),
      ),
    );
  }

  getItemName(type: SourceType, key?: string): Observable<string> {
    const property = this.keyMap[type];

    if (!property) {
      return of(key);
    }

    return this.st.getItem(type, key).pipe(
      map((obj) => obj?.[property]),
      map((name) => name ?? `Unknown ${type}`),
    );
  }

  private getItemByProperty<T>(item: T, prop: ItemProperty<T>): Observable<string> {
    const key = item[prop.property]?.toString();

    if (!prop.type) {
      return of(key);
    }

    const type = typeof prop.type === 'string' ? prop.type : prop.type(item);

    return this.getItemName(type, key).pipe(take(1));
  }
}
