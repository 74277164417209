import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'zef-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class Menu {
  @Output() close = new EventEmitter();
}
