import { AsyncPipe } from '@angular/common';
import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { SourceType } from '@shared/models/utility.model';
import { SourceTypeService } from '@shared/services/source-type.service';

@Pipe({
  name: 'keyToSource',
  pure: false,
})
export class KeyToSourcePipe<T = any> implements PipeTransform, OnDestroy {
  private readonly ap = new AsyncPipe(this.cd);

  constructor(
    private cd: ChangeDetectorRef,
    private st: SourceTypeService,
  ) {}

  transform(key: string, type: SourceType): T {
    return this.ap.transform(this.st.getItem<T>(type, key));
  }

  ngOnDestroy(): void {
    this.ap.ngOnDestroy();
  }
}
