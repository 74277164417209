import {
  Directive,
  ElementRef,
  OnDestroy,
  ViewContainerRef,
  ComponentRef,
  Input,
  OnInit,
  ComponentFactoryResolver,
} from '@angular/core';

import { StatusFlagComponent } from '@shared/modules/status-flag/status-flag.component';

@Directive({
  selector: '[statusFlag]',
})
export class StatusFlagDirective implements OnInit, OnDestroy {
  private compRef?: ComponentRef<StatusFlagComponent> = this.vc.createComponent(
    this.cf.resolveComponentFactory(StatusFlagComponent),
  );

  @Input()
  statusFlag?: string;

  constructor(
    private el: ElementRef<HTMLElement>,
    private vc: ViewContainerRef,
    private cf: ComponentFactoryResolver,
  ) {}

  ngOnInit(): void {
    const pos = this.el.nativeElement.style.position;
    this.el.nativeElement.style.position = !pos || pos === 'static' ? 'relative' : pos;
    this.el.nativeElement.appendChild(this.compRef.location.nativeElement);
    this.compRef.instance.flag = this.statusFlag;
  }

  ngOnDestroy(): void {
    this.compRef.destroy();
  }
}
