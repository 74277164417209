<ng-container
  *ngIf="{
    value: value$ | async,
    isCombo:
      type === Types.Combo || (!isInline && (newSelected || ss.optionLabelPrefixes.size || ss.selectLabelPrefix)),
    labels: (ss.optionLabels$ | async),
    isMultiSelect: ss.isMultiSelect$ | async,
  } as data"
>
  <div
    class="select z-fx-inline"
    [ngClass]="'select-' + type + ' select-' + size"
    [class.zef-text-p2]="!isInline || type === Types.InlineP2"
    [class.zef-text-h3]="type === Types.InlineH3"
    [class.select-combo]="data.isCombo"
    [class.space]="space"
    [class.disabled]="disabled"
    [class.read-only]="readOnly"
    [class.select-small]="valuePrefixOnly"
    [tabindex]="disabled ? -1 : 0"
    (click)="openSelect()"
  >
    <ng-container *ngIf="!data.isMultiSelect; else multiSelectTemplate">
      <select-item
        *ngIf="!newSelected && data.value != null; else newPlaceholder"
        [class.zef-text-p2]="!isInline || type === Types.InlineP2"
        [class.zef-text-h3]="type === Types.InlineH3"
        [label]="valuePrefixOnly ? undefined : ss.selectLabel || data.labels?.get(data.value)"
        [valuePrefixOnly]="valuePrefixOnly"
        [prefix]="
          isInline ? null : loading ? loadingIcon : ss.optionLabelPrefixes.get(data.value) || ss.selectLabelPrefix
        "
        [value]="data.value"
        [isMultiSelect]="data.isMultiSelect"
        (selectItemOverflow)="selectOverflow.emit($event)"
      ></select-item>
    </ng-container>
    <ng-template #multiSelectTemplate>
      <ng-container *ngIf="!ss?.selectLabel; else selectLabelTemplate">
        <ng-container *ngIf="!ss?.selectLabel">
          <ng-container *ngIf="data.value?.length > 1">
            <span>{{ data.value?.length + ' ' + selectedText }}</span>
          </ng-container>
          <ng-container *ngIf="data.value?.length === 1">
            <ng-container *ngIf="data.labels?.get(data.value?.[0]); else valueAsLabel">
              <ng-container [ngTemplateOutlet]="data.labels?.get(data.value?.[0])"></ng-container>
            </ng-container>
            <ng-template #valueAsLabel>
              <span>{{ data.value?.[0] }}</span>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="newSelected && data.value == null; else valuePlacerHolder">
            <span>{{ newValue }}</span>
          </ng-container>
          <ng-template #valuePlacerHolder>
            <span *ngIf="!data.value?.length" class="zef-color-ink-lightest">{{ placeholder }}</span>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-template #selectLabelTemplate>
        <ng-container [ngTemplateOutlet]="ss.selectLabel"></ng-container>
      </ng-template>
    </ng-template>

    <span class="select-suffix z-fx z-fx-center-center">
      <mat-icon *ngIf="!selectSuffix; else selectSuffix?.tr" class="select-pointer z-fx-none" [@rotate]="isOpen">
        pointer_down
      </mat-icon>
    </span>
  </div>

  <ng-template #newPlaceholder>
    <ng-template #newPrefix>
      <mat-icon [color]="newValue || newPrefixIcon ? 'ink' : 'ink-lighter'">
        {{ newValue ? 'plus' : newPrefixIcon || 'question_unknown' }}
      </mat-icon>
    </ng-template>

    <ng-template #newLabel>
      <ng-container *ngIf="!newValue">
        <ng-container *ngIf="placeholder">{{ placeholder }}</ng-container>
        <ng-container *ngIf="!placeholder" i18n>Select</ng-container>
      </ng-container>

      <ng-container *ngIf="newValue">
        {{ newValue }}
      </ng-container>
    </ng-template>

    <select-item
      [class.zef-text-p2]="!isInline || type === Types.InlineP2"
      [class.zef-text-h3]="type === Types.InlineH3"
      [label]="newLabel"
      [prefix]="
        data.isCombo || loading || useNewPrefix
          ? loading
            ? loadingIcon
            : canNew || useNewPrefix
              ? newPrefix
              : noPlaceholderPrefix
                ? null
                : ss.selectLabelPrefix || newPrefix
          : null
      "
      [placeholder]="!newValue"
      [isMultiSelect]="data.isMultiSelect"
    ></select-item>
  </ng-template>
</ng-container>

<div
  *cdkPortal
  class="select-list z-fx-col"
  [class.overlay]="isNewOpen"
  [@dropdown]="dropdownState"
  cdkTrapFocus
  (@dropdown.done)="onDropdownAnimationDone($event)"
>
  <ng-container *ngIf="!isNewOpen">
    <div *ngIf="canSearch || loading" class="select-search select-header z-fx-none">
      <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>

      <mat-form-field *ngIf="canSearch" class="zef-search-input">
        <mat-icon matPrefix>action_find</mat-icon>

        <input
          matInput
          [value]="ss.search$ | async"
          [placeholder]="placeholderSearch"
          (input)="onSearchInput(searchInput.value)"
          #searchInput="matInput"
        />
      </mat-form-field>
    </div>

    <div class="z-fx-flex">
      <div class="zef-scroll-y" [class.multi-select-scroll]="ss.isMultiSelect$ | async">
        <div class="select-options" [class.multi-select-options]="ss.isMultiSelect$ | async">
          <ng-container *ngIf="canClear">
            <zef-select-option [value]="null">
              <mat-icon *selectPrefix>remove</mat-icon>

              <ng-container *selectOptionLabel>
                <ng-container *ngIf="!clearHint" i18n>None</ng-container>

                <ng-container *ngIf="clearHint" i18n>{{ clearHint }}</ng-container>
              </ng-container>
            </zef-select-option>

            <mat-divider></mat-divider>
          </ng-container>

          <ng-container *ngIf="canNew">
            <zef-select-option [value]="newValue" [isNew]="true">
              <mat-icon *selectPrefix>plus</mat-icon>

              <ng-container *selectOptionLabel>
                <ng-container *ngIf="!newHint" i18n>Create new</ng-container>

                <ng-container *ngIf="newHint" i18n>{{ newHint }}</ng-container>
              </ng-container>
            </zef-select-option>

            <mat-divider></mat-divider>
          </ng-container>

          <ng-container
            *ngIf="selectOptions?.template"
            [ngTemplateOutlet]="selectOptions?.template"
            [ngTemplateOutletContext]="{ $implicit: (value$ | async) }"
          ></ng-container>

          <ng-content *ngIf="!selectOptions?.template"></ng-content>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="isNewOpen">
    <div class="select-header z-fx z-fx-start-center z-fx-gap-8 zef-text-p1">
      <button mat-icon-button (click)="closeNewInput()">
        <mat-icon>back</mat-icon>
      </button>

      <span *ngIf="!newHint" i18n>Create new</span>
      <span *ngIf="newHint" i18n>{{ newHint }}</span>
    </div>

    <div class="select-body">
      <mat-form-field>
        <input
          matInput
          [placeholder]="placeholderNew"
          [value]="newValue"
          (keydown.enter)="onNewValue(newInput.value)"
          #newInput="matInput"
        />

        <mat-icon matSuffix [class.empty]="!newInput.value" (click)="onNewValue(newInput.value)">bullet_check</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>

<ng-template #loadingIcon>
  <zef-spinner></zef-spinner>
</ng-template>
