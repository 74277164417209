import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Optional, Inject, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1 from '@angular/material/core';
import { _MatOptgroupBase, MAT_OPTGROUP, _MatOptionBase, MAT_OPTION_PARENT_COMPONENT, MatRippleModule, MatCommonModule, MatPseudoCheckboxModule } from '@angular/material/core';
const _c0 = ["*", [["mat-option"], ["ng-container"]]];
const _c1 = ["*", "mat-option, ng-container"];
const _c2 = ["*"];
function MatLegacyOption_mat_pseudo_checkbox_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "mat-pseudo-checkbox", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("state", ctx_r0.selected ? "checked" : "unchecked")("disabled", ctx_r0.disabled);
  }
}
function MatLegacyOption_span_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("(", ctx_r0.group.label, ")");
  }
}
export { VERSION as LEGACY_VERSION, AnimationCurves as LegacyAnimationCurves, AnimationDurations as LegacyAnimationDurations, DateAdapter as LegacyDateAdapter, ErrorStateMatcher as LegacyErrorStateMatcher, NativeDateAdapter as LegacyNativeDateAdapter, NativeDateModule as LegacyNativeDateModule, RippleRef as LegacyRippleRef, RippleRenderer as LegacyRippleRenderer, ShowOnDirtyErrorStateMatcher as LegacyShowOnDirtyErrorStateMatcher, MATERIAL_SANITY_CHECKS as MATERIAL_LEGACY_SANITY_CHECKS, MAT_DATE_FORMATS as MAT_LEGACY_DATE_FORMATS, MAT_DATE_LOCALE as MAT_LEGACY_DATE_LOCALE, MAT_DATE_LOCALE_FACTORY as MAT_LEGACY_DATE_LOCALE_FACTORY, MAT_NATIVE_DATE_FORMATS as MAT_LEGACY_NATIVE_DATE_FORMATS, MAT_OPTGROUP as MAT_LEGACY_OPTGROUP, MAT_OPTION_PARENT_COMPONENT as MAT_LEGACY_OPTION_PARENT_COMPONENT, MAT_RIPPLE_GLOBAL_OPTIONS as MAT_LEGACY_RIPPLE_GLOBAL_OPTIONS, MatCommonModule as MatLegacyCommonModule, MatLine as MatLegacyLine, MatLineModule as MatLegacyLineModule, MatNativeDateModule as MatLegacyNativeDateModule, MatOptionSelectionChange as MatLegacyOptionSelectionChange, MatPseudoCheckbox as MatLegacyPseudoCheckbox, MatPseudoCheckboxModule as MatLegacyPseudoCheckboxModule, MatRipple as MatLegacyRipple, MatRippleModule as MatLegacyRippleModule, _MatOptgroupBase as _MatLegacyOptgroupBase, _MatOptionBase as _MatLegacyOptionBase, _countGroupLabelsBeforeOption as _countGroupLabelsBeforeLegacyOption, _getOptionScrollPosition as _getLegacyOptionScrollPosition, defaultRippleAnimationConfig as legacyDefaultRippleAnimationConfig, mixinColor as legacyMixinColor, mixinDisableRipple as legacyMixinDisableRipple, mixinDisabled as legacyMixinDisabled, mixinErrorState as legacyMixinErrorState, mixinInitialized as legacyMixinInitialized, mixinTabIndex as legacyMixinTabIndex, setLines as legacySetLines } from '@angular/material/core';

/**
 * Component that is used to group instances of `mat-option`.
 * @deprecated Use `MatOptgroup` from `@angular/material/core` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyOptgroup extends _MatOptgroupBase {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMatLegacyOptgroup_BaseFactory;
      return function MatLegacyOptgroup_Factory(t) {
        return (ɵMatLegacyOptgroup_BaseFactory || (ɵMatLegacyOptgroup_BaseFactory = i0.ɵɵgetInheritedFactory(MatLegacyOptgroup)))(t || MatLegacyOptgroup);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MatLegacyOptgroup,
      selectors: [["mat-optgroup"]],
      hostAttrs: [1, "mat-optgroup"],
      hostVars: 5,
      hostBindings: function MatLegacyOptgroup_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("role", ctx._inert ? null : "group")("aria-disabled", ctx._inert ? null : ctx.disabled.toString())("aria-labelledby", ctx._inert ? null : ctx._labelId);
          i0.ɵɵclassProp("mat-optgroup-disabled", ctx.disabled);
        }
      },
      inputs: {
        disabled: "disabled"
      },
      exportAs: ["matOptgroup"],
      features: [i0.ɵɵProvidersFeature([{
        provide: MAT_OPTGROUP,
        useExisting: MatLegacyOptgroup
      }]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c1,
      decls: 4,
      vars: 2,
      consts: [["role", "presentation", 1, "mat-optgroup-label", 3, "id"]],
      template: function MatLegacyOptgroup_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "span", 0);
          i0.ɵɵtext(1);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(3, 1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("id", ctx._labelId);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1("", ctx.label, " ");
        }
      },
      styles: [".mat-optgroup-label{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;line-height:48px;height:48px;padding:0 16px;text-align:left;text-decoration:none;max-width:100%;-webkit-user-select:none;user-select:none;cursor:default}.mat-optgroup-label[disabled]{cursor:default}[dir=rtl] .mat-optgroup-label{text-align:right}.mat-optgroup-label .mat-icon{margin-right:16px;vertical-align:middle}.mat-optgroup-label .mat-icon svg{vertical-align:top}[dir=rtl] .mat-optgroup-label .mat-icon{margin-left:16px;margin-right:0}"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyOptgroup, [{
    type: Component,
    args: [{
      selector: 'mat-optgroup',
      exportAs: 'matOptgroup',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      inputs: ['disabled'],
      host: {
        'class': 'mat-optgroup',
        '[attr.role]': '_inert ? null : "group"',
        '[attr.aria-disabled]': '_inert ? null : disabled.toString()',
        '[attr.aria-labelledby]': '_inert ? null : _labelId',
        '[class.mat-optgroup-disabled]': 'disabled'
      },
      providers: [{
        provide: MAT_OPTGROUP,
        useExisting: MatLegacyOptgroup
      }],
      template: "<span class=\"mat-optgroup-label\" role=\"presentation\" [id]=\"_labelId\">{{ label }} <ng-content></ng-content></span>\n<ng-content select=\"mat-option, ng-container\"></ng-content>\n",
      styles: [".mat-optgroup-label{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;line-height:48px;height:48px;padding:0 16px;text-align:left;text-decoration:none;max-width:100%;-webkit-user-select:none;user-select:none;cursor:default}.mat-optgroup-label[disabled]{cursor:default}[dir=rtl] .mat-optgroup-label{text-align:right}.mat-optgroup-label .mat-icon{margin-right:16px;vertical-align:middle}.mat-optgroup-label .mat-icon svg{vertical-align:top}[dir=rtl] .mat-optgroup-label .mat-icon{margin-left:16px;margin-right:0}"]
    }]
  }], null, null);
})();

/**
 * Single option inside of a `<mat-select>` element.
 * @deprecated Use `MatOption` from `@angular/material/core` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyOption extends _MatOptionBase {
  constructor(element, changeDetectorRef, parent, group) {
    super(element, changeDetectorRef, parent, group);
  }
  static {
    this.ɵfac = function MatLegacyOption_Factory(t) {
      return new (t || MatLegacyOption)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(MAT_OPTION_PARENT_COMPONENT, 8), i0.ɵɵdirectiveInject(MAT_OPTGROUP, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MatLegacyOption,
      selectors: [["mat-option"]],
      hostAttrs: ["role", "option", 1, "mat-option", "mat-focus-indicator"],
      hostVars: 12,
      hostBindings: function MatLegacyOption_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function MatLegacyOption_click_HostBindingHandler() {
            return ctx._selectViaInteraction();
          })("keydown", function MatLegacyOption_keydown_HostBindingHandler($event) {
            return ctx._handleKeydown($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx.id);
          i0.ɵɵattribute("tabindex", ctx._getTabIndex())("aria-selected", ctx.selected)("aria-disabled", ctx.disabled.toString());
          i0.ɵɵclassProp("mat-selected", ctx.selected)("mat-option-multiple", ctx.multiple)("mat-active", ctx.active)("mat-option-disabled", ctx.disabled);
        }
      },
      exportAs: ["matOption"],
      features: [i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c2,
      decls: 6,
      vars: 4,
      consts: [["text", ""], ["class", "mat-option-pseudo-checkbox", 3, "state", "disabled", 4, "ngIf"], [1, "mat-option-text"], ["class", "cdk-visually-hidden", 4, "ngIf"], ["mat-ripple", "", 1, "mat-option-ripple", 3, "matRippleTrigger", "matRippleDisabled"], [1, "mat-option-pseudo-checkbox", 3, "state", "disabled"], [1, "cdk-visually-hidden"]],
      template: function MatLegacyOption_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, MatLegacyOption_mat_pseudo_checkbox_0_Template, 1, 2, "mat-pseudo-checkbox", 1);
          i0.ɵɵelementStart(1, "span", 2, 0);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, MatLegacyOption_span_4_Template, 2, 1, "span", 3);
          i0.ɵɵelement(5, "div", 4);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.multiple);
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("ngIf", ctx.group && ctx.group._inert);
          i0.ɵɵadvance();
          i0.ɵɵproperty("matRippleTrigger", ctx._getHostElement())("matRippleDisabled", ctx.disabled || ctx.disableRipple);
        }
      },
      dependencies: [i1.MatRipple, i2.NgIf, i1.MatPseudoCheckbox],
      styles: [".mat-option{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;line-height:48px;height:48px;padding:0 16px;text-align:left;text-decoration:none;max-width:100%;position:relative;cursor:pointer;outline:none;display:flex;flex-direction:row;max-width:100%;box-sizing:border-box;align-items:center;-webkit-tap-highlight-color:rgba(0,0,0,0)}.mat-option[disabled]{cursor:default}[dir=rtl] .mat-option{text-align:right}.mat-option .mat-icon{margin-right:16px;vertical-align:middle}.mat-option .mat-icon svg{vertical-align:top}[dir=rtl] .mat-option .mat-icon{margin-left:16px;margin-right:0}.mat-option[aria-disabled=true]{-webkit-user-select:none;user-select:none;cursor:default}.mat-optgroup .mat-option:not(.mat-option-multiple){padding-left:32px}[dir=rtl] .mat-optgroup .mat-option:not(.mat-option-multiple){padding-left:16px;padding-right:32px}.mat-option.mat-active::before{content:\"\"}.cdk-high-contrast-active .mat-option[aria-disabled=true]{opacity:.5}.cdk-high-contrast-active .mat-option.mat-selected:not(.mat-option-multiple)::after{content:\"\";position:absolute;top:50%;right:16px;transform:translateY(-50%);width:10px;height:0;border-bottom:solid 10px;border-radius:10px}[dir=rtl] .cdk-high-contrast-active .mat-option.mat-selected:not(.mat-option-multiple)::after{right:auto;left:16px}.mat-option-text{display:inline-block;flex-grow:1;overflow:hidden;text-overflow:ellipsis}.mat-option .mat-option-ripple{top:0;left:0;right:0;bottom:0;position:absolute;pointer-events:none}.mat-option-pseudo-checkbox{margin-right:8px}[dir=rtl] .mat-option-pseudo-checkbox{margin-left:8px;margin-right:0}"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyOption, [{
    type: Component,
    args: [{
      selector: 'mat-option',
      exportAs: 'matOption',
      host: {
        'role': 'option',
        '[attr.tabindex]': '_getTabIndex()',
        '[class.mat-selected]': 'selected',
        '[class.mat-option-multiple]': 'multiple',
        '[class.mat-active]': 'active',
        '[id]': 'id',
        '[attr.aria-selected]': 'selected',
        '[attr.aria-disabled]': 'disabled.toString()',
        '[class.mat-option-disabled]': 'disabled',
        '(click)': '_selectViaInteraction()',
        '(keydown)': '_handleKeydown($event)',
        'class': 'mat-option mat-focus-indicator'
      },
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<mat-pseudo-checkbox *ngIf=\"multiple\" class=\"mat-option-pseudo-checkbox\"\n    [state]=\"selected ? 'checked' : 'unchecked'\" [disabled]=\"disabled\"></mat-pseudo-checkbox>\n\n<span class=\"mat-option-text\" #text><ng-content></ng-content></span>\n\n<!-- See a11y notes inside optgroup.ts for context behind this element. -->\n<span class=\"cdk-visually-hidden\" *ngIf=\"group && group._inert\">({{ group.label }})</span>\n\n<div class=\"mat-option-ripple\" mat-ripple\n     [matRippleTrigger]=\"_getHostElement()\"\n     [matRippleDisabled]=\"disabled || disableRipple\">\n</div>\n",
      styles: [".mat-option{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;line-height:48px;height:48px;padding:0 16px;text-align:left;text-decoration:none;max-width:100%;position:relative;cursor:pointer;outline:none;display:flex;flex-direction:row;max-width:100%;box-sizing:border-box;align-items:center;-webkit-tap-highlight-color:rgba(0,0,0,0)}.mat-option[disabled]{cursor:default}[dir=rtl] .mat-option{text-align:right}.mat-option .mat-icon{margin-right:16px;vertical-align:middle}.mat-option .mat-icon svg{vertical-align:top}[dir=rtl] .mat-option .mat-icon{margin-left:16px;margin-right:0}.mat-option[aria-disabled=true]{-webkit-user-select:none;user-select:none;cursor:default}.mat-optgroup .mat-option:not(.mat-option-multiple){padding-left:32px}[dir=rtl] .mat-optgroup .mat-option:not(.mat-option-multiple){padding-left:16px;padding-right:32px}.mat-option.mat-active::before{content:\"\"}.cdk-high-contrast-active .mat-option[aria-disabled=true]{opacity:.5}.cdk-high-contrast-active .mat-option.mat-selected:not(.mat-option-multiple)::after{content:\"\";position:absolute;top:50%;right:16px;transform:translateY(-50%);width:10px;height:0;border-bottom:solid 10px;border-radius:10px}[dir=rtl] .cdk-high-contrast-active .mat-option.mat-selected:not(.mat-option-multiple)::after{right:auto;left:16px}.mat-option-text{display:inline-block;flex-grow:1;overflow:hidden;text-overflow:ellipsis}.mat-option .mat-option-ripple{top:0;left:0;right:0;bottom:0;position:absolute;pointer-events:none}.mat-option-pseudo-checkbox{margin-right:8px}[dir=rtl] .mat-option-pseudo-checkbox{margin-left:8px;margin-right:0}"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [MAT_OPTION_PARENT_COMPONENT]
      }]
    }, {
      type: MatLegacyOptgroup,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [MAT_OPTGROUP]
      }]
    }];
  }, null);
})();

/**
 * @deprecated Use `MatOptionModule` from `@angular/material/core` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyOptionModule {
  static {
    this.ɵfac = function MatLegacyOptionModule_Factory(t) {
      return new (t || MatLegacyOptionModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatLegacyOptionModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [MatRippleModule, CommonModule, MatCommonModule, MatPseudoCheckboxModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyOptionModule, [{
    type: NgModule,
    args: [{
      imports: [MatRippleModule, CommonModule, MatCommonModule, MatPseudoCheckboxModule],
      exports: [MatLegacyOption, MatLegacyOptgroup],
      declarations: [MatLegacyOption, MatLegacyOptgroup]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MatLegacyOptgroup, MatLegacyOption, MatLegacyOptionModule };
