<div class="upload z-fx-col z-fx-gap-8" [class.disabled]="disabled">
  <zef-drag-zone
    [class.inactive]="mode !== 'file' && !uploading && !hovering && file"
    [class.loading]="uploading"
    [class.round]="isLarge"
    [ngClass]="[size, shape || '']"
    [active]="active"
    (zoneActive)="hovering = $event"
    (zoneDrop)="onZoneDrop($event)"
    (zoneDragStart)="dropping = true"
    (zoneDragEnd)="dropping = false"
    (click)="!uploading && !disableClick && fileInput.click()"
  >
    <img
      *ngIf="file && mode !== 'file' && !uploading && imageSrc"
      [src]="imageSrc"
      class="upload-image"
      [ngClass]="fit"
      [class.landscape]="imgEl.height > imgEl.width"
      [class.portrait]="imgEl.height < imgEl.width"
      [class.team-avatar-cover]="useCover"
      [class.team-avatar-contain]="useContain"
      (load)="onImageLoad()"
      alt="upload"
      #imgEl
    />

    <div class="z-fx-col z-fx-center-center z-fx-gap-16 zef-text-overflow-line upload-content">
      <ng-container *ngIf="uploading; else isHovering">
        <zef-spinner
          [mode]="!progress || progress === 1 ? 'indeterminate' : 'determinate'"
          [progress]="progress"
          [size]="isLarge ? 'large' : 'larger'"
        ></zef-spinner>

        <div *ngIf="mode === 'file' && fileName">
          {{ fileName }}
        </div>
      </ng-container>

      <ng-template #isHovering>
        <ng-container *ngIf="hovering; else hasError">
          <ng-container [ngTemplateOutlet]="nullOrHover"></ng-container>
        </ng-container>
      </ng-template>

      <ng-template #hasError>
        <ng-container *ngIf="error; else hasFile">
          <mat-icon color="alert" [class.zef-icon-larger]="isLarger" [class.zef-icon-large]="isLarge">
            bullet_alert
          </mat-icon>

          <div *ngIf="mode === 'file'" class="zef-color-alert">
            <ng-container *ngIf="fileName">{{ fileName }}</ng-container>
            <ng-container *ngIf="!fileName" i18n>No file found...</ng-container>
          </div>
        </ng-container>
      </ng-template>

      <ng-template #hasFile>
        <ng-container *ngIf="file; else nullOrHover">
          <ng-container *ngIf="mode === 'file'">
            <mat-icon color="ink" class="zef-icon-larger">bullet_check</mat-icon>

            <div class="zef-color-ink">
              {{ fileName }}
            </div>
          </ng-container>
        </ng-container>
      </ng-template>
    </div>
  </zef-drag-zone>

  <div *ngIf="error && error !== true" class="zef-color-alert zef-text-p1">
    <ng-container [ngTemplateOutlet]="error"></ng-container>
  </div>
</div>

<input class="upload-input" type="file" (change)="onFileInputChange(fileInput)" #fileInput />

<ng-template #nullOrHover>
  <mat-icon *ngIf="mode === 'file'" class="zef-icon-larger">upload</mat-icon>

  <mat-icon *ngIf="mode === 'upload'" class="zef-icon-large">upload</mat-icon>

  <mat-icon *ngIf="mode === 'image'" class="zef-icon-larger">drag</mat-icon>

  <mat-icon *ngIf="mode === 'avatar'" class="zef-icon-large">photo_new</mat-icon>

  <mat-icon *ngIf="mode === 'team'" class="zef-icon-larger">upload</mat-icon>

  <div *ngIf="showText && !size.startsWith('small')" class="zef-text-overflow-line" i18n>
    Drag here or click to browse...
  </div>
</ng-template>
