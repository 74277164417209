import { Subject, timer, merge, ReplaySubject } from 'rxjs';
import { debounce, distinctUntilChanged, takeUntil, filter } from 'rxjs/operators';

import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output, HostBinding, OnChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

@Component({
  selector: 'zef-search-expand',
  exportAs: 'searchExpand',
  templateUrl: './search-expand.component.html',
  styleUrls: ['./search-expand.component.scss'],
  providers: [LifecycleHooks],
  animations: [
    trigger('expand', [
      state('void', style({ width: '0', opacity: 0 })),
      state('*', style({ width: '*', opacity: 1 })),
      transition(':enter, :leave', animate('200ms ease-in-out')),
    ]),
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('200ms 100ms ease-in-out')),
      transition(':leave', animate('100ms ease-in-out')),
    ]),
    trigger('parent', [transition(':enter', [])]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchExpand implements OnChanges {
  @HostBinding('@parent')
  isParent?: boolean;

  @Input()
  value?: string;

  @Input()
  space?: boolean;

  @Input()
  placeholder?: string;

  @Input()
  debounce = 500;

  @Output()
  readonly valueChange = new EventEmitter<string | null>();

  readonly open$ = new ReplaySubject<boolean>(1);

  private input$ = new Subject<string>();

  constructor(private lh: LifecycleHooks) {}

  ngOnChanges(): void {
    if (this.value) {
      this.onSearchOpen();
    }
  }

  onSearchOpen(): void {
    this.open$.next(true);

    this.input$
      .pipe(
        debounce(() => timer(this.debounce)),
        distinctUntilChanged(),
        takeUntil(merge(this.lh.destroy, this.open$.pipe(filter((open) => !open)))),
      )
      .subscribe((value) => {
        this.valueChange.next(value);
      });
  }

  onSearchClose(): void {
    this.open$.next(false);
    this.valueChange.next(null);
  }

  onInput(value: string): void {
    this.input$.next(value);
  }
}
