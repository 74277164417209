import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PromoDialog } from '@shared/components/promo-dialog/promo-dialog.component';
import { BasicModule } from '@shared/modules/basic.module';

@NgModule({
  imports: [BasicModule, CommonModule, RouterModule, MatIconModule, MatButtonModule, MatDialogModule],
  declarations: [PromoDialog],
  exports: [PromoDialog],
})
export class PromoDialogModule {}
