import { trigger, state, style, transition, animate } from '@angular/animations';

export enum DropdownState {
  OpenDown = 'openDown',
  OpenUp = 'openUp',
  Close = 'close',
}

const dropdownAnimationTime = 100;

export const dropdownAnimation = trigger('dropdown', [
  state(DropdownState.OpenUp, style({ transform: 'translateY(0) scaleY(1)', opacity: 1 })),
  state(DropdownState.OpenDown, style({ transform: 'translateY(0) scaleY(1)', opacity: 1 })),
  state(DropdownState.Close, style({ transform: 'translateY(0) scaleY(0)', opacity: 0 })),
  state('void', style({ transform: 'translateY(0) scaleY(0)', opacity: 0 })),
  transition(`${DropdownState.Close} => ${DropdownState.OpenUp}`, [
    style({ transform: 'translateY(50%) scaleY(0)', opacity: 0 }),
    animate(`${dropdownAnimationTime}ms ease-out`),
  ]),
  transition(`${DropdownState.Close} => ${DropdownState.OpenDown}`, [
    style({ transform: 'translateY(-50%) scaleY(0)', opacity: 0 }),
    animate(`${dropdownAnimationTime}ms ease-out`),
  ]),
  transition(`${DropdownState.OpenUp} => ${DropdownState.Close}`, [
    animate(`${dropdownAnimationTime}ms ease-in`, style({ transform: 'translateY(50%) scaleY(0)', opacity: 0 })),
  ]),
  transition(`${DropdownState.OpenDown} => ${DropdownState.Close}`, [
    animate(`${dropdownAnimationTime}ms ease-in`, style({ transform: 'translateY(-50%) scaleY(0)', opacity: 0 })),
  ]),
]);
