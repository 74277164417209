import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { CdkPortal } from '@angular/cdk/portal';
import { AnimationEvent } from '@angular/animations';

import { NgScrollbar } from 'ngx-scrollbar';

import { SelectType } from '@shared/components/select/select.model';
import { SelectLabel } from '@shared/components/select/select-label.directive';
import { SelectPrefix } from '@shared/components/select/select-prefix.directive';
import { SelectOptions } from '@shared/components/select/select-options.directive';
import { SelectService } from '@shared/components/select/select.service';
import { rotateAnimation } from '@shared/animations/rotate.anim';
import { SelectSuffix } from '@shared/components/select/select-suffix.directive';
import { dropdownAnimation, DropdownState } from '@shared/animations/dropdown.anim';

@Component({
  selector: 'zef-select',
  exportAs: 'zefSelect',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [SelectService],
  animations: [dropdownAnimation, rotateAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Select<T = any> implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  private selectOverlay?: OverlayRef;

  private newOverlay?: OverlayRef;

  private init = false;

  readonly Types = SelectType;

  @Input()
  set value(value: T) {
    this.newSelected = false;
    this.ss.value$.next(value);

    const wait = this.init ? (cb) => cb() : setTimeout;

    wait(() => {
      this.cd.markForCheck();
      this.cd.detectChanges();
    });
  }

  @Input()
  set multiSelect(value: boolean) {
    this.ss.isMultiSelect$.next(value);
  }

  @Input()
  type: SelectType = SelectType.Text;

  @Input()
  placeholder?: string;

  @Input()
  canSearch?: boolean;

  @Input()
  valuePrefixOnly?: boolean;

  @Input()
  noPlaceholderPrefix?: boolean;

  @Input()
  searchHint: string = '';

  @Input()
  defaultSearchValue?: string;

  @Input()
  canNew?: boolean;

  @Input()
  useNewPrefix?: boolean;

  @Input()
  newHint: string = '';

  @Input()
  canClear?: boolean;

  @Input()
  clearHint?: string;

  @Input()
  readOnly?: boolean;

  @Input()
  disabled?: boolean;

  @Input()
  loading?: boolean;

  @Input()
  listMaxWidth = 600;

  @Input()
  newSelected?: boolean;

  @Input()
  newValue: string = '';

  @Input()
  newPrefixIcon?: string;

  @Input()
  offsetY: number = 0;

  @Input()
  space: boolean = true;

  @Input()
  size: 'medium' | 'big' = 'medium';

  @Input()
  selectedText: string = $localize`selected`;

  @Output()
  readonly change = new EventEmitter<T>();

  @Output()
  readonly new = new EventEmitter<string>();

  @Output()
  readonly search = new EventEmitter<string>();

  @Output()
  readonly close = new EventEmitter<void>();

  @Output()
  readonly selectOverflow = new EventEmitter<boolean>();

  @ContentChild(SelectLabel, { static: true })
  set selectLabel(selectLabel: SelectLabel) {
    if (selectLabel) {
      this.ss.selectLabel = selectLabel.template;
    }
  }

  @ContentChild(SelectPrefix, { static: false })
  set selectPrefix(selectPrefix: SelectPrefix) {
    if (selectPrefix?.template) {
      this.ss.selectLabelPrefix = selectPrefix.template;
    }
  }

  @ContentChild(SelectSuffix)
  selectSuffix?: SelectSuffix;

  @ContentChild(SelectOptions, { static: true })
  selectOptions?: SelectOptions;

  @ViewChild(CdkPortal, { static: true })
  selectPortal?: CdkPortal;

  @ViewChild('searchInput', { read: MatInput })
  searchInput?: MatInput;

  @ViewChild('newInput', { read: MatInput })
  newInput?: MatInput;

  @ViewChild(NgScrollbar)
  set scrollbar(sb: NgScrollbar) {
    this.ss.scroller = sb;
  }

  isInline?: boolean;

  isOpen: boolean = false;

  isNewOpen: boolean = false;

  dropdownState: DropdownState = DropdownState.Close;

  openStateValue: any;

  readonly value$ = this.ss.value$.asObservable();

  get searchValue(): string {
    return this.searchInput?.value || '';
  }

  get placeholderNew(): string {
    return this.newHint || $localize`Create new...`;
  }

  get placeholderSearch(): string {
    return this.searchHint || $localize`Search...`;
  }

  constructor(
    private overlay: Overlay,
    private el: ElementRef<HTMLElement>,
    private cd: ChangeDetectorRef,
    readonly ss: SelectService,
  ) {}

  ngOnInit(): void {
    this.ss.optionSelected$.subscribe(({ isNew, value }) => {
      if (isNew) {
        this.openNewInput();
      } else {
        this.selectOption(value);
      }
    });

    if (this.defaultSearchValue) {
      this.onSearchInput(this.defaultSearchValue);
    }
  }

  ngAfterViewInit(): void {
    this.init = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      this.isInline = [SelectType.InlineP2, SelectType.InlineH3].includes(this.type);
    }

    if (changes.defaultSearchValue) {
      this.onSearchInput(this.defaultSearchValue);
    }
  }

  ngOnDestroy(): void {
    this.selectOverlay?.dispose();
    this.newOverlay?.dispose();
  }

  selectOption(value: T): void {
    this.newSelected = false;

    if (!this.ss.isMultiSelect$.value) {
      this.change.emit(value);
      this.ss.value$.next(value);
    } else {
      const valueArr: T[] = [...(this.ss.radioSelected$.value ? [] : this.ss.value$.value || [])];

      if (valueArr.indexOf(value) >= 0) {
        valueArr.splice(valueArr.indexOf(value), 1);
      } else {
        valueArr.push(value);
      }

      if (valueArr.length === 0 && this.ss.defaultSelection$.value) {
        valueArr.push(this.ss.defaultSelection$.value);
        this.ss.radioSelected$.next(true);
      }

      this.change.emit(valueArr as T);
      this.ss.value$.next(valueArr);
    }

    setTimeout(() => {
      if (!this.ss.isMultiSelect$.value) {
        this.closeSelect();
      }
      this.cd.markForCheck();
    });
  }

  openSelect(): void {
    if (this.readOnly || this.disabled || this.isOpen || !this.selectPortal) {
      return;
    }

    if (!this.newValue) {
      this.isNewOpen = false;
    }

    if (this.selectOverlay) {
      this.selectOverlay.dispose();
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.el)
      .withPositions([
        { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
        { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
        { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
        { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
      ])
      .withDefaultOffsetY(this.offsetY)
      .withPush(true)
      .withGrowAfterOpen(true)
      .withFlexibleDimensions(true);

    positionStrategy.positionChanges.subscribe((change) => {
      const oldState = this.dropdownState;

      this.dropdownState = change.connectionPair.originY === 'top' ? DropdownState.OpenUp : DropdownState.OpenDown;

      if (oldState !== this.dropdownState) {
        const translateX = change.connectionPair.originX === 'end' ? 8 : -8;
        const translateY = this.dropdownState === DropdownState.OpenUp ? 8 : -8;

        this.selectOverlay.hostElement.style.transform = `translate(${translateX}px, ${translateY}px)`;
      }

      setTimeout(() => this.ss.updateScroll());
    });

    const config = new OverlayConfig({
      minWidth: Math.max(200, this.el.nativeElement.offsetWidth),
      maxWidth: this.listMaxWidth,
      positionStrategy,
      backdropClass: ['cdk-overlay-transparent-backdrop', 'zef-select'],
      panelClass: ['zef-select-panel'],
      hasBackdrop: true,
      disposeOnNavigation: true,
    });

    this.selectOverlay = this.overlay.create(config);

    merge(
      this.selectOverlay.keydownEvents().pipe(filter((event) => event.code === 'Escape')),
      this.selectOverlay.backdropClick(),
    ).subscribe(() => {
      this.closeSelect();
      this.cd.markForCheck();
    });

    this.selectOverlay.attach(this.selectPortal);

    this.isOpen = true;

    if (this.ss.isMultiSelect$.value && Array.isArray(this.ss.value$.value)) {
      this.openStateValue = this.ss.value$.value?.slice();
    }

    setTimeout(() => this.ss.beforeOpened$.next());
  }

  onSearchInput(search: string): void {
    this.search.emit(search);
    this.ss.search$.next(search);
  }

  onDropdownAnimationDone(event: AnimationEvent): void {
    if (event.fromState === DropdownState.Close) {
      if (this.isOpen) {
        this.selectOverlay?.updatePosition();
        this.ss.afterOpened$.next();
        (this.searchInput || this.newInput)?.focus();
        // scrolling in editor while opening select
        setTimeout(() => {
          if (this.isOpen) {
            this.selectOverlay?.updatePosition();
          }
        }, 500);
      }
    } else if (event.fromState === DropdownState.OpenUp || event.fromState === DropdownState.OpenDown) {
      this.ss.search$.next('');
      this.selectOverlay?.dispose();
      this.selectOverlay = undefined;
    }
  }

  closeSelect(): void {
    this.isOpen = false;
    this.dropdownState = DropdownState.Close;

    if (
      this.ss.isMultiSelect$.value &&
      (!this.openStateValue ||
        !Array.isArray(this.openStateValue) ||
        !Array.isArray(this.ss.value$.value) ||
        this.openStateValue.sort((a, b) => (a > b ? 1 : a === b ? 0 : -1)).join() !==
          [...this.ss.value$.value].sort((a, b) => (a > b ? 1 : a === b ? 0 : -1)).join())
    ) {
      this.change.emit(this.ss.value$.value);
    }

    this.close.emit();
  }

  onNewValue(value: string): void {
    value = value.trim();

    if (value) {
      this.newValue = value;
      this.newSelected = true;
      this.new.emit(value);
      this.ss.value$.next(null);
      this.closeSelect();
    }
  }

  closeNewInput(): void {
    this.isNewOpen = false;

    setTimeout(() => this.searchInput?.focus());
  }

  openNewInput(): void {
    this.isNewOpen = true;

    setTimeout(() => this.newInput?.focus());
  }

  public updatePosition(): void {
    this.selectOverlay?.updatePosition();
  }
}
