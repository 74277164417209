import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zef-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class Popup implements OnInit {
  @Input() xOffset: number = 0;

  @Input() yOffset: number = 0;

  menuTransform: object | null = null;

  ngOnInit() {
    this.menuTransform = {
      transform: `translate(${this.xOffset}px, ${this.yOffset}px)`,
    };
  }
}
