// @todo keep in sync with web backend Image Upload models

export type ImageUploadDirectory =
  | 'user-avatars'
  | 'contact-avatars'
  | 'popup-images'
  | 'outcome-images'
  | 'info-images'
  | 'design-logos'
  | 'backgrounds'
  | 'share-images'
  | 'email-images'
  | 'external'
  | 'design-bot'
  | 'choice-images'
  | 'team-logos'
  | 'poll-images';

export type ImageFit = 'cover' | 'contain';

export const ImageDefaultValue = '__IMAGE_DEFAULT__';

export interface ImageGalleryItemUrls {
  thumb: string;
  original: string;
  regular: string;
  download?: string;
}

export interface ImageGalleryRequestParams {
  page?: number | string;
  maxResults?: number;
  query?: string;
}

export interface ImageGalleryItem {
  id?: string;
  name: string;
  size?: number;
  updated?: number;
  created: number;
  urls: ImageGalleryItemUrls;
  blurHash?: string;
}

export interface ExternalImageGalleryItem extends ImageGalleryItem {
  id: string;
  blurHash: string;
  user: string;
  userProfile: string;
}

export type ImageUrlSource = keyof ImageGalleryItemUrls;
