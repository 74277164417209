import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ZefThemePalette } from '@shared/models/color.model';
import { HelpCenterSidenav } from '@shared/modules/help-center/components/help-sidenav/help-sidenav.component';
import {
  HelpCenterItemStatus,
  HelpCenterLanguage,
  HelpCenterMode,
  HelpItemSubject,
} from '@shared/modules/help-center/help-center.models';
import {
  GetHelpItemStatistics,
  GetHelpItemStatus,
  GetHelpItemTip,
  GetHelpItemTitle,
  HideArticle,
  ShowArticle,
  UpdateHelpCenterMode,
} from '@shared/modules/help-center/state/help-center.actions';
import { HelpCenterState } from '@shared/modules/help-center/state/help-center.state';
import { getLastValue, shareRef } from '@shared/operators/share-ref.operator';
import { AccountState } from '@shared/states/account.state';
import { AuthState } from '@shared/states/auth.state';
import { OpenSidenav } from '@shared/states/dialog.actions';
import { PrefsState } from '@shared/states/prefs.state';
import { assertStoreData } from '@shared/utilities/store.utilities';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { MatLegacyMenu } from '@angular/material/legacy-menu';
import { SidenavRef } from '@shared/modules/sidenav/models/sidenav.models';

@Component({
  selector: 'zef-help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['./help-icon.component.scss'],
  providers: [LifecycleHooks],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpIcon implements OnInit, OnDestroy {
  readonly statusColorMap: Record<HelpCenterItemStatus, string> = {
    [HelpCenterItemStatus.Missing]: 'alert',
    [HelpCenterItemStatus.Partial]: 'warning',
    [HelpCenterItemStatus.Complete]: 'ink-lightest',
  };

  readonly _subject$ = new ReplaySubject<HelpItemSubject>(1);

  @Select(PrefsState.language)
  readonly lang$!: Observable<HelpCenterLanguage>;

  @Input()
  set subject(subject: HelpItemSubject) {
    this._subject$.next(subject);
  }

  readonly subject$: Observable<HelpItemSubject> = this._subject$.pipe(
    filter((subject) => !!subject),
    distinctUntilChanged(),
    shareRef(),
  );

  readonly status$: Observable<HelpCenterItemStatus> = this.subject$.pipe(
    switchMap((subject) =>
      assertStoreData(this.store, HelpCenterState.status(subject), new GetHelpItemStatus(subject)),
    ),
    shareRef(),
  );

  readonly color$: Observable<ZefThemePalette> = this.status$.pipe(
    map((status) => this.statusColorMap[status] as ZefThemePalette),
  );

  readonly tip$: Observable<string> = combineLatest([this.subject$, this.lang$]).pipe(
    switchMap(([subject, lang]) =>
      assertStoreData(this.store, HelpCenterState.tip(subject, lang), new GetHelpItemTip(subject, lang)),
    ),
    shareRef(),
  );

  readonly canEdit$ = combineLatest([
    this.store.select(AuthState.isZefAdmin),
    this.store.select(AccountState.isParentAdmin),
  ]).pipe(
    map(([isAdmin, isParentAdmin]) => isAdmin || isParentAdmin),
    shareRef(),
  );

  readonly show$: Observable<boolean> = combineLatest([this.status$, this.canEdit$]).pipe(
    map(([status, canEdit]) => status === HelpCenterItemStatus.Complete || canEdit),
  );

  constructor(
    private store: Store,
    private lh: LifecycleHooks,
    @Optional() private sn: SidenavRef,
    @Optional() private mn: MatLegacyMenu,
  ) {}

  ngOnInit() {
    if (!this.sn && !this.mn) {
      const lang = this.store.selectSnapshot(PrefsState.language) as HelpCenterLanguage;
      const subject = getLastValue(this.subject$);
      this.store.dispatch(new ShowArticle(subject));

      assertStoreData(
        this.store,
        HelpCenterState.data(subject, lang),
        new GetHelpItemTitle(subject, lang),
        (data) => data?.title != null,
      )
        .pipe(takeUntil(this.lh.destroy))
        .subscribe();

      assertStoreData(
        this.store,
        HelpCenterState.stats(subject),
        new GetHelpItemStatistics(subject),
        (data) => data?.title != null,
      )
        .pipe(takeUntil(this.lh.destroy))
        .subscribe();
    }
  }

  ngOnDestroy() {
    if (!this.sn && !this.mn) {
      const subject = getLastValue(this.subject$);
      this.store.dispatch(new HideArticle(subject));
    }
  }

  openArticle(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    const subject = getLastValue(this.subject$);
    const status = getLastValue(this.status$);
    const canEdit = getLastValue(this.canEdit$);

    this.store.dispatch([
      new UpdateHelpCenterMode({
        mode: canEdit && status !== HelpCenterItemStatus.Complete ? HelpCenterMode.Edit : HelpCenterMode.Read,
        language: getLastValue(this.lang$),
      }),
      new OpenSidenav(HelpCenterSidenav, { data: { subject } }),
    ]);
  }
}
