import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'zeffi-icon',
  templateUrl: './zeffi-icon.component.html',
  styleUrls: ['./zeffi-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZeffiIcon {
  @Input()
  @HostBinding('class')
  class: string;

  @Input()
  invert?: boolean;
}
