import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { MessageKind } from '@shared/models/email.model';

@Component({
  selector: 'zef-message-mark',
  templateUrl: './message-mark.component.html',
  styleUrls: ['./message-mark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageMark {
  @Input() kind: MessageKind;

  @Input() defaultLabel: boolean;

  @Input() size: 'small' | 'medium' = 'small';

  get colorClass(): string {
    return `zef-color-${this.kind === 'email' ? 'emails' : this.kind}`;
  }
}
