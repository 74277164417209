<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<div *ngIf="removable; else content">
  <span class="z-fx-sc">
    <ng-container *ngTemplateOutlet="content"></ng-container>

    <button
      mat-icon-button
      class="zef-transparent-button chip-close"
      matTooltip="Remove value"
      i18n-matTooltip
      (click)="$event.stopPropagation(); remove.emit()"
    >
      <mat-icon color="secondary" class="zef-icon-small">close</mat-icon>
    </button>
  </span>
</div>
