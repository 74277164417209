<ng-container
  *ngIf="{
    isTeamAdmin: (isTeamAdmin$ | async),
    isEnabled: (isEnabled$ | async),
  } as asyncData"
>
  <button
    class="zef-action-button"
    color="beta"
    mat-raised-button
    [routerLink]="!asyncData.isTeamAdmin ? ['/settings/org'] : null"
    (click)="asyncData.isTeamAdmin && asyncData.isEnabled ? enableExperimental() : null"
    [inlineDialogTriggerFor]="asyncData.isTeamAdmin && !asyncData.isEnabled ? optInDialog : null"
  >
    <ng-container i18n>Enable for Organization...</ng-container>
  </button>

  <zef-inline-dialog color="ink" #optInDialog (confirm)="enableExperimental()">
    <ng-container *dialogTitle i18n>Opt-in to Beta features</ng-container>

    <ng-container *dialogContent i18n>
      Beta features may occasionally behave unexpectedly. By Opting-in you agree to Zeffi's
      <a
        class="zef-text-link-hover-underline zef-color-primary no-margin"
        href="https://zef.fi/terms-of-service/"
        target="_blank"
        i18n-href
      >
        Terms Of Service
      </a>
    </ng-container>

    <ng-container *dialogAction i18n>Agree and Continue</ng-container>
  </zef-inline-dialog>
</ng-container>
