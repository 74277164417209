import { Pipe, PipeTransform } from '@angular/core';

import { UcFirstPipe } from 'ngx-pipes';

import { Store } from '@ngxs/store';

import { RecipientState, SmsErrorState } from '@shared/models/recipient.model';

const statuses: Record<RecipientState | SmsErrorState, string> = {
  PENDING: $localize`:message status@@zef-i18n-00140:pending`,
  SENDING: $localize`:message status@@zef-i18n-00141:sending`,
  SENT: $localize`:message status@@zef-i18n-00142:sent`,
  DELIVERED: $localize`:message status@@zef-i18n-00143:delivered`,
  ERROR_SENDING: $localize`:message status@@zef-i18n-00144:error sending`,
  DROPPED: $localize`:message status@@zef-i18n-00145:dropped`,
  BOUNCED: $localize`:message status@@zef-i18n-00146:bounced`,
  OPENED: $localize`:message status@@zef-i18n-00147:opened`,
  CLICKED: $localize`:message status@@zef-i18n-00148:clicked`,
  STARTED: $localize`:message status@@zef-i18n-00149:started`,
  COMPLETED: $localize`:message status@@zef-i18n-00150:completed`,

  SWITCHED_OFF: $localize`:message status@@zef-i18n-00151:switched off`,
  SENDER_BLOCKED: $localize`:message status@@zef-i18n-00152:sender blocked`,
  UNKNOWN_NUMBER: $localize`:message status@@zef-i18n-00153:unknown number`,
  UNAVAILABLE: $localize`:message status@@zef-i18n-00154:unavailable`,
  PARTIALLY_DELIVERED: $localize`:message status@@zef-i18n-00155:partially delivered`,
  CLIPPED: $localize`:message status@@zef-i18n-00156:clipped`,
  INTERNAL_ERROR: $localize`:message status@@zef-i18n-00157:internal error`,
  UNKNOWN_ERROR: $localize`:message status@@zef-i18n-00158:unknown error`,
  INSUFFICIENT_BALANCE: $localize`:message status@@zef-i18n-00159:insufficient balance`,
  FILTERED: $localize`:message status@@zef-i18n-00159-pk:filtered by operator`,
};

@Pipe({
  name: 'messageStatus',
})
export class MessageStatus implements PipeTransform {
  constructor(readonly store: Store) {}

  transform(status: RecipientState | SmsErrorState): string {
    return new UcFirstPipe().transform(statuses[status] || '');
  }
}
