/**
 * Clamps number to be between min & max values.
 *
 * @unstable
 */

import '@angular/localize/init';

import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

import { Store } from '@ngxs/store';

import moment from 'moment';

type DateInput = moment.Moment | Date | string | number | null | undefined;

@Pipe({
  name: 'sinceWhen',
})
export class SinceWhen extends DatePipe implements PipeTransform {
  readonly timeFrames = [
    { text: $localize`:@@zef-i18n-00065:Just now`, frame: 0 },
    { text: $localize`:@@zef-i18n-00066:1 minute ago`, frame: 60 },
    { text: $localize`:@@zef-i18n-00067:~ minutes ago`, frame: 60 * 2 },
    { text: $localize`:@@zef-i18n-00068:1 hour ago`, frame: 60 * 60 },
    { text: $localize`:@@zef-i18n-00069:~ hours ago`, frame: 60 * 60 * 2 },
    { text: $localize`:@@zef-i18n-00070:1 day ago`, frame: 60 * 60 * 24 },
    { text: $localize`:@@zef-i18n-00071:~ days ago`, frame: 60 * 60 * 24 * 2 },
  ];

  constructor(
    @Inject(LOCALE_ID) locale: string,
    readonly store: Store,
  ) {
    super(locale);
  }

  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: DateInput, ref?: DateInput, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: DateInput, ref?: DateInput, format?: string, timezone?: string, locale?: string): string | null {
    const now = this.getTime(value);
    const start = this.getTime(ref);
    const diff = (start - now) / 1000;

    if (diff < 0) {
      return '';
    }

    if (diff >= 60 * 60 * 24 * 30) {
      return super.transform(now, format, timezone, locale);
    }

    let i = this.timeFrames.length - 1;

    while (i >= 0 && diff < this.timeFrames[i].frame) {
      i--;
    }

    return this.getTimeFrame(i, i && Math.floor(diff / (this.timeFrames[i].frame / (i % 2 ? 1 : 2))));
  }

  getTime(value: any): number {
    if (!value) {
      value = Date.now();
    }

    if (moment.isMoment(value)) {
      value = value.toDate();
    } else if (typeof value === 'string') {
      value = new Date(value);
    }

    if (value instanceof Date) {
      value = value.getTime();
    }

    return value;
  }

  getTimeFrame(i: number, replace: number): string {
    const timeFrame = this.timeFrames[i] && this.timeFrames[i].text;

    if (!timeFrame) {
      return '';
    }

    return timeFrame.replace('~', `${replace}` || '');
  }
}
