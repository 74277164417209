import { Component, Input } from '@angular/core';
import { teamLogoPlaceholder } from '@shared/utilities/assets.utilities';

@Component({
  selector: 'team-tile',
  styleUrls: ['./team-tile.component.scss'],
  templateUrl: './team-tile.component.html',
})
export class TeamTile {
  logoPlaceholder = 'assets/images/team-placeholder.png';

  @Input()
  noBackground: boolean = false;

  @Input()
  variant: 'big' | 'small' = 'big';

  @Input()
  teamName: string = '';

  @Input()
  teamLogo: string = '';

  @Input()
  teamCreated: string | number;

  teamLogoUrl() {
    const created = +this.teamCreated;
    return this.teamLogo || teamLogoPlaceholder(created);
  }
}
