import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { random } from '@shared/utilities/array.utilities';
import { assetsDecoBanners } from '@shared/utilities/assets.utilities';

@Component({
  selector: 'random-background',
  templateUrl: './random-bg.component.html',
  styleUrls: ['./random-bg.component.scss'],
})
export class RandomBackground implements OnInit, OnChanges {
  public backgroundImage: string | null = null;
  @Input() images: string[] = assetsDecoBanners;

  ngOnInit(): void {
    if (!this.backgroundImage) {
      this.backgroundImage = random(this.images);
    }
  }

  ngOnChanges(): void {
    this.backgroundImage = random(this.images);
  }
}
