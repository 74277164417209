<button *ngIf="(open$ | async) !== true" @fade mat-icon-button color="secondary" (click)="onSearchOpen()">
  <mat-icon>action_find</mat-icon>
</button>

<div *ngIf="open$ | async" class="search-expand" @expand>
  <zef-input-search
    [placeholder]="placeholder"
    [value]="value"
    [autoFocus]="true"
    [space]="space"
    (valueChange)="onInput($event)"
    (close)="onSearchClose()"
  ></zef-input-search>
</div>
