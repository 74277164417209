import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Rights } from '@shared/enums/rights.enum';

import { IdentityData } from '@shared/models/prefs.model';

import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AccountState } from '@shared/states/account.state';
import { AuthState } from '@shared/states/auth.state';
import { ImageFit } from '@shared/modules/image-upload/models/image-upload.models';

@Component({
  selector: 'zef-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Avatar {
  readonly Rights = Rights;

  @Input() size: string = 'normal';

  @Input() emblem: boolean = false;

  @Input() notifications: number = 0;

  @Input() identity: IdentityData | null = null;

  @Input() fit: ImageFit = 'contain';

  @Select(AuthState.isZefAdmin)
  isZefAdmin$: Observable<boolean>;

  @Select(AccountState.userRole)
  userRole$: Observable<Rights>;
}
