import { timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'zef-spinner-ai-loading',
  templateUrl: 'spinner-ai-loading.component.html',
  styleUrl: './spinner-ai-loading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerAiLoadingComponent {
  readonly state$ = timer(0, 1000).pipe(map((time) => `state-${time % 4}`));
}
