import { shareReplay } from 'rxjs/operators';

import { Action } from '@ngxs/store';

export function SingleAction(actions: any | any[]) {
  return (target: any, name: string, descriptor: TypedPropertyDescriptor<any>) => {
    Action(actions)(target, name, descriptor);

    const originalMethod: Function = descriptor.value;
    const calls: { [args: string]: any } = {};

    descriptor.value = function (ctx, action) {
      const args = JSON.stringify(action);

      if (!calls[args]) {
        calls[args] = originalMethod.apply(this, [ctx, action]).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
      }

      return calls[args];
    };

    return descriptor;
  };
}
