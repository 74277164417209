import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';

import { StatusFlagDirective } from '@shared/modules/status-flag/status-flag.directive';
import { StatusFlagComponent } from '@shared/modules/status-flag/status-flag.component';

@NgModule({
  imports: [OverlayModule],
  declarations: [StatusFlagDirective, StatusFlagComponent],
  exports: [StatusFlagDirective, StatusFlagComponent],
})
export class StatusFlagModule {}
