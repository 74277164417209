<button mat-icon-button (click)="close.emit()"><mat-icon>close</mat-icon></button>

<div class="zef-no-select container-box z-fx-col z-fx-gap-24">
  <ng-scrollbar class="z-fx-col z-fx-flex">
    <div class="z-fx-col z-fx-gap-24 z-fx-no-shrink">
      <h3 class="menu-title">
        <ng-content select="[menu-title]"></ng-content>
      </h3>

      <div class="menu-content z-fx-col">
        <ng-content select="[menu-content]"></ng-content>
      </div>
    </div>
  </ng-scrollbar>

  <div class="menu-actions">
    <ng-content select="[menu-actions]"></ng-content>
  </div>
</div>
