import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { Spinner } from '@shared/components/spinner/spinner.component';
import { SpinnerBetaComponent } from '@shared/components/spinner/spinner-beta.component';
import { SpinnerAiAttentionComponent } from '@shared/components/spinner/spinner-ai-attention.component';
import { SpinnerAiLoadingComponent } from '@shared/components/spinner/spinner-ai-loading.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [Spinner, SpinnerBetaComponent, SpinnerAiAttentionComponent, SpinnerAiLoadingComponent],
  exports: [Spinner, SpinnerBetaComponent, SpinnerAiAttentionComponent, SpinnerAiLoadingComponent],
})
export class SpinnerModule {}
