import { Directive, Input, HostListener } from '@angular/core';

import { Toolbar } from '@shared/components/toolbar/components/toolbar/toolbar.component';

@Directive({
  selector: '[toolbarTriggerFor]',
})
export class ToolbarTriggerFor {
  @Input('toolbarTriggerFor')
  toolbar?: Toolbar;

  @HostListener('click')
  onClick(): void {
    this.toolbar?.toggle();
  }
}
