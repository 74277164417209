import { TemplateRef, Directive, Input } from '@angular/core';

@Directive({
  selector: '[selectPrefix]',
})
export class SelectPrefix {
  @Input('selectPrefixHidden')
  hidden?: boolean;

  get template(): TemplateRef<any> | undefined {
    return !this.hidden ? this.tr : undefined;
  }

  constructor(private tr: TemplateRef<any>) {}
}
