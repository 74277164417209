import { environment } from '@env/environment';

import { Observable, timer } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';

import { HelpGuide } from '@shared/modules/help-center/help-subject.enum';
import { HelpCenterState } from '@shared/modules/help-center/state/help-center.state';
import { HelpCenterService } from '@shared/modules/help-center/help-center.service';
import { HelpCenterGuide, HelpGuideStep } from '@shared/modules/help-center/help-center.models';
import { GetGuide, HideGuide, ShowGuide, StartGuide } from '@shared/modules/help-center/state/help-center.actions';

import { PrefsState } from '@shared/states/prefs.state';
import { ActionsState } from '@shared/states/actions.state';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { MarkHelpCenterGuide } from '@shared/states/prefs.actions';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'zef-help-guide',
  template: '',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LifecycleHooks],
})
export class HelpGuideComponent implements OnInit, OnDestroy {
  @Input()
  subject?: HelpGuide;

  @Input()
  stopOnNavigate?: boolean;

  @Output()
  readonly active = new EventEmitter<boolean>();

  @Select(ActionsState.dispatchedAction(StartGuide))
  readonly start$!: Observable<any>;

  constructor(
    private store: Store,
    private actions: Actions,
    private hcs: HelpCenterService,
    private lh: LifecycleHooks,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.actions
      .pipe(ofActionDispatched(StartGuide), takeUntil(this.lh.destroy))
      .subscribe(({ subject, steps }: StartGuide) => this.start(subject, steps));

    this.store.dispatch([new GetGuide(this.subject), new ShowGuide(this.subject)]);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart && this.stopOnNavigate),
        takeUntil(this.lh.destroy),
      )
      .subscribe(() => this.hcs.stopGuide());

    this.autoplay();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new HideGuide(this.subject));
  }

  private autoplay(): void {
    const help = this.store.selectSnapshot(PrefsState.help);
    const language = this.store.selectSnapshot(PrefsState.language);
    const lastPlayed = help?.guide?.[this.subject] || 0;

    this.store
      .select(HelpCenterState.item(this.subject as any) as any)
      .pipe(filter(Boolean), take(1), takeUntil(this.lh.destroy))
      .subscribe((guide: HelpCenterGuide) => {
        const replayAfterTime = 1000 * 60 * (environment.debug ? 1 : 60 * 24) * (guide?.replay || 0);
        const canStartTour = !lastPlayed ? true : !guide.replay ? false : Date.now() > lastPlayed + replayAfterTime;

        if (canStartTour && guide?.online && guide.steps?.length) {
          timer(1000)
            .pipe(takeUntil(this.lh.destroy))
            .subscribe(() => {
              const steps = guide.steps?.map((step) => this.hcs.stepWithLanguage(step, language));
              this.start(this.subject, steps);
            });
        }
      });
  }

  start(subject: HelpGuide, steps: HelpGuideStep[]): void {
    this.hcs.startGuide(subject, steps, () => {
      this.active.emit(false);
      return this.store.dispatch(new MarkHelpCenterGuide(subject, Date.now()));
    });

    this.active.emit(true);
  }
}
