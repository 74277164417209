<div class="upload z-fx-col z-fx-gap-24">
  <div class="upload-grid" [ngClass]="size">
    <image-upload-area [fit]="fit"></image-upload-area>

    <ng-container *ngIf="gallery">
      <zef-spinner *ngIf="preloading$ | async"></zef-spinner>

      <image-upload-item
        *ngFor="let image of images$ | async; trackBy: trackByImage"
        [image]="image"
      ></image-upload-item>
    </ng-container>
  </div>

  <div
    *ngIf="gallery && (startImages$ | async)?.length > 2 && (showAll$ | async) === false"
    class="zef-text-light zef-text-center zef-text-link zef-text-underline zef-text-p2"
    (click)="showAll()"
    i18n
  >
    Show all
  </div>
</div>
