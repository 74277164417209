<zef-file-upload
  [fit]="fit"
  [size]="iu.size"
  [mode]="iu.mode"
  [shape]="iu.shape"
  [error]="hasError"
  [file]="getImageSrc()"
  [progress]="progress$ | async"
  [uploading]="uploading$ | async"
  [disableClick]="iu.disableClick"
  (fileChange)="onFileChange($event)"
></zef-file-upload>
