import { takeUntil, delay } from 'rxjs/operators';

import {
  Component,
  Input,
  HostBinding,
  HostListener,
  ElementRef,
  OnInit,
  ContentChild,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { SelectService } from '@shared/components/select/select.service';
import { SelectPrefix } from '@shared/components/select/select-prefix.directive';
import { SelectOptionLabel } from '@shared/components/select/select-option-label.directive';
import { SelectLabel } from '@shared/components/select/select-label.directive';

@Component({
  selector: 'zef-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss'],
  providers: [LifecycleHooks],
})
export class SelectOption<T> implements OnInit, OnChanges, AfterViewInit {
  @Input()
  value?: T;

  @Input()
  isNew: boolean = false;

  @Input()
  overrideValue?: T;

  @Input()
  radioType?: boolean;

  @Input()
  @HostBinding('class.disabled')
  disabled?: boolean;

  @HostBinding('class.option-selected')
  selected: boolean;

  @HostBinding('class.multi-select-item')
  get multiSelect() {
    return this.ss.isMultiSelect$.value;
  }

  @ContentChild(SelectOptionLabel, { static: false })
  selectOptionLabel?: SelectOptionLabel;

  @ContentChild(SelectPrefix, { static: false })
  selectPrefix?: SelectPrefix;

  @ViewChild(SelectLabel, { static: false })
  selectContentLabel?: SelectLabel;

  @ViewChild('viewValueContent', { static: false, read: ElementRef })
  viewValueContent?: ElementRef<HTMLElement>;

  viewValue: string = '';

  @HostBinding('class.combo')
  get prefix(): TemplateRef<T> | undefined {
    return this.selectPrefix?.template || this.ss.selectLabelPrefix;
  }

  @HostBinding('class.zef-interactive')
  get isInteractive(): boolean {
    return !this.disabled;
  }

  constructor(
    readonly ss: SelectService,
    private el: ElementRef<HTMLElement>,
    private lh: LifecycleHooks,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && !changes.value.firstChange) {
      this.setTemplates();
    }
  }

  ngOnInit(): void {
    this.ss.value$
      .pipe(takeUntil(this.lh.destroy))
      .subscribe((value) =>
        !this.ss.isMultiSelect$.value
          ? (this.selected = value != null && this.value === value)
          : (this.selected = !!value?.length && value.indexOf(this.value) >= 0),
      );

    this.ss.beforeOpened$.pipe(delay(1), takeUntil(this.lh.destroy)).subscribe(() => {
      if (this.selected) {
        this.ss.scrollToElement(this.el.nativeElement);
      }
    });
  }

  ngAfterViewInit(): void {
    // because <ng-content> cannot be duplicated #issue 9173
    this.viewValue = this.viewValueContent?.nativeElement.textContent || ' ';

    this.setTemplates();
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  @HostListener('click')
  select(): void {
    if (!this.disabled) {
      if (!this.ss.isMultiSelect$.value) {
        this.ss.optionSelected$.next({ isNew: this.isNew, value: this.overrideValue || this.value });
      } else {
        if (this.radioType) {
          this.ss.radioSelected$.next(true);
        }

        this.ss.optionSelected$.next({ isNew: this.isNew, value: this.overrideValue || this.value });

        if (
          !this.radioType &&
          this.ss.radioSelected$.value &&
          (!this.ss.defaultSelection$.value ||
            (Array.isArray(this.ss.value$.value) && this.ss.defaultSelection$.value !== this.ss.value$.value.join()))
        ) {
          this.ss.radioSelected$.next(false);
        }
      }
    }
  }

  private setTemplates(): void {
    if (this.selectOptionLabel?.template || this.selectContentLabel?.template) {
      if (this.value != null && !this.isNew) {
        this.ss.updateOptionLabels(this.value, this.selectOptionLabel?.template || this.selectContentLabel?.template);
      }
    } else {
      this.ss.updateOptionLabels(this.value);
    }

    if (this.selectPrefix?.template) {
      if (this.value != null && !this.isNew) {
        this.ss.optionLabelPrefixes.set(this.value, this.selectPrefix.template);
      }
    } else {
      this.ss.optionLabelPrefixes.delete(this.value);
    }

    if (this.radioType && this.selected && !this.ss.radioSelected$.value) {
      this.ss.radioSelected$.next(true);
    }

    if (this.radioType && !this.ss.defaultSelection$.value) {
      this.ss.defaultSelection$.next(this.overrideValue || this.value);
    }
  }
}
