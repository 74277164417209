import { BehaviorSubject, combineLatest, defer, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';

import { Directive, EventEmitter } from '@angular/core';

import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';

import {
  ImageDefaultValue,
  ImageGalleryItem,
  ImageUploadDirectory,
  ImageUrlSource,
} from '@shared/modules/image-upload/models/image-upload.models';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { assertStoreData } from '@shared/utilities/store.utilities';
import { ImageUploadDefaultDirective } from '@shared/modules/image-upload/directive/image-upload-default.directive';
import { ImageUploadState } from '@shared/modules/image-upload/image-upload.state';
import {
  GetImages,
  RemoveImage,
  UploadImage,
  UploadImageComplete,
} from '@shared/modules/image-upload/image-upload.actions';
import { FileUploadMode, FileUploadShape, FileUploadSize } from '@shared/modules/file-upload/file-upload.models';
import { ActionsState } from '@shared/states/actions.state';

@Directive()
export abstract class ImageUploadService {
  abstract mode: FileUploadMode;

  abstract shape: FileUploadShape;

  abstract size: FileUploadSize;

  abstract directory: ImageUploadDirectory;

  abstract source: string;

  abstract gallery: boolean;

  abstract color?: string;

  abstract placeholder: string;

  abstract customGallery?: boolean;

  abstract disableClick?: boolean;

  abstract urlSource: ImageUrlSource;

  abstract defaultDirective?: ImageUploadDefaultDirective;

  abstract sourceChange: EventEmitter<{ image: ImageGalleryItem; source: string } | null>;

  protected _showAll = new BehaviorSubject(false);

  readonly showAll$: Observable<boolean> = this._showAll.asObservable();

  readonly galleryImages$: Observable<ImageGalleryItem[]> = defer(() =>
    assertStoreData(this.store, ImageUploadState.images(this.directory), new GetImages(this.directory)),
  ).pipe(
    map((images) => [...(images || [])].reverse()),
    take(1),
    shareReplay(1),
    this.lh.untilDestroy,
  );

  readonly removedImages$: Observable<ImageGalleryItem[]> = this.galleryImages$.pipe(
    switchMap((sourceImages) =>
      this.store
        .select(ImageUploadState.images(this.directory))
        .pipe(
          map((newImages) =>
            [...(sourceImages || [])].filter((image) => newImages.every((source) => source.name !== image.name)),
          ),
        ),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly uploadedImages$: Observable<ImageGalleryItem[]> = combineLatest([
    this.galleryImages$,
    this.removedImages$,
  ]).pipe(
    switchMap(([source, removed]) =>
      this.store
        .select(ImageUploadState.images(this.directory))
        .pipe(
          map((newImages) =>
            [...(newImages || [])]
              .filter((image) => [...source, ...removed].every(({ name }) => name !== image.name))
              .sort((a, b) => b.created - a.created),
          ),
        ),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly startImages$ = combineLatest([this.galleryImages$, this.removedImages$]).pipe(
    map(([source, removed]) => source.filter((image) => removed.every(({ name }) => name !== image.name))),
    map((images) => {
      const selectedIdx = images.findIndex((image) => image.urls[this.urlSource] === this.source);
      const defaultImage = this.defaultDirective ? [{ urls: { [this.urlSource]: ImageDefaultValue } }] : [];
      return [
        ...(selectedIdx > -1 ? [images[selectedIdx]] : []),
        ...defaultImage,
        ...images.filter((_, i) => i !== selectedIdx).sort((a, b) => b.created - a.created),
      ] as ImageGalleryItem[];
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly images$: Observable<ImageGalleryItem[]> = combineLatest([
    this.uploadedImages$,
    this.showAll$,
    this.startImages$,
  ]).pipe(
    map(([uploaded, showAll, gallery]) => [...uploaded, ...(showAll ? gallery : gallery.slice(0, 2))]),
    shareReplay(1),
    this.lh.untilDestroy,
  );

  @Select(ActionsState.whileAction(GetImages))
  readonly preloading$!: Observable<boolean>;

  protected constructor(
    private store: Store,
    private lh: LifecycleHooks,
    private actions: Actions,
  ) {}

  showAll(): void {
    this._showAll.next(true);
  }

  uploadImage(image: File): Observable<void> {
    this.store.dispatch(new UploadImage(this.directory, image));

    return this.actions.pipe(
      ofActionDispatched(UploadImageComplete),
      filter((action) => action?.directory === this.directory),
      take(1),
      map((action: UploadImageComplete) => {
        if (action.image) {
          this.changeImage(action.image);
        }
      }),
    );
  }

  isSelected(image: ImageGalleryItem): boolean {
    if (!this.source && this.defaultDirective) {
      return this.isDefault(image);
    }

    return this.source === image?.urls[this.urlSource];
  }

  isDefault(image: ImageGalleryItem): boolean {
    return ImageDefaultValue === image?.urls[this.urlSource];
  }

  changeImage(image: ImageGalleryItem): void {
    const source = this.isDefault(image) ? null : image.urls[this.urlSource];

    if (!this.customGallery) {
      this.source = source;
    }

    this.sourceChange.emit(source && { image, source });
  }

  removeImage(image: ImageGalleryItem): Observable<void> {
    return this.store.dispatch(new RemoveImage(this.directory, image.name));
  }
}
