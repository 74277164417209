/**
 * Locales support module.
 *
 * @unstable
 */

import localeFi from '@angular/common/locales/fi';

import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import { FormatDate } from '@shared/pipes/format-date.pipe';
import { CurrencyCent } from '@shared/pipes/currency-cent.pipe';

import { LangChanged } from '@shared/guards/lang-changed.guard';

registerLocaleData(localeFi, 'fi-FI');

@NgModule({
  bootstrap: [],
  declarations: [FormatDate, CurrencyCent],
  exports: [FormatDate, CurrencyCent],
  imports: [],
  providers: [LangChanged],
})
export class LocalesModule {}
