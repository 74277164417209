<button mat-icon-button matDialogClose><mat-icon>close</mat-icon></button>

<div class="z-fx">
  <div class="zef-promo-dialog-content">
    <div class="zef-text-d2">
      <ng-content select="[dialog-title]"></ng-content>
    </div>

    <div class="zef-promo-dialog-list zef-text-h3" [class.show-checkbox]="showCheckbox">
      <ng-content select="[dialog-list]"></ng-content>
    </div>

    <span class="z-fx-auto"></span>

    <div class="z-fx-col z-fx-gap-16">
      <button
        *ngIf="requiredPlan"
        class="zef-cta-button"
        cdkFocusInitial
        mat-raised-button
        matDialogClose
        color="ink"
        [routerLink]="'/plans/cart/create/' + requiredPlan"
      >
        <ng-content select="[dialog-button]"></ng-content>
      </button>

      <div class="z-fx-col z-fx-gap-8">
        <a *ngIf="requiredPlan" class="zef-text-link-flex zef-color-ink" [href]="pricingAddress" target="_blank">
          <span class="zef-text-p1">
            <ng-container *ngIf="requiredPlan === 'plan_genius'" i18n>Available starting GENIUS Plan</ng-container>
            <ng-container *ngIf="requiredPlan === 'plan_smart'" i18n>Available starting SMART Plan</ng-container>
          </span>
          <mat-icon class="zef-icon-small">new_tab</mat-icon>
        </a>

        <ng-content select="[dialog-footnote]"></ng-content>
      </div>
    </div>
  </div>

  <figure
    *ngIf="backgroundImage; else dialogImage"
    class="zef-promo-dialog-image"
    [style.background-image]="backgroundImage | encodeURI: 'url'"
  ></figure>

  <ng-template #dialogImage>
    <ng-content select="[dialog-image]"></ng-content>
  </ng-template>
</div>
