<div
  class="avatar-frame"
  [ngClass]="size"
  [class.admin]="emblem && Rights.hasRights(Rights.ADMIN, userRole$ | async)"
  [class.super]="emblem && (isZefAdmin$ | async)"
>
  <div
    class="avatar-image z-fx-sc"
    [style.background-size]="fit"
    [style.background-image]="identity?.avatar || 'assets/images/user-placeholder.png' | encodeURI: 'url' | safeStyle"
  >
    <div *ngIf="!!notifications" class="alert-bubble">
      {{ notifications }}
    </div>

    <mat-icon class="avatar-badge">zef_logo_console</mat-icon>
  </div>
</div>
