import { Component, Input, Output, EventEmitter, HostBinding, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'zef-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss'],
})
export class InlineEdit implements OnChanges {
  @Input()
  value?: string;

  @Input()
  class?: string;

  @Input()
  useClick?: boolean;

  @Input()
  tooltip?: string;

  @Input()
  placeholder?: string;

  @Output()
  readonly valueChange = new EventEmitter<string>();

  @HostBinding('class.editing')
  editing: boolean = false;

  private originalValue?: string | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.originalValue = this.value;
    }
  }

  onSubmit(value: string): void {
    this.value = value;
    this.editing = false;

    if (this.originalValue === null || this.originalValue !== value) {
      this.valueChange.emit(this.value);
    }
  }

  onEscape(event: KeyboardEvent): void {
    event.stopPropagation();
    this.editing = false;
  }
}
