import moment from 'moment';

import { CounterKey } from '@shared/models/recipient.model';
import { EmailData, MessageData } from '@shared/models/email.model';
import { SurveyInvite, SurveyMessage, SurveyReminder } from '@shared/models/survey-shares.model';

import { TextData } from '@shared/modules/email-composer/models/text-data.model';
import { BlockType } from '@shared/modules/email-composer/models/block-type.enum';
import { SurveyData } from '@shared/modules/email-composer/models/survey-data.model';
import { fallbackLinkProperties } from '@shared/enums/properties.enum';

export function isMessageSent(msg?: SurveyMessage): boolean {
  return !!(msg?.sending || ['sent', 'sending'].includes(msg?.status));
}

export function isMessageScheduled(msg?: MessageData): boolean {
  return !!(msg?.scheduledAt && !msg?.sending);
}

export function isMessageAutomated(msg?: MessageData): boolean {
  return msg?.status === 'automated';
}

export function isParentAutomated(msg?: SurveyMessage): boolean {
  return isMessageReminder(msg) && !!msg.parentItem?.automation;
}

export function getNextReminderSchedule(msg?: SurveyReminder): number {
  const date = moment(
    Math.max(
      msg?.parentItem?.scheduledAt || 0,
      ...(msg?.parentItem?.reminders.map((reminder) => reminder.scheduledAt || 0) || [0]),
    ) || Date.now(),
  ).add(3, 'd');

  if (date.isBefore()) {
    return moment(Date.now()).add(1, 'd').valueOf();
  } else {
    return date.valueOf();
  }
}

export function getNextInviteSchedule(): number {
  return moment(Date.now()).add(1, 'd').valueOf();
}

export function getParentItem(msg?: SurveyMessage): SurveyInvite | undefined {
  return isMessageReminder(msg) ? msg.parentItem : ((msg?.masterItem || msg) as SurveyInvite);
}

export function hasListsSelected(msg?: SurveyMessage): boolean {
  return msg?.recipientsLists?.length > 0 || msg?.externalLists?.length > 0 || false;
}

export function hasReminders(msg?: SurveyMessage): boolean {
  return isMessageInvite(msg) && !!msg.reminders?.length;
}

export function hasRemindersScheduled(msg?: SurveyMessage): boolean {
  return isMessageInvite(msg) && !!msg.reminders?.some((reminder) => reminder.scheduledAt);
}

export function isMessageDraft(msg?: SurveyMessage): boolean {
  return !!msg && !msg.sending && !msg.scheduledAt && !msg.automation;
}

export function isTwoSteps(msg?: SurveyMessage): boolean {
  return !!(msg?.totalContacts > msg?.typeContacts || msg.slave || msg.master);
}

export function isMessageInvite(msg?: SurveyMessage): msg is SurveyInvite {
  return !!(msg && !msg.parent);
}

export function isMessageReminder(msg?: SurveyMessage): msg is SurveyReminder {
  return !!msg?.parent;
}

export function getDayDifference(schedule: number, prevSchedule: number, withRounding?: boolean): number | undefined {
  if (schedule && prevSchedule && schedule > prevSchedule) {
    if (withRounding) {
      return moment(schedule).diff(moment(prevSchedule), 'd');
    } else {
      const scheduleDate = new Date(schedule);
      const prevDate = new Date(prevSchedule);

      if (scheduleDate.getHours() === prevDate.getHours() && scheduleDate.getMinutes() === prevDate.getMinutes()) {
        return Math.round((schedule - prevSchedule) / 1000 / 60 / 60 / 24) || 0;
      }
    }
  }
}

export function getPreviousMessage(msg?: SurveyMessage): SurveyMessage | undefined {
  msg = msg?.masterItem || msg;

  if (isMessageReminder(msg) && msg.parentItem) {
    let previous: SurveyMessage;

    for (const item of [msg.parentItem, ...(msg.parentItem.reminders || [])]) {
      if (item.$key === msg.$key) {
        return previous;
      }

      previous = item;
    }
  }
}

export function getAllMessages(msg?: SurveyMessage): SurveyMessage[] {
  return msg
    ? [
        msg,
        ...(msg.slaveItem ? [msg.slaveItem] : []),
        ...(isMessageInvite(msg) && !msg.master ? msg.reminders || [] : []).reduce(
          (a, b) => [...a, ...[b, ...(b.slaveItem ? [b.slaveItem] : [])]],
          [],
        ),
      ]
    : [];
}

export function createMessageUpdates(
  msg?: SurveyMessage,
  update: Partial<SurveyMessage> = {},
): { key: string; update: Partial<SurveyMessage> }[] {
  return (msg && [{ key: msg.$key, update }, ...(msg.master ? [{ key: msg.master, update }] : [])]) || [];
}

export function applyMessageUpdates(
  msg: SurveyMessage,
  updates: { key: string; update: Partial<SurveyMessage> }[] = [],
): SurveyMessage {
  const findUpdate = (item?: SurveyMessage): Partial<SurveyMessage> | undefined => {
    if (!item) {
      return;
    }

    const update = updates.find(({ key }) => key === item.$key)?.update;

    if (!update) {
      return;
    }

    Object.keys(update).forEach((updateKey) => {
      const updateValue = update[updateKey];

      if (updateValue && typeof updateValue === 'object' && typeof item[updateKey] === 'object') {
        update[updateKey] = {
          ...item[updateKey],
          ...updateValue,
        };
      }
    });

    return update;
  };

  const applyMessageUpdate = <T extends SurveyMessage>(item?: T): T | undefined =>
    item && { ...item, ...(findUpdate(item) || {}) };

  const applyUpdate = <T extends SurveyMessage>(item: T) => {
    item = applyMessageUpdate(item);
    item.masterItem = applyMessageUpdate(item.masterItem);

    return item;
  };

  msg = applyUpdate(msg);

  if (isMessageReminder(msg)) {
    msg.parentItem = applyUpdate(msg.parentItem);

    if (msg.parentItem) {
      msg.parentItem.reminders = msg.parentItem.reminders?.map((reminder) => applyUpdate(reminder));
    }
  }

  if (isMessageInvite(msg)) {
    msg.reminders = msg.reminders?.map((reminder) => applyUpdate(reminder));
  }

  return msg;
}

const statuses: CounterKey[] = ['recipients', 'delivered', 'opened', 'clicked', 'started', 'completed'];

export function getMessageCounterSteps(msg?: SurveyMessage): CounterKey[] {
  const parent = getParentItem(msg);

  return parent ? statuses : [];
}

export function getMessageFunnelCount(msg?: SurveyMessage): number {
  if (!msg) {
    return 0;
  }

  let count = 0;

  for (const status of statuses) {
    const isInFunnel = isStepInFunnel(status, [msg.scheduledFromStatus, msg.scheduledToStatus]);

    if (isInFunnel && !count) {
      count = msg.counter?.[status] || 0;
    } else if (!isInFunnel && count) {
      return count - (msg.counter?.[status] || 0);
    }
  }

  return count;
}

export function isStepInFunnel(step: CounterKey, funnel: CounterKey[]): boolean {
  if (!step || !funnel.length) {
    return false;
  }

  const funnelFromIdx = statuses.includes(funnel[0]) ? statuses.indexOf(funnel[0]) : statuses.length - 1;
  const funnelToIdx = statuses.includes(funnel[1]) ? statuses.indexOf(funnel[1]) : 0;

  const stepIdx = statuses.indexOf(step);

  return funnelFromIdx <= stepIdx && funnelToIdx >= stepIdx;
}

export function getOtherMessage<T extends SurveyMessage>(msg?: T): T | undefined {
  return (msg?.masterItem || msg?.slaveItem) as T;
}

export function getNextReminders(msg: SurveyReminder): SurveyReminder[] {
  const idx = msg.parentItem.reminders.findIndex(({ $key }) => msg.$key === $key);

  return idx > -1 ? msg.parentItem.reminders.slice(idx) : [];
}

export function setTotalContacts(invite: SurveyInvite): void {
  const other = getOtherMessage(invite);

  const getTypeCount = (subj: SurveyMessage) => {
    const other2 = getOtherMessage(invite);

    if (subj.status !== 'sending' && subj.sendingStatus) {
      return subj.sendingStatus.count || 0;
    } else if (subj.status === 'automated' && other2?.sendingStatus) {
      return 0;
    } else {
      return subj.typeContacts || 0;
    }
  };

  invite.typeContacts = getTypeCount(invite);

  if (other) {
    other.typeContacts = getTypeCount(other);
  }

  invite.totalContacts =
    invite.sending || invite.status === 'automated'
      ? Math.max(invite.typeContacts + (other?.typeContacts || 0), invite.totalContacts)
      : invite.totalContacts;

  if (other) {
    other.totalContacts = invite.totalContacts;
  }

  const getReminderTypeCount = (reminder: SurveyReminder) => {
    if (reminder.sendingStatus && reminder.status !== 'sending') {
      return reminder.sendingStatus.count;
    }

    if (reminder.processType !== 'automate' && reminder.typeContacts) {
      return reminder.typeContacts;
    }

    const parent = invite.kind === reminder.kind ? invite : other;

    if (!parent) {
      return invite.totalContacts - invite.typeContacts;
    }

    return parent.typeContacts || 0;
  };

  const getReminderTotalCount = (reminder: SurveyReminder) =>
    reminder.processType !== 'automate' ? reminder.totalContacts : reminder.parentItem.totalContacts;
  invite.reminders.forEach((reminder) => {
    reminder.typeContacts = getReminderTypeCount(reminder);
    reminder.totalContacts = getReminderTotalCount(reminder);

    const otherReminder = getOtherMessage(reminder);

    if (otherReminder) {
      otherReminder.typeContacts = getReminderTypeCount(otherReminder);
      otherReminder.totalContacts = getReminderTotalCount(otherReminder);
    }
  });
}

export function setAutomationDayDiffToUi(invite: SurveyInvite): void {
  let prev: SurveyMessage = invite;

  invite.reminders
    .filter((reminder) => !!reminder.automation)
    .forEach((reminder) => {
      reminder.automation = {
        ...reminder.automation,
      };

      if (reminder.automation.sendAfterDays) {
        reminder.automation.sendAfterDaysFromPrev =
          reminder.automation.sendAfterDays - (prev.automation.sendAfterDays || 0);
      }

      prev = reminder;
    });
}

export function getAutomationDayDiffToData(reminder: SurveyMessage, dayDiff: number): number | undefined {
  if (!isMessageReminder(reminder) || !dayDiff) {
    return dayDiff;
  }

  const prev = getPreviousMessage(reminder);

  if (prev) {
    dayDiff += prev.automation?.sendAfterDays || 0;
  }

  return dayDiff;
}

export function isValidEmail(msg?: EmailData): boolean {
  if (!msg || msg.kind !== 'email') {
    return false;
  }

  if (!msg.survey) {
    return true;
  }

  return !!msg.template?.some(
    (block: SurveyData & TextData) =>
      (block.type === BlockType.Survey && block.options && block.options.url && block.options.url !== 'https://') ||
      (block.type === BlockType.Text && block.text && fallbackLinkProperties.some((pp) => block.text.includes(pp))),
  );
}
