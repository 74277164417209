import { timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'zef-spinner-ai-attention',
  templateUrl: 'spinner-ai-attention.component.html',
  styleUrl: './spinner-ai-attention.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerAiAttentionComponent {
  readonly state$ = timer(0, 500).pipe(map((time) => `state-${time % 3}`));
}
