import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DragZoneComponent } from '@shared/modules/drag-zone/drag-zone.component';

@NgModule({
  imports: [CommonModule],
  declarations: [DragZoneComponent],
  exports: [DragZoneComponent],
})
export class DragZoneModule {}
