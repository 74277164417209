import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SurveysManager } from '@shared/services/surveys-manager.service';
import { ActionsState } from '@shared/states/actions.state';
import { GoToSignUp } from '@shared/states/auth.actions';
import { TryFree } from '@shared/states/billing.actions';
import { AddPlan } from '@shared/states/cart.actions';
import { CreateSurveyInvite } from '@shared/states/survey-shares.actions';
import { CreateSurvey, GetSurveys } from '@shared/states/survey.actions';
import { CreateTemplate, UpdateTemplate } from '@shared/states/templates.actions';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';
import { filter, map, mapTo, scan, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GlobalSpinnerService {
  readonly startSpin$ = new Subject<void>();

  readonly stopSpin$ = new BehaviorSubject<void>(void 0);

  readonly toggleSpin$ = new Subject<boolean>();

  /* eslint-disable @typescript-eslint/no-shadow */
  readonly addSelect$ = new Subject<(...args: any) => boolean | ((...args: any) => boolean)[]>();
  /* eslint-enable @typescript-eslint/no-shadow */

  private readonly actions = [
    AddPlan,
    TryFree,
    GetSurveys,
    GoToSignUp,
    CreateSurveyInvite,
    CreateSurvey,
    CreateTemplate,
    UpdateTemplate,
  ];

  private readonly selects: ((...args: any) => boolean)[] = [ActionsState.whileAction(this.actions)];

  private readonly selects$: Observable<boolean> = this.addSelect$.pipe(
    startWith(this.selects),
    scan((a, b) => {
      const selects = Array.isArray(b) ? b : [b];

      selects.forEach((select) => {
        if (!a.includes(select)) {
          a.push(select);
        }
      });

      return a;
    }, []),
    switchMap((selects) => combineLatest(selects.map((select) => this.store.select(select)))),
    map(([...flags]: boolean[]) => flags.some(Boolean)),
  );

  readonly appLoadComplete$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    take(1),
    tap(() => {
      // doing it this way so there is no intermediate white page because of the APP_INITIALIZER
      const initLogoSpinner = document.querySelector('.zef-app-loader__init__');

      initLogoSpinner?.parentElement.removeChild(initLogoSpinner);
    }),
    shareReplay(1),
  );

  readonly showSpinner$: Observable<boolean> = combineLatest([
    this.selects$,
    this.router.events.pipe(
      filter((e) => e instanceof NavigationStart),
      switchMap(() =>
        this.router.events.pipe(
          filter((e) => e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError),
          take(1),
          mapTo(false),
          startWith(true),
        ),
      ),
      startWith(false),
    ),
    merge(this.startSpin$.pipe(mapTo(true)), this.stopSpin$.pipe(mapTo(false)), this.toggleSpin$),
    this.sm.creatingSurvey,
  ]).pipe(
    map(([...flags]: boolean[]) => flags.some((flag) => flag)),
    switchMap((show) => this.appLoadComplete$.pipe(mapTo(show))),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  constructor(
    private router: Router,
    private store: Store,
    private sm: SurveysManager,
  ) {}
}
