import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { HideShow } from '@shared/components/banner/banner.animations';

@Component({
  selector: 'zef-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [HideShow],
})
export class Banner {
  @Input() closeButton: boolean = true;
  @Input() foreground: boolean = false;
  @Input() height: number | null = null;

  @HostBinding('@show') @Input() show: boolean = true;

  @HostBinding('class.zef-secondary') @Input() secondaryStyle: boolean;

  @Output() close: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() {}

  onCloseClick(event: MouseEvent): void {
    this.show = false;

    this.close.emit(event);
  }
}
