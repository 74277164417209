import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { Component, ChangeDetectionStrategy, Input, EventEmitter, ViewChild, Output } from '@angular/core';

import { TimeValue } from '@shared/models/utility.model';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { ZefThemePalette } from '@shared/models/color.model';

@Component({
  selector: 'zef-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss'],
  providers: [LifecycleHooks],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTime {
  @ViewChild(MatInput, { static: true })
  input: MatInput;

  @Input()
  placeholder: string = '00:00';

  @Input()
  color: ZefThemePalette = 'ink-lightest';

  @Input()
  space?: boolean;

  @Input()
  clearable?: boolean;

  @Input()
  set value(time: TimeValue) {
    this._value = time;
    this.setInputFromValue(time);
  }

  private _value?: TimeValue;

  @Output()
  readonly valueChange = new EventEmitter<TimeValue | undefined>();

  updateTime(): void {
    let time = this.input.value;
    time = (time || '').replace(/[^\d]/g, '').substr(0, 4);

    if (this.clearable && !time) {
      this.clearTime();
    } else {
      if (time.length === 1) {
        time = `0${time}00`;
      } else if (time.length === 3) {
        time = `0${time}`;
      } else if (time.length < 3) {
        time = time.padEnd(4, '0');
      }

      let hour = Math.abs(Number.parseInt(time.substr(0, 2), 10) || 0);
      let minute = Math.abs(Number.parseInt(time.substr(2, 2), 10) || 0);

      if (hour > 24 || (hour === 24 && minute)) {
        hour %= 24;
      }

      if (minute >= 60) {
        minute %= 60;
      }

      this.setInputFromValue({ hour, minute });

      if (this._value?.hour !== hour || this._value.minute !== minute) {
        this.valueChange.emit({ hour, minute });
      }
    }
  }

  clearTime(): void {
    this.setInputFromValue();
    this.valueChange.emit();
  }

  private setInputFromValue(time?: TimeValue): void {
    if (time?.hour == null || time?.minute == null) {
      this.input.value = null;
    } else {
      this.input.value = `${time.hour.toString().padStart(2, '0')}:${time.minute.toString().padStart(2, '0')}`;
    }
  }
}
