import Step from 'shepherd.js/src/types/step';

import { StateToken } from '@ngxs/store';

import { HelpGuide, HelpSubject } from '@shared/modules/help-center/help-subject.enum';

export const HelpSubjects = Object.values(HelpSubject);

export const HELP_CENTER_STATE = new StateToken<HelpCenterStateModel>('helpCenter');

export type HelpItemSubject = HelpSubject | (typeof HelpSubjects)[0];

export type HelpCenterLanguage = 'en' | 'fi';

export const HelpCenterToolTipMaxChar = 160;

export enum HelpCenterMode {
  Read,
  Preview,
  Edit,
}

export enum HelpCenterItemStatus {
  Missing,
  Partial,
  Complete,
}

export interface HelpCenterItemData {
  title?: string;
  quickTip?: string;
  externalLink?: string;
  article?: string;
  parsedArticle?: string;
  hasParentData?: boolean;
}

export interface HelpCenterItemStatistics {
  hovers: number;
  clicks: number;
  externalClicks: number;
}

export interface HelpCenterItem {
  subject?: HelpSubject;
  status?: HelpCenterItemStatus;
  data?: Record<HelpCenterLanguage, HelpCenterItemData>;
  stats?: Record<HelpCenterLanguage, HelpCenterItemStatistics>;
}

export interface HelpGuideStep {
  // https://shepherdjs.dev/docs/Step.html
  cancelIcon: Step.StepOptionsCancelIcon;
  attachTo: Step.StepOptionsAttachTo;
  buttons: any[];
  classes?: string;
  scrollTo?: boolean;
  highlightClass: string;
  id: string;
  title: Record<HelpCenterLanguage, string>;
  text: Record<HelpCenterLanguage, string>;
}

export interface HelpCenterGuide {
  subject: HelpGuide;
  online: boolean;
  replay: number;
  title: Record<HelpCenterLanguage, string>;
  steps: HelpGuideStep[];
}

export type HelpCenterItems = Partial<Record<HelpSubject, HelpCenterItem>>;

export interface HelpCenterStateModel {
  mode?: HelpCenterMode;
  items: HelpCenterItems;
  articles?: HelpSubject[];
  guides?: HelpGuide[];
  language?: HelpCenterLanguage;
  selectedTeam?: string;
}
