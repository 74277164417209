/**
 * Service for handling contacts.
 *
 * @unstable
 */

import { environment } from '@env/environment';

import { combineLatest, forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';

import { catchError, distinctUntilChanged, filter, map, mapTo, switchMap, take, tap, timeout } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { Injectable } from '@angular/core';
import {
  ColumnType,
  ContactColumn,
  ContactEntryData,
  ContactFieldsData,
  ContactsDataResponse,
  ContactsItemResponse,
  ContactsListData,
  ContactsListResponse,
  ContactSourceType,
  ContactsSearchParams,
  FbContactsColumn,
  FbContactsImport,
  FbContactsImportColumns,
  FbContactsImportOptions,
  ImportColumnData,
  UniqueContactsInfoResponse,
  UniqueContactsResponse,
  UserContactsImport,
} from '@shared/models/contact.model';
import { AccountState } from '@shared/states/account.state';

import { PrefsManager } from '@shared/services/prefs-manager.service';
import { ZefApi } from '@shared/services/zef-api.service';
import { excludeByKeyList, firebaseSafe, jsonSafe, pickBy, pickByKeyList } from '@shared/utilities/object.utilities';

import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { AuthState } from '@shared/states/auth.state';
import { MessageExternalList } from '@shared/models/email.model';
import { DisplaySnackbar } from '@shared/states/dialog.actions';
import { shareRef } from '@shared/operators/share-ref.operator';
import { SnapshotAction } from '@angular/fire/compat/database';
import { UpdateImportSource } from '@shared/states/contacts.actions';
import { assertArray } from '@shared/utilities/array.utilities';

interface ExportData {
  $key: string;
  created: Date;
  format: string;
  name: string;
  status: string;
  type: string;
  downloadToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContactsManager {
  public entryColumns: ContactColumn[] = [
    { $key: 'email', name: 'Email', type: 'email', editable: true, visible: true, required: true },
    { $key: 'phone', name: 'Phone', type: 'phone', editable: true, visible: true, required: true },
    { $key: 'name', name: 'Name', type: 'text', editable: true, visible: true, required: true },
    { $key: 'firstName', name: 'First name', type: 'text', editable: true, visible: true, required: true },
    { $key: 'lastName', name: 'Last name', type: 'text', editable: true, visible: true, required: true },
    { $key: 'created', name: 'Created', type: 'date', editable: false, visible: true, required: true },
    { $key: 'modified', name: 'Modified', type: 'date', editable: false, visible: true, required: true },
    { $key: 'source', name: 'Source', type: 'text', editable: false, visible: true, required: true },
    { $key: 'creator', name: 'Creator', type: 'text', editable: false, visible: true, required: true },
    { $key: 'creatorName', name: '', type: 'auto', editable: false, visible: false, required: false },
    { $key: 'collectionMethod', name: '', type: 'auto', editable: false, visible: false, required: false },
    { $key: 'photo', name: '', type: 'auto', editable: false, visible: false, required: false },
    { $key: 'thumb', name: '', type: 'auto', editable: false, visible: false, required: false },
  ];

  public fieldTypes: { name: string; value: ColumnType }[] = [
    { name: $localize`:column type name@@zef-i18n-00022:Text type`, value: 'text' },
    { name: $localize`:column type name@@zef-i18n-00023:Email type`, value: 'email' },
    { name: $localize`:column type name@@zef-i18n-00024:Phone number`, value: 'phone' },
    { name: $localize`:column type name@@zef-i18n-00025:Numeric type`, value: 'numeric' },
    { name: $localize`:column type name@@zef-i18n-00025-dt:Date type`, value: 'date' },
  ];

  private readonly importDone$ = new Subject<string>();

  constructor(
    private db: DatabaseWrapper,
    private pm: PrefsManager,
    private za: ZefApi,
    private store: Store,
  ) {}

  getColumnTypeName(type: ColumnType): string {
    return this.fieldTypes.find(({ value }) => value === type)?.name || '';
  }

  private searchParams(params: Partial<ContactsSearchParams>) {
    return pickBy(
      {
        start: params.start,
        rows: 'count' in params ? params.count : null,
        contactList: 'listId' in params ? { id: params.listId } : null,
        search: params.search ? '*' + params.search + '*' : null,
        sort: params.sort,
        asc: params.order !== 'desc',
        ids: params.ids,
        creators: params.creator,
        owner: params.owner || null,
        onlyIds: params.onlyIds,
        includedMembers: params.includedMembers,
      },
      (val) => val != null,
    );
  }

  public dbChanges(teamKey?: string) {
    teamKey = teamKey || this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<number>(`/statuses/licenses/contacts/${teamKey}/time`)
      .valueChanges()
      .pipe(
        distinctUntilChanged(),
        catchError(() => of(null)),
      );
  }

  public getSortedContacts(search: Partial<ContactsSearchParams>): Observable<ContactsItemResponse> {
    search.type = 'item';

    return this.getContacts(search).pipe(
      map((response) => ({
        ...response,
        result: response.result.map((item) => ({
          ...item.entry,
          ...excludeByKeyList(
            item.fields,
            this.entryColumns.map((col) => col.$key).filter((key) => !!item.entry?.[key]),
          ),
        })),
      })),
    );
  }

  public getContacts(params: Partial<ContactsSearchParams>): Observable<ContactsDataResponse> {
    const isAnonymous = this.store.selectSnapshot(AuthState.isAnonymous);

    return isAnonymous ? this.anonymousContacts() : this.authorizedContacts(params);
  }

  private anonymousContacts() {
    return of({
      startIndex: 0,
      totalCount: 0,
      result: [],
    });
  }

  private authorizedContacts(params: Partial<ContactsSearchParams>) {
    const data = this.searchParams(params);

    return this.za.get<ContactsDataResponse>(`contact/search?data=${encodeURIComponent(JSON.stringify(data))}`).pipe(
      timeout(300000),
      // map((response) => ({
      //   startIndex: 0,
      //   totalCount: 5,
      //   result: response.result.slice(0, 5)
      // })),
      catchError(() => of({ startIndex: 0, totalCount: 0, result: [] })),
    );
  }

  public saveContact(contact: Partial<ContactEntryData & ContactFieldsData>, list?: number) {
    const entryKeys = this.entryColumns.map(({ $key }) => $key);

    const entry = pickByKeyList(contact, entryKeys);
    const fields = excludeByKeyList(contact, entryKeys);

    Object.keys(entry).forEach((key) => {
      if (typeof entry[key] === 'string' && entry[key].trim() === '') {
        entry[key] = null;
      }
    });

    delete fields.id;

    if (contact.id) {
      entry.id = contact.id;
    }

    const payload = {
      list: list ? { id: list } : null,
      entry: jsonSafe(entry),
      fields,
    };

    return contact.id ? this.za.put('contact/entry', payload) : this.za.post('contact/entry', payload);
  }

  public deleteContacts(ids: number[] = [], all: boolean = false): Observable<boolean> {
    const payload = { contacts: ids, all };

    return this.za.delete(`contact/entry`, payload).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  public getContactIds(search: string, listId?: number): Observable<number[]> {
    const data = this.searchParams({
      search,
      ...(listId != null ? { listId } : {}),
      onlyIds: true,
    });

    return this.za.get<{ result: number[] }>(`contact/search`, { data: JSON.stringify(data) }).pipe(
      map((response) => response?.result || []),
      catchError(() => of([])),
    );
  }

  public exportContacts(fileName: string, list?: number): Observable<void> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.za.post(`contact/export`, list ? { list: { id: list } } : null).pipe(
      switchMap((response: any) =>
        this.db
          .object(`/exports/${teamKey}/${userKey}/${response.export.key}`)
          .snapshotChanges()
          .pipe(map((object: any) => ({ $key: object.key, ...object.payload.val() }))),
      ),
      filter((data: ExportData) => data.status === 'ready' && !!data.downloadToken),
      take(1),
      map((exportData) => {
        window.open(
          `https:${environment.apiServer}/contact/export/download/` +
            `${exportData['$key']}?token=${exportData.downloadToken}&team=${teamKey}&user=${userKey}&name=` +
            encodeURIComponent(fileName),
        );
      }),
    );
  }

  public getImportData(fileName: string, data: string, options: FbContactsImportOptions): Observable<ImportColumnData> {
    const payload = {
      data,
      file: fileName,
      settings: {
        options,
      },
      rowsToPreview: 6,
    };

    return this.za.post<ImportColumnData>(`contact/preview`, payload).pipe(
      timeout(300000),
      catchError((error) => throwError(error)),
    );
  }

  public getUserImports(): Observable<UserContactsImport[]> {
    return combineLatest([this.store.select(AccountState.teamKey), this.store.select(AccountState.userKey)]).pipe(
      filter(([teamKey, userKey]) => !!teamKey && !!userKey),
      switchMap(([teamKey, userKey]) =>
        this.db
          .list<FbContactsImport>(`/imports/${teamKey}`, (ref) => ref.orderByChild('user').equalTo(userKey))
          .snapshotChanges()
          .pipe(
            catchError(() => of([])),
            map((refs: SnapshotAction<FbContactsImport>[]) =>
              (refs || []).filter((ref) => ref.payload.exists).map((ref) => ({ ...ref.payload.val(), $key: ref.key })),
            ),
          ),
      ),
    );
  }

  public getImportStatus(importKey: string): Observable<{ success: number; active: boolean } | null> {
    return this.store.select(AccountState.teamKey).pipe(
      switchMap((teamKey) =>
        this.db
          .object<{ success: number; active: boolean }>(`/statuses/contacts/imports/${teamKey}/${importKey}`)
          .valueChanges()
          .pipe(catchError(() => of(null))),
      ),
    );
  }

  public removeUserImport(importKey: string): Observable<void> {
    this.importDone$.next(importKey);

    return this.store
      .selectOnce(AccountState.teamKey)
      .pipe(switchMap((teamKey) => this.db.object(`/imports/${teamKey}/${importKey}`).remove()));
  }

  public uploadContacts(file: File): Observable<void> {
    const formData: FormData = new FormData();

    formData.append('contacts', file, file.name);

    return this.za.upload(`upload/contacts/${file.name}`, formData).pipe(
      tap((progress) =>
        this.store.dispatch(
          new UpdateImportSource({
            error: !!progress.error,
            errorMessage: progress.error,
            progress: progress.progress,
          }),
        ),
      ),
      filter((progress) => progress.complete),
      mapTo(void 0),
    );
  }

  public createImport(
    list: ContactsListData | null,
    options: FbContactsImportOptions,
    columns: FbContactsImportColumns[],
    invite?: string,
    inviteKind?: 'email' | 'sms',
  ): Observable<{ importKey: string; listId?: number }> {
    return this.startImport(list, options, columns, invite, inviteKind);
  }

  private startImport(
    list: ContactsListData | undefined,
    options: FbContactsImportOptions,
    columns: FbContactsImportColumns[],
    invite?: string,
    inviteKind?: 'email' | 'sms',
  ): Observable<{ importKey: string; listId?: number }> {
    this.store.dispatch(
      new DisplaySnackbar($localize`:snackbar notification@@zef-i18n-00018:Importing contacts...`, { timeout: 500000 }),
    );

    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    columns.forEach((column: FbContactsImportColumns) => {
      if (!column.key && !column.skipped) {
        column.visible = true;
        column.key = this.db.list<FbContactsColumn>(`/contacts/${teamKey}/columns/`).push(column).key;
      }
    });

    const list$: Observable<ContactsListData | null> = list && !list.id ? this.createContactList(list) : of(list);

    return list$.pipe(
      switchMap((newList) =>
        from(
          this.db.list<FbContactsImport>(`/imports/${teamKey}`).push(
            firebaseSafe({
              list: newList && { id: newList.id, name: newList.name },
              options,
              columns,
              invite,
              inviteKind,
              user: this.store.selectSnapshot(AccountState.userKey),
            }),
          ),
        ).pipe(
          map((ref) => ({
            importKey: ref.key,
            listId: newList?.id,
          })),
        ),
      ),
      shareRef(),
    );
  }

  public syncImportContacts(
    fileName: string,
    data: string,
    list: ContactsListData,
    options: FbContactsImportOptions,
    columns: FbContactsImportColumns[],
    invite?: string,
    inviteKind?: 'email' | 'sms',
  ): Observable<void> {
    return this.startImport(list, options, columns, invite, inviteKind).pipe(
      switchMap(({ importKey }) =>
        this.importContacts(fileName, data, importKey).pipe(
          switchMap(() =>
            this.importDone$.pipe(
              filter((done) => done === importKey),
              take(1),
              mapTo(void 0),
            ),
          ),
        ),
      ),
    );
  }

  public importContacts(fileName: string, data: string, importKey: string): Observable<void> {
    const payload = {
      data,
      file: fileName,
      settings: {
        key: importKey,
      },
    };

    return this.za.post<void>(`contact/import`, payload).pipe(timeout(300000));
  }

  importContactsFromCopyPaste(
    listName: string,
    copyPaste: string,
    repairCode?: string,
    invite?: string,
    inviteKind?: 'email' | 'sms',
  ): Observable<void> {
    if (!copyPaste) {
      return of(void 0);
    }

    const options: FbContactsImportOptions = {
      encoding: 'utf-8',
      textDelimiter: ',',
      firstRowHasHeaders: false,
      repeatingDelimsAsOne: true,
      repairCode: repairCode?.toLocaleUpperCase() || null,
    } as FbContactsImportOptions;

    const emailCol = this.entryColumns.find((col) => col.$key === 'email');
    const phoneCol = this.entryColumns.find((col) => col.$key === 'phone');

    const matchedColumns: FbContactsImportColumns[] = [
      { key: emailCol.$key, name: emailCol.name, type: emailCol.type, skipped: false, visible: true },
      { key: phoneCol.$key, name: phoneCol.name, type: phoneCol.type, skipped: false, visible: true },
    ];

    return this.syncImportContacts(
      void 0,
      copyPaste,
      { name: listName, source: ContactSourceType.CopyPaste } as ContactsListData,
      options,
      matchedColumns,
      invite,
      inviteKind,
    );
  }

  public getContactColumnSample(column: ContactColumn, sampleSize: number): Observable<string[]> {
    if (!column || column.added) {
      return of([]);
    }

    return this.za.get<string[]>(`contact/sample/${column.$key}`, { sampleSize }).pipe(
      map((sample) => sample || []),
      catchError(() => of([])),
    );
  }

  public getUniqueCount(
    lists: number[],
    contacts: number[],
    externalLists: MessageExternalList[],
  ): Observable<UniqueContactsResponse> {
    const emptyResponse: UniqueContactsResponse = {
      uniqueContacts: contacts.length,
      uniqueContactsInLists: 0,
      uniqueLockedContactsInLists: 0,
    };

    if (lists.length === 0 && externalLists.length === 0) {
      return of(emptyResponse);
    }

    return this.za
      .post<UniqueContactsResponse>('contact/uniqueCounts', { lists, contacts, externalLists })
      .pipe(catchError(() => of(emptyResponse)));
  }

  public getUniqueContactInfos(
    contacts: number[],
    lists: number[],
    externalLists: MessageExternalList[],
  ): Observable<UniqueContactsInfoResponse> {
    const emptyResponse: UniqueContactsInfoResponse = {
      uniqueEmails: 0,
      uniquePhones: 0,
      uniqueContacts: 0,
      uniqueLockedContacts: 0,
    };

    if (!lists?.length && !contacts?.length && !externalLists?.length) {
      return of(emptyResponse);
    }

    return this.za
      .post<UniqueContactsInfoResponse>('contact/uniqueContactInfos', {
        lists: Array.isArray(lists) ? lists : [],
        contacts: Array.isArray(contacts) ? contacts : [],
        externalLists: Array.isArray(externalLists) ? externalLists : [],
      })
      .pipe(catchError(() => of(emptyResponse)));
  }

  public getContactLists(params: Partial<ContactsSearchParams>): Observable<ContactsListResponse> {
    const isAnonymous = this.store.selectSnapshot(AuthState.isAnonymous);

    return isAnonymous ? this.anonymousContactLists() : this.authorizedContactLists(params);
  }

  public getContactList(listId: number): Observable<ContactsListData> {
    return this.za
      .get<ContactsListResponse>(`contact/list`, { ids: listId })
      .pipe(map((response) => response.result?.[0]));
  }

  private anonymousContactLists(): Observable<ContactsListResponse> {
    return of({
      startIndex: 0,
      totalCount: 1,
      result: [{ name: 'Sales & Marketing', contactCount: 10 }],
    } as ContactsListResponse);
  }

  private authorizedContactLists(params: Partial<ContactsSearchParams>): Observable<ContactsListResponse> {
    const data = this.searchParams(params);

    return this.za.get<ContactsListResponse>(`contact/list?data=${encodeURIComponent(JSON.stringify(data))}`).pipe(
      timeout(300000),
      catchError(() => of({ startIndex: 0, totalCount: 0, result: [] } as ContactsListResponse)),
      map((response) => ({
        ...response,
        result: response.result.map((list) => ({
          ...list,
          updated: list.updated || list.created,
        })),
      })),
    );
  }

  public createContactList(
    list: Partial<ContactsListData>,
    members: number[] = [],
    all: boolean = false,
  ): Observable<ContactsListData> {
    delete list.id;

    list = {
      ...list,
      creator: this.store.selectSnapshot(AccountState.userKey),
      owner: this.store.selectSnapshot(AccountState.userKey),
    };

    return this.za.post<ContactsListData>(`contact/members`, { list, all, members });
  }

  public updateContactList(list: Partial<ContactsListData> & Pick<ContactsListData, 'id'>): Observable<void> {
    return this.za.put(`contact/list`, { list });
  }

  public deleteContactLists(ids: number[] = []): Observable<boolean> {
    const payload = { lists: ids };

    return this.za.delete(`contact/list`, payload).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  public removeContactsFromList(contacts: number[], listId: number, all: boolean = false) {
    const payload = {
      list: {
        id: listId,
      },
      members: contacts,
      all,
    };

    return this.za.delete('contact/members', payload);
  }

  public addContactsToLists(contacts: number[], lists: number[], all: boolean = false) {
    return this.za.post(`contact/members`, {
      members: contacts,
      lists,
      all,
    });
  }

  public listContactsColumns(): Observable<ContactColumn[]> {
    const isAnonymous = this.store.selectSnapshot(AuthState.isAnonymous);

    if (isAnonymous) {
      return of([
        {
          $key: 'role',
          name: 'Role',
          type: 'text',
        },
        {
          $key: 'team',
          name: 'Team',
          type: 'text',
        },
      ]);
    } else {
      const teamKey = this.store.selectSnapshot(AccountState.teamKey);

      return this.db
        .list<ContactColumn>(`/contacts/${teamKey}/columns/`)
        .snapshotChanges()
        .pipe(
          map((list) =>
            assertArray(list).map((object) => ({
              editable: true,
              ...object.payload.val(),
              visible: true,
              order: null,
              $key: object.key,
            })),
          ),
          catchError(() => of([])),
          shareRef(),
        );
    }
  }

  public setContactsColumns(columnChanges: ContactColumn[]): Observable<{ oldKey: string; newKey: string }[]> {
    if (!columnChanges.length) {
      return of([]);
    }

    const userPrefs: (keyof ContactColumn)[] = ['visible', 'order'];

    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const list = this.db.list<ContactColumn>(`/contacts/${teamKey}/columns/`);

    return forkJoin(
      columnChanges.map((column) => {
        column = { ...column };
        const updates: Promise<any>[] = [];
        const { added, deleted, $key } = column;

        delete column.added;
        delete column.deleted;
        delete column.$key;
        delete column.integration;
        delete column.itemLink;

        if (!column.required) {
          const prefLessUpdate = { ...column };

          userPrefs.forEach((pref) => delete prefLessUpdate[pref]);

          if (added) {
            updates.push(
              list.push(column).then(({ key }) => ({
                oldKey: $key,
                newKey: key,
              })),
            );
          } else if (deleted) {
            updates.push(list.remove($key));
          } else {
            updates.push(list.update($key, column));
          }
        }

        const update = {
          ...(column.order != null ? { order: column.order } : {}),
          ...(column.visible != null ? { visible: column.visible } : {}),
        };

        if (Object.keys(update).length > 0) {
          updates.push(this.pm.updateColumns('contacts', $key, update));
        }

        return updates.length > 0 ? forkJoin(updates) : of(void 0);
      }),
    ).pipe(map((updates) => updates.reduce((a, b) => a.concat(b), []).filter((update) => !!update)));
  }

  public sendTestMessage(data: { to: string; key: string; kind: string }) {
    return this.za.put('survey/share/test', data);
  }
}
