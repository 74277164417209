import { Component } from '@angular/core';

import { GlobalSpinnerService } from '@shared/services/global-spinner.service';

@Component({
  selector: 'zef-global-spinner',
  templateUrl: './global-spinner.component.html',
  styleUrls: ['./global-spinner.component.scss'],
})
export class GlobalSpinner {
  constructor(readonly gs: GlobalSpinnerService) {}
}
