/**
 * Toggles panel state when element is clicked.
 *
 * @unstable
 */

import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { Panel } from '@shared/components/panel/panel.component';

@Directive({
  selector: '[panelTriggerFor]',
})
export class PanelTrigger {
  @Input('panelTriggerFor') panel: Panel;

  @Output() panelOpen = new EventEmitter<void>();
  @Output() panelClose = new EventEmitter<void>();

  @HostListener('click') onClick(): void {
    this.togglePanel();
  }

  constructor() {}

  public openPanel(): void {
    this.panel.openPanel();

    this.panelOpen.emit();
  }

  public closePanel(): void {
    this.panel.closePanel();

    this.panelClose.emit();
  }

  public togglePanel(): void {
    if (this.panel.isOpen()) {
      this.closePanel();
    } else {
      this.openPanel();
    }
  }
}
