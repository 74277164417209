import { trigger, state, style, transition, animate } from '@angular/animations';

export const FadeInOut = trigger('scrimState', [
  state(
    '*',
    style({
      'background-color': 'rgba(0, 0, 0, 0)',
    }),
  ),

  state(
    'opened-left, opened-left-root, opened-right, opened-right-root',
    style({
      'background-color': 'rgba(0, 0, 0, 0.5)',
    }),
  ),

  transition('* => *', [animate('250ms ease')]),
]);

export const SlideInOut = trigger('panelState', [
  state(
    'closed',
    style({
      transform: 'translateX(-100%)',
    }),
  ),

  state(
    'closed-left',
    style({
      transform: 'translateX(-100%)',
    }),
  ),

  state(
    'opened-left-root',
    style({
      transform: 'translateX(0)',
    }),
  ),

  state(
    'opened-left-child',
    style({
      transform: 'translateX(100%)',
    }),
  ),

  state(
    'closed-right',
    style({
      transform: 'translateX(100%)',
    }),
  ),

  state(
    'opened-right-root',
    style({
      transform: 'translateX(0)',
    }),
  ),

  state(
    'opened-right-child',
    style({
      transform: 'translateX(-100%)',
    }),
  ),

  transition('* <=> *', [animate('300ms ease-in')]),
]);
