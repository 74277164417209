import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';
import { PlanId } from '@shared/models/plan.model';

@Component({
  selector: 'zef-promo-dialog',
  templateUrl: './promo-dialog.component.html',
  styleUrls: ['./promo-dialog.component.scss'],
})
export class PromoDialog {
  readonly pricingAddress = `https:${environment.wwwAddress}/pricing`;

  @Input()
  backgroundImage = '';

  @Input()
  requiredPlan: PlanId | null = null;

  @Input()
  showCheckbox = true;
}
