<div [matMenuTriggerFor]="popup" #popupTrigger="matMenuTrigger">
  <ng-content select="[mat-icon-button]"></ng-content>

  <mat-menu #popup class="popup-menu" xPosition="after">
    <div *matMenuContent class="popup-container zef-card-z4-soft zef-card-24" [ngStyle]="menuTransform">
      <button mat-icon-button class="zef-close-button" (click)="popupTrigger.closeMenu()">
        <mat-icon>close</mat-icon>
      </button>

      <ng-content></ng-content>
    </div>
  </mat-menu>
</div>
