import { ContactColumn } from '@shared/models/contact.model';

export function sortedContactColumns(columns: ContactColumn[]): ContactColumn[] {
  return columns.sort((a, b): number => {
    const aOrder = a.order;
    const bOrder = b.order;

    if (aOrder === undefined || bOrder === undefined) {
      return aOrder === bOrder ? 0 : aOrder === undefined ? -1 : 1;
    }

    if (aOrder === null || bOrder === null) {
      return aOrder === bOrder ? 0 : aOrder === null ? 1 : -1;
    }

    return aOrder - bOrder;
  });
}

export function isCorrectContactsFileType(fileName: string): boolean {
  return /\.(xlsx?|csv|txt)$/.test(fileName);
}
