import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'help-article',
  template: '',
  styleUrls: [
    '../../../../../../../node_modules/quill/dist/quill.core.css',
    '../../../../../../../node_modules/quill/dist/quill.snow.css',
    'help-article.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpArticleComponent {
  @Input()
  @HostBinding('innerHTML')
  article?: string;
}
