/*
 * Formats cents value to currency
 *
 * @unstable
 */

import { Store } from '@ngxs/store';

import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { PrefsState } from '@shared/states/prefs.state';

@Pipe({
  name: 'currencyCent',
})
export class CurrencyCent implements PipeTransform {
  private currencyPipe: CurrencyPipe;

  constructor(readonly store: Store) {
    this.currencyPipe = new CurrencyPipe('en-US');
  }

  transform(
    value: any,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): string | null {
    value = parseInt(value, 10) || 0;

    if (typeof value === 'number') {
      const neg = value < 0;
      value = value.toFixed(0).substr(neg ? 1 : 0);

      if (value.length === 1) {
        value = `0.0${value}`;
      } else if (value.length === 2) {
        value = `0.${value}`;
      } else {
        value = `${value.substr(0, value.length - 2)}.${value.substr(-2)}`;
      }

      if (neg) {
        value = `-${value}`;
      }

      value = parseFloat(value);
    }

    if (!locale) {
      locale = this.store.selectSnapshot(PrefsState.locale);
    }

    return this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
  }
}
