<zef-sidenav>
  <ng-container *zefSidenavTitle>
    <ng-container *ngIf="contact$ | async as contact">
      <ng-container *ngIf="!contact.id" i18n>New contact</ng-container>
      <ng-container *ngIf="contact.id" i18n>Edit contact</ng-container>
    </ng-container>
  </ng-container>

  <ng-container *zefSidenavButtonPrimary>
    <button
      *ngIf="contact$ | async as contact"
      mat-raised-button
      [color]="contact.id ? 'primary' : 'success'"
      [loading]="loading$ | async"
      [disabled]="!contact.id && (contactsRemaining$ | async) <= 0"
      (click)="onSaveContact(contact)"
    >
      <ng-container *ngIf="!contact.id" i18n>Create contact</ng-container>
      <ng-container *ngIf="contact.id" i18n>Save</ng-container>
    </button>
  </ng-container>

  <mat-menu *ngIf="(contact$ | async)?.id || (contactsRemaining$ | async)" zefSidenavMenu>
    <ng-container *ngIf="contact$ | async as contact">
      <div *ngIf="!contact.id" mat-menu-item i18n (click)="onSaveContact(contact, true)">Save and create another</div>

      <div class="alert" mat-menu-item i18n (click)="sidenav?.closeSidenav()">Discard</div>
    </ng-container>
  </mat-menu>

  <div *zefSidenavContent class="contact-body">
    <ng-container *ngIf="contact$ | async as contact">
      <div *ngIf="!contact.id && (contactsRemaining$ | async) <= 0" class="contact-limit z-fx-col z-fx-gap-16">
        <div class="zef-text-p1" i18n>Your organization has reached its maximum Contacts limit.</div>

        <button mat-raised-button class="zef-cta-button" color="contacts" routerLink="/plans" i18n>
          Get more Contacts
        </button>

        <div routerLink="/settings/billing" class="z-fx z-fx-gap-8 z-fx-start-center zef-text-link">
          <div class="zef-text-p2 zef-text-light">Go to Plan & Billing</div>

          <mat-icon class="zef-icon-small">new_tab</mat-icon>
        </div>
      </div>

      <div class="contact-body-inner z-fx-col z-fx-gap-32">
        <dropzone
          *ngIf="uploadConfig | async as config"
          class="z-fx-flex-center zef-image-upload zef-avatar-image zef-large"
          [config]="config"
          [message]="(contact$ | async)?.photo ? 'Change contact avatar' : 'Add contact avatar'"
          [placeholder]="(contact$ | async)?.thumb || 'assets/images/user-placeholder.png'"
          (success)="onAvatarUploaded($event)"
        >
          <mat-icon class="zef-upload-icon">photo_new</mat-icon>
        </dropzone>

        <div class="contact-columns z-fx-col z-fx-gap-24">
          <ng-container *ngFor="let column of columns$ | async; let index = index">
            <ng-container *ngIf="index === 2">
              <mat-divider></mat-divider>

              <div class="z-fx-col z-fx-gap-8">
                <div class="zef-text-h3" i18n>Add to list</div>

                <zef-select
                  placeholder="Select a list..."
                  i18n-placeholder
                  [canClear]="true"
                  clearHint="No list"
                  i18n-clearHint
                  [readOnly]="readonlyAddList"
                  [value]="addToList"
                  (change)="addToList = $event"
                >
                  <zef-select-option *ngFor="let list of contactLists$ | async; trackBy: trackByList" [value]="list.id">
                    {{ list?.name }}
                  </zef-select-option>
                </zef-select>
              </div>

              <mat-divider></mat-divider>
            </ng-container>

            <div class="z-fx-col z-fx-gap-8">
              <div
                class="z-fx z-fx-between-center z-fx-gap-8 zef-text-overflow-line"
                [class.zef-text-h3]="index < 2"
                [class.zef-text-p2]="index >= 2"
              >
                <div class="zef-text-ellipsis">
                  {{ column.name }}
                </div>

                <span class="zef-text-light" *ngIf="index >= 2">
                  {{ cm.getColumnTypeName(column.type) }}
                </span>
              </div>

              <mat-form-field *ngIf="column.type !== 'date'" class="z-input-smaller z-grey">
                <input
                  *ngIf="column.$key === 'phone'"
                  matInput
                  placeholder="{{ column.name }} starting with +..."
                  [(ngModel)]="contact.phone"
                  [ngModelOptions]="{ updateOn: 'blur' }"
                  (blur)="onPhoneBlur(contact)"
                />

                <input
                  *ngIf="column.$key !== 'phone'"
                  matInput
                  placeholder="{{ column.name }}..."
                  [(ngModel)]="contact[column.$key]"
                  [ngModelOptions]="{ updateOn: 'blur' }"
                />
              </mat-form-field>

              <mat-form-field *ngIf="column.type === 'date'" class="z-input-smaller z-grey">
                <mat-datepicker #picker></mat-datepicker>

                <input
                  matInput
                  placeholder="{{ column.name }}..."
                  [(ngModel)]="contact[column.$key]"
                  [ngModelOptions]="{ updateOn: 'blur' }"
                  [matDatepicker]="picker"
                />

                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon>calendar</mat-icon>
                </mat-datepicker-toggle>
              </mat-form-field>

              <mat-error *ngIf="column.$key === 'email' && contact.email">
                <ng-container *ngIf="!this.isValidEmail(contact.email)" i18n>
                  Please enter a valid email address
                </ng-container>

                <ng-container *ngIf="duplicateError === 'email'" i18n>
                  A contact with this email already exists
                </ng-container>
              </mat-error>

              <mat-error *ngIf="column.$key === 'phone' && contact.phone">
                <ng-container *ngIf="!this.isValidPhone(contact.phone)" i18n>
                  Please enter a valid Phone Number starting with +
                </ng-container>

                <ng-container *ngIf="duplicateError === 'phone'" i18n>
                  A contact with this Phone Number already exists
                </ng-container>
              </mat-error>

              <mat-error *ngIf="index >= 2 && !this.isValidColumn(contact[column.$key], column.type)">
                <ng-container i18n>This does not look like a</ng-container>
                {{ cm.getColumnTypeName(column.type) }} field
              </mat-error>
            </div>

            <mat-error *ngIf="index === 1 && submitted && !contact.email && !contact.phone" i18n>
              At least an Email, or Phone Number is required
            </mat-error>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</zef-sidenav>
