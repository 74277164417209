import { fromEvent, merge, race, Subject, timer } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, mapTo, startWith, switchMap, take, takeUntil } from 'rxjs/operators';

import {
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
  MAT_LEGACY_TOOLTIP_SCROLL_STRATEGY as MAT_TOOLTIP_SCROLL_STRATEGY,
  MatLegacyTooltip as MatTooltip,
  MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions,
} from '@angular/material/legacy-tooltip';
import { FlexibleConnectedPositionStrategy, Overlay, ScrollDispatcher } from '@angular/cdk/overlay';
import {
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  ViewContainerRef,
} from '@angular/core';

import { assertArray } from '@shared/utilities/array.utilities';
import { Platform } from '@angular/cdk/platform';
import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[zefInputTooltip]',
})
export class InputTooltip extends MatTooltip implements OnInit, OnDestroy {
  @HostBinding('class.mat-tooltip-trigger')
  readonly matTooltipTrigger = true;

  @Input('zefInputTooltip')
  set inputTooltip(message: string) {
    this.message = message;
  }

  private element = (this as MatTooltip | any)._elementRef.nativeElement;

  private destroy$ = new Subject<void>();

  private hideFromInputTooltip = false;

  private fixedOverlay?: boolean;

  private readonly focusDebounce$ = race(
    timer(1).pipe(delay(1), mapTo(true)),
    fromEvent(this.element, 'focusin').pipe(mapTo(false)),
  ).pipe(
    take(1),
    filter((focusout) => focusout),
  );

  private readonly focusOut$ = fromEvent(this.element, 'focusout').pipe(
    switchMap(() => this.focusDebounce$),
    mapTo('hide'),
  );

  private readonly scrollPositioning$ = timer(1).pipe(
    filter(() => !!this._overlayRef),
    switchMap(
      () => (this._overlayRef?.getConfig().positionStrategy as FlexibleConnectedPositionStrategy).positionChanges,
    ),
    map(({ scrollableViewProperties }) =>
      !scrollableViewProperties.isOriginClipped && !scrollableViewProperties.isOverlayClipped ? 'in' : 'out',
    ),
    startWith('show'),
    distinctUntilChanged(),
    takeUntil(this.focusOut$),
  );

  private readonly focusIn$ = fromEvent(this.element, 'focusin').pipe(switchMap(() => this.scrollPositioning$));

  constructor(
    ol: Overlay,
    el: ElementRef<HTMLElement>,
    sd: ScrollDispatcher,
    vc: ViewContainerRef,
    ng: NgZone,
    pl: Platform,
    ad: AriaDescriber,
    fm: FocusMonitor,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) ss: any,
    @Optional() dir: Directionality,
    @Optional() @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS) mdo: MatTooltipDefaultOptions,
    @Inject(DOCUMENT) doc: Document,
  ) {
    super(ol, el, sd, vc, ng, pl, ad, fm, ss, dir, mdo, doc);
  }

  ngOnInit(): void {
    merge(this.focusIn$, this.focusOut$)
      .pipe(takeUntil(this.destroy$))
      .subscribe((showState: 'hide' | 'show' | 'out' | 'in') => {
        (this as any)._ngZone.run(() => {
          switch (showState) {
            case 'hide':
              return this.hide(0, true);
            case 'show':
              return this.show(0, void 0, true);
            case 'in':
              return this.scrolledIn();
            case 'out':
              return this.scrolledOut();
          }
        });
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.destroy$.next();
    this.destroy$.complete();
  }

  show(
    delayTime?: number,
    origin?: {
      x: number;
      y: number;
    },
    force?: boolean,
  ): void {
    if (force && !this._isTooltipVisible()) {
      super.show(delayTime, origin);

      if (this._tooltipInstance) {
        const hide = this._tooltipInstance.hide.bind(this._tooltipInstance);

        this._tooltipInstance.hide = () => {
          if (this.hideFromInputTooltip) {
            this.hideFromInputTooltip = false;
            hide(delayTime);
          }
        };

        if (!this.fixedOverlay && this._overlayRef) {
          this.fixedOverlay = true;

          (this._overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy)
            .withPositions([{ ...this._getOrigin().main, ...this._getOverlayPosition().main }])
            .withPush(false);
        }
      }
    }
  }

  hide(delayTime?: number, force?: boolean): void {
    if (force) {
      this.hideFromInputTooltip = true;

      super.hide(delayTime);
    }
  }

  private scrolledIn(): void {
    if (this._isTooltipVisible()) {
      this.tooltipClass = assertArray(this.tooltipClass).filter((item) => item !== 'hide');
    }
  }

  private scrolledOut(): void {
    if (this._isTooltipVisible()) {
      this.tooltipClass = assertArray(this.tooltipClass).filter((item) => item !== 'hide');
      this.tooltipClass.push('hide');
    }
  }
}
