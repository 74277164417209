import { Router } from '@angular/router';
import { Dialog, DialogConfig } from '@angular/cdk/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewContainerRef } from '@angular/core';

import { BaseOverlay } from '@shared/components/base-overlay/base-overlay.component';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'zef-toolbar',
  exportAs: 'zefToolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    trigger('toggle', [
      state('void', style({ transform: 'translateY(-100%)' })),
      state('*', style({ transform: 'translateY(0)' })),
      transition(':enter, :leave', animate('200ms ease-in-out')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Toolbar extends BaseOverlay implements OnDestroy {
  protected config: DialogConfig = {
    hasBackdrop: false,
    positionStrategy: new GlobalPositionStrategy().top('0').left('0').right('0'),
    width: '100vw',
    maxWidth: 'initial',
    height: '56px',
    maxHeight: 'initial',
    panelClass: 'zef-toolbar',
    autoFocus: false,
    disableClose: true,
  };

  @Input()
  color: string;

  constructor(dialog: Dialog, vc: ViewContainerRef, router: Router) {
    super(dialog, vc, router);
  }
}
