<figure
  class="survey-status"
  [class.inline]="!toggle && type === 'inline'"
  [class.toggle]="!!toggle || type === 'toggle'"
  [ngClass]="status"
></figure>

<span class="zef-text-p2">
  <ng-content></ng-content>
</span>
