import { trigger, state, style, transition, animate } from '@angular/animations';

export const rotateAnimation = trigger('rotate', [
  state('*', style({ userSelect: 'none' })),
  state('false', style({ transform: 'scaleY(1)' })),
  state('true', style({ transform: 'scaleY(-1)' })),
  transition('false <=> true', animate('100ms ease-in-out')),
]);

export const rotate90Animation = trigger('rotate90', [
  state('*', style({ userSelect: 'none' })),
  state('false', style({ transform: 'rotate(0deg)' })),
  state('true', style({ transform: 'rotate(90deg)' })),
  transition('false <=> true', animate('100ms ease-in-out')),
]);
