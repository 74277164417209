import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';

import { Toolbar } from '@shared/components/toolbar/components/toolbar/toolbar.component';
import { ToolbarTriggerFor } from '@shared/components/toolbar/directives/toolbar-trigger/toolbar-trigger.directive';

@NgModule({
  imports: [CommonModule, DialogModule, MatToolbarModule],
  declarations: [Toolbar, ToolbarTriggerFor],
  exports: [Toolbar, ToolbarTriggerFor],
})
export class ToolbarModule {}
