import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

import { NgScrollbarModule } from 'ngx-scrollbar';

import { Select } from '@shared/components/select/select.component';
import { SelectItem } from '@shared/components/select/select-item.component';
import { SelectOption } from '@shared/components/select/select-option.component';
import { SelectPrefix } from '@shared/components/select/select-prefix.directive';
import { SelectCategory } from '@shared/components/select/select-category.component';
import { SelectLabel } from '@shared/components/select/select-label.directive';
import { SelectOptions } from '@shared/components/select/select-options.directive';
import { SelectOptionLabel } from '@shared/components/select/select-option-label.directive';
import { SpinnerModule } from '@shared/components/spinner/spinner.module';
import { BasicModule } from '@shared/modules/basic.module';
import { SelectSuffix } from '@shared/components/select/select-suffix.directive';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';

@NgModule({
  imports: [
    CommonModule,
    PortalModule,
    OverlayModule,
    NgScrollbarModule,

    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatButtonModule,
    MatProgressBarModule,

    BasicModule,
    SpinnerModule,
    MatLegacyCheckboxModule,
    MatLegacyRadioModule,
  ],
  declarations: [
    Select,
    SelectItem,
    SelectLabel,
    SelectOption,
    SelectOptionLabel,
    SelectOptions,
    SelectPrefix,
    SelectCategory,
    SelectSuffix,
  ],
  exports: [
    Select,
    SelectLabel,
    SelectOption,
    SelectOptionLabel,
    SelectOptions,
    SelectPrefix,
    SelectCategory,
    SelectSuffix,
  ],
})
export class SelectModule {}
