import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import {
  SIDENAV_CONFIG,
  SidenavConfig,
  SidenavDefaults,
  SidenavRef,
} from '@shared/modules/sidenav/models/sidenav.models';

@Component({
  templateUrl: './sidenav-container.component.html',
  styleUrls: ['./sidenav-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: SidenavRef, useExisting: SidenavContainer }],
})
export class SidenavContainer<T = void> extends SidenavRef<T> {
  get isOpen(): boolean {
    return this._isOpen;
  }

  constructor(@Inject(SIDENAV_CONFIG) readonly config: SidenavConfig) {
    super();

    this.config = {
      ...SidenavDefaults,
      ...config,
    };
  }

  open(): void {
    this._isOpen = true;
  }
}
