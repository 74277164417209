/*
 *  Edit contact component sidebar.
 *
 *  @unstable
 */

import { environment } from '@env/environment';

import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Commands } from '@shared/enums/commands.enum';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { Select, Store } from '@ngxs/store';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { AccountState } from '@shared/states/account.state';
import { ContactsState } from '@shared/states/contacts.state';

import { ContactsListData, ContactColumn, ColumnType, ContactItemData } from '@shared/models/contact.model';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import { AuthManager } from '@shared/services/auth-manager.service';
import { ContactsManager } from '@shared/services/contacts-manager.service';

import { ContactEntryData, ContactFieldsData } from '@shared/models/contact.model';
import { GetContactLists } from '@shared/states/contacts.actions';
import { Sidenav } from '@shared/modules/sidenav/components/sidenav/sidenav.component';
import { BillingState } from '@shared/states/billing.state';

@Component({
  selector: 'edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.scss'],
  providers: [LifecycleHooks, { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditContact implements OnInit {
  private readonly primaryCols = ['phone', 'email'];

  public duplicateError?: 'phone' | 'email';

  public submitted: boolean = false;

  @Select(BillingState.contactsRemaining)
  readonly contactsRemaining$!: Observable<number>;

  @ViewChild(Sidenav, { static: true })
  sidenav?: Sidenav;

  @Input()
  public addToList?: number;

  @Input() set contact(contact: Partial<ContactItemData> | undefined) {
    this.contact$.next({ ...(contact || {}) });
  }

  @Input() readonlyAddList?: boolean;

  @Output()
  readonly save = new EventEmitter<{ isNew: boolean }>();

  @Select(ContactsState.contactLists)
  readonly contactLists$!: Observable<ContactsListData[]>;

  readonly columns$: Observable<ContactColumn[]> = this.store.select(ContactsState.columns).pipe(
    map((columns) =>
      columns
        .filter((col) => col.editable)
        .sort((a, b) => this.primaryCols.indexOf(b.$key) - this.primaryCols.indexOf(a.$key)),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly uploadConfig: Observable<DropzoneConfigInterface> = combineLatest([
    this.store
      .select(AccountState.teamKey)
      .pipe(map((teamKey) => `${environment.cloudFunctions}/${Commands.ImageGallery}/${teamKey}/contact-avatars/`)),
    this.auth.idTokenChange.pipe(
      map((idToken) => ({
        'Firebase-Authorization': idToken,
      })),
    ),
  ]).pipe(
    map(([url, headers]) => ({ url, headers })),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly contact$ = new BehaviorSubject<Partial<ContactItemData>>({});

  readonly loading$ = new BehaviorSubject(false);

  private readonly emailRegExp: RegExp = /\S+@\S+\.\S+/;

  private readonly numericRegExp: RegExp = /^\d+$/;

  readonly trackByList = (i: number, list: ContactsListData) => list.id;

  constructor(
    readonly store: Store,
    readonly auth: AuthManager,
    readonly cm: ContactsManager,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new GetContactLists({ start: 0 }));
  }

  onPhoneBlur(contact: Partial<ContactItemData>): void {
    if (contact.phone && contact.phone.startsWith('00')) {
      contact.phone = contact.phone.replace('00', '+');
    }

    contact.phone = contact.phone && (contact.phone.startsWith('+') ? '+' : '') + contact.phone.replace(/\D/g, '');
  }

  isValidEmail(email: string): boolean {
    return this.emailRegExp.test(email);
  }

  isValidPhone(phone: string): boolean {
    return phone.startsWith('+');
  }

  isValidNumeric(number: string): boolean {
    return this.numericRegExp.test(number);
  }

  isValidColumn(value: string, type: ColumnType): boolean {
    if (!value) {
      return true;
    }

    if (type === 'phone') {
      return this.isValidPhone(value);
    }

    if (type === 'email') {
      return this.isValidEmail(value);
    }

    if (type === 'numeric') {
      return this.isValidNumeric(value);
    }

    return true;
  }

  private resetFields(): void {
    this.addToList = undefined;
    this.contact$.next({});
    this.submitted = false;
    delete this.duplicateError;
  }

  public onSaveContact(contact: Partial<ContactItemData>, createNew?: boolean): void {
    this.submitted = true;
    delete this.duplicateError;

    if (!this.loading$.getValue() && this.isValidContact(contact)) {
      this.loading$.next(true);

      this.cm.saveContact(contact, this.addToList).subscribe({
        next: () => {
          if (createNew) {
            this.resetFields();
          } else {
            this.sidenav?.closeSidenav();
          }

          this.save.emit({ isNew: !contact.id });
        },
        complete: () => this.loading$.next(false),
        error: ({ error }: HttpErrorResponse) => {
          if (error && error.code === 409) {
            this.duplicateError = error.data;
          }

          this.loading$.next(false);
        },
      });
    }
  }

  public onAvatarUploaded(event: any[]): void {
    const contact = this.contact$.getValue();
    const response = JSON.parse(event[0].xhr.response);

    contact.thumb = response.urls.thumb;
    contact.photo = response.urls.regular;
  }

  private isValidContact(contact: Partial<ContactEntryData & ContactFieldsData>): boolean {
    if (!contact.email && !contact.phone) {
      return false;
    }

    if (contact.email && !this.isValidEmail(contact.email)) {
      return false;
    }

    return !(contact.phone && !this.isValidPhone(contact.phone));
  }
}
