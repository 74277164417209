/**
 * Tracking analytics module.
 *
 * @unstable
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Consent } from '@shared/components/consent/consent.component';

import { AnalyticsTracker } from '@shared/directives/analytics-tracker.directive';

@NgModule({
  bootstrap: [],
  declarations: [Consent, AnalyticsTracker],
  exports: [AnalyticsTracker],
  imports: [CommonModule],
})
export class TrackingModule {}
