import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { Chip } from '@shared/components/chip/chip.component';

@NgModule({
  declarations: [Chip],
  imports: [CommonModule, MatLegacyButtonModule, MatIconModule, MatLegacyTooltipModule],
  exports: [Chip],
})
export class ChipModule {}
