import { InjectionToken } from '@angular/core';

export function documentReferrer() {
  return (typeof document !== 'undefined' && document.referrer) || '';
}

export function documentSource() {
  if (typeof window === 'undefined') {
    return '';
  } else if ('URLSearchParams' in window) {
    return new URLSearchParams(location.search).get('source') || '';
  } else {
    const tester = new RegExp('[?|&]source=(.*?)(&.*|$)');
    const matches = location.search.match(tester);

    return (matches && matches[1]) || '';
  }
}

export const DOCUMENT_REFERRER = new InjectionToken<string>('Document Referrer', {
  providedIn: 'root',
  factory: documentReferrer,
});

export const DOCUMENT_SOURCE = new InjectionToken<string>('Document Source', {
  providedIn: 'root',
  factory: documentSource,
});
