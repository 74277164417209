import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'zef-success-box',
  templateUrl: './success-box.component.html',
  styleUrls: ['./success-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessBox {
  @Input()
  darkMode?: boolean;
}
