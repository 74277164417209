import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask',
})
export class MaskPipe implements PipeTransform {
  transform(value: string, mask: string = '•'): string {
    let visible = 8;
    let size = 10;

    value = value || '';

    if (value.length < 8) {
      visible = 0;
      size = 8;
    } else if (value.length < 16) {
      visible = 2;
    } else if (value.length < 24) {
      visible = 4;
    }

    return value.slice(0, visible / 2).padEnd(size, mask) + value.slice(-(visible / 2));
  }
}
