import { Pipe, Inject, LOCALE_ID, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import moment from 'moment';

import { SurveyMessage } from '@shared/models/survey-shares.model';
import {
  getPreviousMessage,
  getDayDifference,
} from '@surveys/+detail/+share/+invite/shared/utilities/invite.utilities';
import { AutomationSettings } from '@shared/models/email.model';

@Pipe({
  name: 'scheduleDate',
})
export class ScheduleDatePipe extends DatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }

  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(
    value: SurveyMessage | AutomationSettings | number,
    format: 'date' | 'dateTime',
    timezone?: string,
    locale?: string,
  ): string | null;
  transform(
    value: SurveyMessage | AutomationSettings | number,
    format: 'date' | 'dateTime' = 'dateTime',
    timezone?: string,
    locale?: string,
  ): string | null {
    let scheduledAt: number;
    let prevSchedule: number;

    if (this.isAutomationSettings(value)) {
      return $localize`:invitation:+${value.sendAfterDaysFromPrev} days`;
    }

    if (typeof value !== 'number') {
      scheduledAt = value?.scheduledAt;

      const prevMessage = getPreviousMessage(value);

      if (prevMessage && !prevMessage.sending && prevMessage.parent) {
        prevSchedule = prevMessage.scheduledAt;
      }
    } else {
      scheduledAt = value;
    }

    if (!scheduledAt) {
      return null;
    }

    if (prevSchedule) {
      const dayDiff = getDayDifference(scheduledAt, prevSchedule);

      if (dayDiff) {
        return $localize`:invitation:+${dayDiff} days`;
      }
    }

    if (format === 'date') {
      const shortFormat = moment().isSame(moment(scheduledAt), 'd') ? 'shortTime' : 'shortDate';

      return `@ ${super.transform(scheduledAt, shortFormat, timezone, locale)}`;
    }

    return `${super.transform(scheduledAt, 'shortDate', timezone, locale)} @ ${super.transform(
      scheduledAt,
      'shortTime',
      timezone,
      locale,
    )}`;
  }

  isAutomationSettings(settings: SurveyMessage | AutomationSettings | number): settings is AutomationSettings {
    return settings?.hasOwnProperty('sendAfterDaysFromPrev');
  }
}
