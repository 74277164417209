import { ContactColumn, ContactItemData, ContactsListData, ContactsImportModel } from '@shared/models/contact.model';
import { StateToken } from '@ngxs/store';

export interface ContactsStateModel {
  columns: ContactColumn[];
  itemsCount: number;
  listsCount: number;
  resultsCount: number;
  listsLoading: boolean;
  itemsLoading: boolean;
  contactItems: ContactItemData[];
  contactLists: ContactsListData[];
  contactResults: ContactItemData[] | ContactsListData[];
  import?: ContactsImportModel;
}

export const CONTACTS_STATE_TOKEN = new StateToken<ContactsStateModel>('contacts');
