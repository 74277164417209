import { trigger, state, style, transition, animate } from '@angular/animations';

export const HideShow = trigger('show', [
  state(
    '*',
    style({
      height: 0,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  ),
  state(
    'true',
    style({
      height: '*',
      paddingTop: '*',
      paddingBottom: '*',
    }),
  ),
  transition('true <=> *', [animate('200ms ease-in-out')]),
]);
