<div *ngIf="show" class="wrapper z-fx-col" [style.min-height.px]="height">
  <div class="background">
    <ng-content select="[background]"></ng-content>
  </div>

  <div class="foreground z-fx z-fx-flex" [class.zef-foreground]="foreground">
    <ng-content></ng-content>

    <div class="icon-action z-fx-center-center">
      <button *ngIf="closeButton" mat-icon-button (click)="onCloseClick($event)">
        <mat-icon class="zef-icon-large" matTooltip="Close" i18n-matTooltip>close</mat-icon>
      </button>

      <ng-content *ngIf="!closeButton" select="[action]"></ng-content>
    </div>
  </div>
</div>
