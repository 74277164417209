/**
 * Analytics related interfaces
 *
 * @unstable
 */

import { Rights } from '@shared/enums/rights.enum';

/**
 * Segment group properties
 *
 * @see https://segment.com/docs/spec/group/#traits
 */
export interface GroupProperties {
  id: string;
  avatar: string;
  name: string;
  createdAt: Date | number;
  plan: string;
  employees: number;
  website: string;
}

/**
 * Company properties as specified in Segment doc
 *
 * @see https://segment.com/docs/spec/identify/#traits
 */
export interface CompanyProperties {
  name: string;
  id: string;
  employee_count: number;
}

/**
 * Identity properties. Combination of Segment & own traits
 *
 * @see https://segment.com/docs/spec/identify/#traits
 */
export interface IdentifyProperties {
  // User info & preferences
  userId: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  avatar: string;
  language: string;

  createdAt: Date | number;
  lastSignInAt: Date | number;
  signUpComplete: Date | number;

  company: CompanyProperties;

  // Organisation traits
  defaultTeam: string;
  activeTeam: string;
  activeTeamName: string;
  activeTeamPlan: string;

  permission: Rights;

  // Billing traits
  billingPlan: string;
  billingDate: Date | number;
  trialEnds: Date | number;

  // License traits
  contacts: number;
  answers: number;
  emails: number;

  contactsLimit: number;
  answersLimit: number;
  emailsLimit: number;
}

export interface HubSpotTraits {
  email: string;
  new_version_language?: string;
}

/**
 * Page names for Analytics
 *
 */
export enum AnalyticPages {
  HOME = 'Home',
  SURVEY_EDIT_BUILD = 'Survey-Edit-Build',
  SURVEY_EDIT_DESIGN = 'Survey-Edit-Design',
  SURVEY_EDIT_SETTINGS = 'Surveys-Edit-Settings',
  SURVEY_EDIT_LOGIC = 'Surveys-Edit-Logic',
  ANALYZE = 'Analyze',
  CONTACTS = 'Contacts',
  LISTS = 'Lists',
  IMPORT = 'Import',
  EMAILS = 'Emails',
  EMAIL_DRAFT = 'Email-Draft',
  EMAIL_SENT = 'Email-Sent',
  ORG_PROFILE = 'Org-Profile',
  ORG_USERS = 'Org-Users',
  ORG_BILLING = 'Org-Billing',
  ORG_INTEGRATIONS = 'Org-Integrations',
  PROFILE_USER = 'Profile-User',
  PROFILE_SETTINGS = 'Profile-Settings',
  SURVEY_BUILD = 'Survey-Build',
  SURVEY_SHARE = 'Survey-Share',
  SURVEY_SHARE_RECIPIENTS = 'Survey-Share-Recipients',
  SURVEY_SETTINGS = 'Surveys-Settings',
  DISCOVER = 'Discover',
  PLANS = 'Plans',
}
export const AnalyticPagesMatcher = [
  { regex: '/home', page: AnalyticPages.HOME },
  { regex: '/surveys/edit/.*/design', page: AnalyticPages.SURVEY_EDIT_DESIGN },
  { regex: '/surveys/edit/.*/settings', page: AnalyticPages.SURVEY_EDIT_SETTINGS },
  { regex: '/surveys/edit/.*?logic=true', page: AnalyticPages.SURVEY_EDIT_LOGIC },
  { regex: '/surveys/edit/.*/build', page: AnalyticPages.SURVEY_EDIT_BUILD },
  { regex: '/surveys/.*/analyze', page: AnalyticPages.ANALYZE },
  { regex: '/contacts/all', page: AnalyticPages.CONTACTS },
  { regex: '/contacts/lists', page: AnalyticPages.LISTS },
  { regex: '/contacts/import', page: AnalyticPages.IMPORT },
  { regex: '/emails/.*/draft', page: AnalyticPages.EMAIL_DRAFT },
  { regex: '/emails/.*/sent', page: AnalyticPages.EMAIL_SENT },
  { regex: '/emails', page: AnalyticPages.EMAILS },
  { regex: '/settings/org', page: AnalyticPages.ORG_PROFILE },
  { regex: '/settings/users', page: AnalyticPages.ORG_USERS },
  { regex: '/settings/billing', page: AnalyticPages.ORG_BILLING },
  { regex: '/settings/integrations', page: AnalyticPages.ORG_INTEGRATIONS },
  { regex: '/profile/user', page: AnalyticPages.PROFILE_USER },
  { regex: '/profile/settings', page: AnalyticPages.PROFILE_SETTINGS },
  { regex: '/surveys/.*/build', page: AnalyticPages.SURVEY_BUILD },
  { regex: '/surveys/.*/share/.*/recipients', page: AnalyticPages.SURVEY_SHARE_RECIPIENTS },
  { regex: '/surveys/.*/share', page: AnalyticPages.SURVEY_SHARE },
  { regex: '/surveys/.*/settings', page: AnalyticPages.SURVEY_SETTINGS },
  { regex: '/discover', page: AnalyticPages.DISCOVER },
  { regex: '/plans', page: AnalyticPages.PLANS },
];

export function urlToPage(url: string) {
  const match = AnalyticPagesMatcher.find(({ regex }) => new RegExp(regex).test(url));
  return (match && match.page) || null;
}

/**
 * Track names for Analytics
 */
export enum AnalyticsTracks {
  // Old tracks
  AUTH_SIGNUP_COMPLETE = 'auth-signup-complete',
  HOME_UPGRADE = '-home-upgrade',
  HOME_CREATE_FREE_ACCOUNT = '-home-create-free-account',
  EDIT_SIGNUP = '-edit-signup',
  PLANS_CHANGE = '-plans-change',
  SURVEY_EDIT_BUTTON = '-surveys-edit-button',
  SURVEY_SHARE_COPY_LINK = '-surveys-share-copy-link',
  TEAM_SWITCH_ORG = '-team-dialog-switch-org',
  TEAM_ACCEPT_INVITE = '-team-dialog-accept-invite',
  TEAM_IGNORE_INVITE = '-team-dialog-ignore-invite',
  // New tracks
  SURVEY_EDIT = '-surveys-edit',
  SURVEY_SHARE = '-surveys-share',
  SURVEY_ANALYZE = '-surveys-analyze',
  SURVEY_CREATE = '-surveys-create',
  CREATE_SCRATCH_PLACEHOLDER = '-create-scratch-placeholder',
  CREATE_SCRATCH = '-create-scratch',
  CREATE_USE_TEMPLATE = '-create-use-template',
  EDIT_BACK = '-edit-back',
  EDIT_PUBLISH = '-edit-publish',
  PUBLISH_TEST_LINK = '-publish-test-link',
  PUBLISH_PUBLISH_LINK = '-publish-publish-link',
  PUBLISH_PUBLISH = '-publish-publish',
  PUBLISH_FINISH = '-publish-finish',
  PUBLISH_COPY_DEFAULT = '-publish-copy-default',
  PUBLISH_MORE_SHARING = '-publish-more-sharing',
  TEMPLATE_EDIT = '-template-edit',
  TEMPLATE_DELETE = '-template-delete',
  TEMPLATE_DUPLICATE = '-template-duplicate',
}
